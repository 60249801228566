import * as React from "react";

function SvgLuggage(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={32} {...props}>
      <path d='M20.815 3.904h-4.561a4.375 4.375 0 00-8.032 0H3.655a1.98 1.98 0 00-1.98 1.98v20.46a1.98 1.98 0 001.98 1.98h17.16a1.98 1.98 0 001.98-1.98V5.884a1.98 1.98 0 00-1.98-1.98zm-8.58-.66a2.275 2.275 0 011.676.66h-3.326a2.277 2.277 0 011.65-.66zm8.58 23.1H3.655V5.884h17.16v20.46z' />
      <path d='M9.615 29.314a2 2 0 11-3.999.001 2 2 0 013.999-.001zM18.855 29.314a2 2 0 11-3.999.001 2 2 0 013.999-.001zM5.635 7.864h1.32v16.5h-1.32zM9.595 7.864h2v16.5h-2v-16.5zM13.555 7.864h2v16.5h-2v-16.5zM17.515 7.864h2v16.5h-2v-16.5z' />
    </svg>
  );
}

export default SvgLuggage;
