import useFirebaseEvent from "hooks/useFirebaseEvent";
import useSearchQuery from "hooks/useSearchQuery";
import { useCallback } from "react";
import { useHistory } from "react-router";

const useOnClick = (cityHallId: string) => {
  const searchQuery = useSearchQuery();
  const reportFirebase = useFirebaseEvent<"se_busco_municipio">({
    eventName: "se_busco_municipio"
  });
  return useCallback(() => {
    if (searchQuery.get("busqueda")) {
      //If there is an active search, assume the City Hall has been searched for
      reportFirebase({ id_municipio_buscado: cityHallId });
    }
  }, []);
};

export default useOnClick;
