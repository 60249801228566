import usePrompt from "hooks/usePrompt";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import THXContact from "./THXContact";

const useTHXContactModal = () => {
  const prompt = usePrompt();
  const history = useHistory();

  return useCallback(async () => {
    prompt<any>(props => (
      <THXContact onClick={() => history.push("/my-benefits")} {...props} />
    ))
      .then(response => console.log())
      .catch(error => console.error(error));
  }, []);
};

export default useTHXContactModal;
