const useMapRange = (ubications: Array<any>) => {
  //calculo un promedio para sacar la posicion en donde se posiciona el mapa.
  let latitudeRange = ubications?.map((ubication, i, array) => {
    return ubication.latitude / array.length;
  });
  let longitudeRange = ubications?.map((ubication, i, array) => {
    return ubication.longitude / array.length;
  });
  const reducer = (accumulator: number, currentValue: number) =>
    accumulator + currentValue;
  let position = [
    latitudeRange.reduce(reducer),
    longitudeRange.reduce(reducer)
  ];

  //si por alguna razon se hizo mal el calculo se dara el valor standar para el mapa de buenos aires
  if (
    position[0] > 180 ||
    position[0] < -180 ||
    position[1] > 180 ||
    position[1] < -180
  ) {
    position = [-35.907625376966294, -59.66724138179775];
  }

  return position;
};

export default useMapRange;
