import React, { useState } from "react";
import Loader from "components/Loader";
import { VIOLETDARK } from "assets/colors";
import usePropuestas from "../Propuesta/usePropuestas";
import useGanadores from "../Finalizadas/useGanadores";
import "./style.scss";
import PropuestaCard from "../../../components/PropuestaCard";
import AccordionConvo from "components/AccordionConvo";
import Tab from "components/Tabs/Tab";
import Tabs from "components/Tabs/Tabs";
import JuradoCard from "components/JuradoCard";
import Ganador from "typedef/Ganador";
import GanadorCard from "components/GanadorCard";
export type TabsProps = {
  convo: any;
};

const Details: React.FC<TabsProps> = ({ convo }) => {
  const { ganadores, loading: ganadoresLoading } = useGanadores(convo.id);
  const [search, setsearch] = useState("");
  const { propuestas, loading } = usePropuestas(convo.id, search);

  return (
    <>
      {ganadoresLoading && loading ? (
        <div className='d-flex justify-content-center'>
          {" "}
          <Loader />
        </div>
      ) : (
        <Tabs position='justify-content-center' colorHasGray={true}>
          <Tab title={"Información"}>
            {/* INFORMACION */}
            <AccordionConvo convo={convo} />
          </Tab>
          {convo?.juries && convo.juries.length > 0 && (
            <Tab title={"Jurado"}>
              {/* JURADO */}
              {convo?.juries?.map((jurado: any, index: number) => (
                <>
                  <JuradoCard jurado={jurado} />
                  <hr className='col-sm-12 col-md-8' />
                </>
              ))}
            </Tab>
          )}
          {convo.state === "in_voting" || convo.state === "finished" ? (
            <Tab title={"Propuestas Presentadas"}>
              <div className='bg-white d-flex mt-5 mb-3 justify-content-lg-between justify-content-sm-center rounded'>
                <h6 className='p-4 d-none d-lg-block'>Listado de propuestas</h6>
                <input
                  type='text'
                  onChange={({ currentTarget }) =>
                    setsearch(currentTarget.value)
                  }
                  className='search-bar'
                  placeholder='¿Qué propuesta estás buscando?'
                />
              </div>

              <div className='container'>
                <div className='d-flex flex-row row justify-content-between'>
                  {propuestas?.map(propuesta => (
                    <PropuestaCard
                      estadoConvocatoria={convo.state}
                      evaluation_metodology={convo.evaluation_metodology}
                      key={propuesta.id}
                      propuesta={propuesta}
                    />
                  ))}
                </div>
              </div>
            </Tab>
          ) : null}
          {convo.state === "finished" && (
            <Tab title={"Ganadores"}>
              <div className='container'>
                <div className='d-flex flex-row row justify-content-center mb-5'>
                  {ganadores.map((ganador: Ganador, key) => (
                    <>
                      <div
                        key={key}
                        className='d-flexmt-3 mt-3 p-3 col-md-8 col-sm-12 rounded'>
                        <h5
                          className='font-weight-bolder'
                          style={{ color: VIOLETDARK }}>
                          {`${key + 1}° puesto`}
                        </h5>
                      </div>
                      <GanadorCard ganador={ganador} />
                    </>
                  ))}
                </div>
              </div>
            </Tab>
          )}
        </Tabs>
      )}
    </>
  );
};

export default Details;
