import { gql, useMutation } from "@apollo/client";

const useParticipar = (
  convocatoriaID: string,
  sobreMI: string,
  title: string,
  archivos: [File]
) => {
  const [mutate] = useMutation(query, {
    variables: {
      annoucement_id: convocatoriaID,
      about: sobreMI,
      description: title,
      files: archivos.filter(item => typeof item !== "string"),
      links: archivos.filter(item => typeof item === "string")
    }
  });
  async function participar() {
    try {
      await mutate();
    } catch (error) {
      console.error(
        `Parece que tuvimos un problema al subir la Propuesta, ${JSON.stringify(
          error
        )}`
      );
      alert("Parece que tuvimos un problema");
    }
  }
  return participar;
};
const query = gql`
  mutation NewProposalComplex(
    $annoucement_id: Int!
    $about: String!
    $description: String!
    $files: [Upload]
    $links: [String]
  ) {
    create_complex_proposal(
      annoucement_id: $annoucement_id
      about: $about
      description: $description
      files: $files
      links: $links
    ) {
      about
      description
    }
  }
`;
export default useParticipar;
