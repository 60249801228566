import React, { useState } from "react";
import AccordionItem from "./AccordionItem";
import "./index.scss";

const AccordionPointOfInterest = ({ accordionElements }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const renderedAccordionElements = accordionElements.map(item => {
    const showDescription = item.id === activeIndex ? "show-description" : "";
    const ariaExpanded = item.id === activeIndex ? "true" : "false";
    return (
      <AccordionItem
        key={item.id}
        showDescription={showDescription}
        ariaExpanded={ariaExpanded}
        item={item}
        index={item.id}
        onClick={() => {
          setActiveIndex(item.id);
        }}
      />
    );
  });

  return (
    <div className='faq'>
      <div className='faq__list'>{renderedAccordionElements}</div>
    </div>
  );
};

export default AccordionPointOfInterest;
