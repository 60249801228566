import { IconName } from "assets/icons";

export type SafetyTip = {
  key: string;
  title: string;
  icon: IconName;
};

const useSafetyTips = (): Array<SafetyTip> => {
  return [
    {
      key: "1",
      title: "Usá siempre tapabocas.",
      icon: "FacemaskCircle"
    },
    {
      key: "2",
      title: "Mantené el distanciamiento social  \n" + "(mínimo 2 metro).",
      icon: "DistanceCircle"
    },
    {
      key: "3",
      title: "Evitá lugares muy concurridos.",
      icon: "AvoidClosenessCircle"
    },
    {
      key: "4",
      title:
        "Leé y prestá atención a las recomendaciones de los prestadores turísticos para prevenir el contagio.",
      icon: "EditCircle"
    },
    {
      key: "5",
      title: "Privilegiá las actividades al aire libre.",
      icon: "PalmerCircle"
    },
    {
      key: "6",
      title:
        "Si tenés algunos de estos síntomas, tomá contacto con las autoridades de salud locales: más de 37° de temperatura.\nPérdida del olfato o gusto, dolor de garganta o falta de aire.",
      icon: "TemperatureCircle"
    },
    {
      key: "7",
      title:
        "Antes de viajar, consultá las fuentes de información locales para seguir sus recomendaciones.",
      icon: "LuggageCircle"
    },
    {
      key: "8",
      title:
        "Recordá llevar con vos alcohol en gel, sanitizante de manos y/o tollas o pañuelos descartables.",
      icon: "HandSanitizerCircle"
    }
  ];
};

export default useSafetyTips;
