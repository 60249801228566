import { gql, useMutation } from "@apollo/client";
import useUser from "hooks/useUser";
import User from "typedef/User";

const useParticipar = (
  convocatoriaID: string | null,
  convocatoriaItemID: number | undefined,
  sobreMI: string | undefined,
  title: string | undefined,
  archivo: File | string | any | undefined,
  step: () => void
) => {
  //si es un link o un file.
  let link = typeof archivo?.path ? archivo?.path : undefined;
  archivo = link ? undefined : archivo;

  const [mutate] = useMutation(query, {
    variables: {
      annoucement_id: convocatoriaID,
      annoucement_item_id: convocatoriaItemID,
      about: sobreMI,
      description: title,
      file: archivo,
      link: link
    }
  });
  async function participar() {
    try {
      await mutate();
      step();
    } catch (error) {
      console.error(
        `Parece que tuvimos un problema al subir la Participación, ${JSON.stringify(
          error
        )}`
      );
      alert("Parece que tuvimos un problema");
    }
  }
  return participar;
};
const query = gql`
  mutation NewProposal(
    $annoucement_id: Int!
    $annoucement_item_id: Int!
    $about: String!
    $description: String!
    $file: Upload
    $link: String
  ) {
    create_proposal(
      annoucement_id: $annoucement_id
      annoucement_item_id: $annoucement_item_id
      about: $about
      description: $description
      file: $file
      link: $link
    ) {
      about
      description
    }
  }
`;
export default useParticipar;
