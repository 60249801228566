import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import Recess from "typedef/Recess";
import getStartingDate from "./getStartingDate";

export type AgendaDay = {
  date: Date;
  recesses: Array<Recess>;
};

/**
 *  Hook para manejar la agenda de la semana dado un offset
 * @param offset días de corrimiento desde la fecha
 * @param incomingAgenda listado de eventos del recreo múltiple
 * @param startingDate día inicial en el que se debe posicionar la agenda
 */

const useAgenda = (
  offset: number,
  incomingAgenda: Array<Recess>,
  pickDate: any
) => {
  const [agenda, setAgenda] = useState<AgendaDay[]>([]);
  const startingDate = useMemo(
    () => getStartingDate(incomingAgenda, pickDate),
    [incomingAgenda, pickDate]
  );

  useEffect(() => {
    setAgenda([
      {
        date: dayjs(startingDate)
          .add(0 + offset, "days")
          .toDate(),
        recesses:
          incomingAgenda?.filter(recess =>
            dayjs(recess.date_from).isSame(
              dayjs(startingDate).add(0 + offset, "days"),
              "day"
            )
          ) || []
      },
      {
        date: dayjs(startingDate)
          .add(1 + offset, "days")
          .toDate(),
        recesses:
          incomingAgenda?.filter(recess =>
            dayjs(recess.date_from).isSame(
              dayjs(startingDate).add(1 + offset, "days"),
              "day"
            )
          ) || []
      },
      {
        date: dayjs(startingDate)
          .add(2 + offset, "days")
          .toDate(),
        recesses:
          incomingAgenda?.filter(recess =>
            dayjs(recess.date_from).isSame(
              dayjs(startingDate).add(2 + offset, "days"),
              "day"
            )
          ) || []
      },
      {
        date: dayjs(startingDate)
          .add(3 + offset, "days")
          .toDate(),
        recesses:
          incomingAgenda?.filter(recess =>
            dayjs(recess.date_from).isSame(
              dayjs(startingDate).add(3 + offset, "days"),
              "day"
            )
          ) || []
      },
      {
        date: dayjs(startingDate)
          .add(4 + offset, "days")
          .toDate(),
        recesses:
          incomingAgenda?.filter(recess =>
            dayjs(recess.date_from).isSame(
              dayjs(startingDate).add(4 + offset, "days"),
              "day"
            )
          ) || []
      },
      {
        date: dayjs(startingDate)
          .add(5 + offset, "days")
          .toDate(),
        recesses:
          incomingAgenda?.filter(recess =>
            dayjs(recess.date_from).isSame(
              dayjs(startingDate).add(5 + offset, "days"),
              "day"
            )
          ) || []
      },
      {
        date: dayjs(startingDate)
          .add(6 + offset, "days")
          .toDate(),
        recesses:
          incomingAgenda?.filter(recess =>
            dayjs(recess.date_from).isSame(
              dayjs(startingDate).add(6 + offset, "days"),
              "day"
            )
          ) || []
      }
    ]);
  }, [offset, incomingAgenda, pickDate]);

  return [agenda];
};

export default useAgenda;
