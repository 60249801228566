import { useReducer } from "react";

const reducer = (formData: any, { type, payload }) => {
  switch (type) {
    case "city_hall":
      return {
        ...formData,
        city_hall: payload as string
      };
    case "activity":
      return {
        ...formData,
        activity: payload as string
      };
    case "name":
      return {
        ...formData,
        name: payload as string
      };
    case "lastname":
      return {
        ...formData,
        lastname: payload as string
      };
    case "email":
      return {
        ...formData,
        email: payload as string
      };
    case "web":
      return {
        ...formData,
        web: payload as string
      };
    case "phone":
      return {
        ...formData,
        phone: payload as string
      };
    case "municipio":
      return {
        ...formData,
        municipio: payload as string
      };
    case "actividad":
      return {
        ...formData,
        actividad: payload as string
      };
    case "categoria":
      return {
        ...formData,
        categoria: payload as string
      };
    case "facebook_profile":
      return {
        ...formData,
        facebook_profile: payload as string
      };
    case "instagram_profile":
      return {
        ...formData,
        instagram_profile: payload as string
      };
    case "phone":
      return {
        ...formData,
        phone: payload as string
      };
    case "detail":
      return {
        ...formData,
        detail: payload as string
      };
    default:
      return {
        ...formData,
        [type]: payload
      };
  }
};

export default reducer;
