import Benefit from "typedef/Benefit";
import CityHall from "typedef/CityHall";
import Recess from "typedef/Recess";

function getType(
  entity: Recess | CityHall | Benefit
): "recreo-simple" | "recreo-multiple" | "municipio" | "beneficio" {
  if ((entity as Recess)?.circuit?.length) return "recreo-multiple";
  if ((entity as Recess)?.agenda?.length) return "recreo-multiple";
  if ((entity as CityHall)?.extra_information) return "municipio";
  if ((entity as Benefit)?.__typename === "BenefitType") return "beneficio";
  return "recreo-simple";
}

export default getType;
