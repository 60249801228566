import * as React from "react";

function SvgGenderFluid(props) {
  return (
    <svg
      width={18.5}
      height={16}
      viewBox='0 0 19 17'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M9.817 6.947a4.329 4.329 0 10-8.658 0 4.329 4.329 0 008.658 0zM8.55 9.232a3.8 3.8 0 010-4.57 3.814 3.814 0 010 4.57zM1.666 6.947a3.82 3.82 0 015.618-3.372 5.467 5.467 0 000 6.744 3.82 3.82 0 01-5.618-3.372zm6.05 3.103a4.964 4.964 0 010-6.205c.177.127.343.269.495.424a4.314 4.314 0 000 5.356 3.844 3.844 0 01-.495.425z' />
      <path d='M18.293.008h-2.61a.253.253 0 00-.179.433l.716.716-1.368 1.368a5.499 5.499 0 00-3.244-1.066 5.44 5.44 0 00-3.058.935 5.487 5.487 0 10-3.895 9.977v1.098h-.851a.254.254 0 00-.254.253v1.16c0 .14.114.253.254.253h.851v.832c0 .14.114.253.254.253h1.159c.14 0 .253-.113.254-.253v-.832h.85c.14 0 .254-.113.254-.253v-1.16a.254.254 0 00-.253-.253h-.851V12.37a5.463 5.463 0 002.231-.87 5.484 5.484 0 007.478-7.8l1.367-1.366.716.716a.254.254 0 00.433-.18V.263a.254.254 0 00-.254-.254zM6.04 11.896a.254.254 0 00-.226.252v1.574c0 .14.114.254.254.254h.851v.652h-.851a.254.254 0 00-.254.253v.832h-.652v-.832a.254.254 0 00-.253-.253h-.852v-.652h.851c.14 0 .254-.114.254-.254v-1.573a.254.254 0 00-.225-.253 4.978 4.978 0 114.332-1.705.253.253 0 00-.054.06l-.005.01c-.233.26-.492.496-.774.703a.246.246 0 00-.07.051 4.962 4.962 0 01-2.326.881zm3.774-8.32a3.822 3.822 0 11.005 6.746 5.484 5.484 0 00-.005-6.747zM18.04 2.26l-.462-.463a.253.253 0 00-.359 0l-1.704 1.705a.253.253 0 00-.019.337 4.922 4.922 0 011.093 3.108 4.987 4.987 0 01-4.98 4.981 4.932 4.932 0 01-2.622-.75c.175-.144.34-.299.496-.464a4.329 4.329 0 10-.006-7.53 5.53 5.53 0 00-.493-.465 4.96 4.96 0 015.732.34c.101.08.247.073.338-.019l1.704-1.704a.253.253 0 000-.358l-.462-.462h1.744V2.26z' />
    </svg>
  );
}

export default SvgGenderFluid;
