import { useEffect, useState } from "react";

const useCarruselSelected = (
  carruselLenght: number,
  carruselHaveVideo: boolean
): [number, React.Dispatch<React.SetStateAction<number>>, boolean] => {
  const [carruselSelected, setCarruselSelected] = useState(0);

  useEffect(() => {
    if (!carruselHaveVideo) {
      let max = carruselLenght - 1;

      const internalId = setInterval(
        () =>
          setCarruselSelected(previousCarruselSelected => {
            if (previousCarruselSelected === max) {
              return 0;
            } else {
              return previousCarruselSelected + 1;
            }
          }),
        9000
      );

      return () => clearInterval(internalId);
    }
  }, [carruselLenght]);

  return [carruselSelected, setCarruselSelected];
};

export default useCarruselSelected;
