import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCDLMPkz8fip03TfKEqEsEc8PL32O9KmGM",
  authDomain: "recreo-b9fe5.firebaseapp.com",
  databaseURL: "https://recreo-b9fe5.firebaseio.com",
  projectId: "recreo-b9fe5",
  storageBucket: "recreo-b9fe5.appspot.com",
  messagingSenderId: "856075297719",
  appId: "1:856075297719:web:5cd95c4d5fa762032e3e32",
  measurementId: "G-GWXKR5YXQB"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
