import React, { useEffect, useState } from "react";
import Notification from "./Notification";
import useNotifications from "./useNotifications";
import { useCallback } from "react";
import Loader from "assets/images/loader.svg";
import useCategories from "./useCategories";
import MainIcon from "assets/images/bellIcon-purple.svg";
import UtiliyBar from "components/UtilityBar";
import useSearchQuery from "hooks/useSearchQuery";
import useNotificationToRead from "./useNotificationToRead";
import ErrorMSJ from "components/ErrorMSJ";
import useLangContext from "hooks/useLangContext";

const NotificationsList = () => {
  const [selectedCategory, selectCategory] = useState();
  const [notifications, loading, reload] = useNotifications(selectedCategory);
  const [categories] = useCategories(notifications);
  const { lang } = useLangContext();

  const searchQuery = useSearchQuery();

  useEffect(() => {
    if (searchQuery.get("filtro")) {
      selectCategory(searchQuery.get("filtro"));
    }
  }, [searchQuery]);

  return (
    <div className='container my-4'>
      <div className='section-title mx-4 d-flex align-items-center my-4'>
        <img src={MainIcon} className='mr-4' />
        <h2 className='text-secondary font-weight-bold'>
          {lang.Configurations.notification}
        </h2>
      </div>

      <div className='container notifications p-0'>
        <UtiliyBar utils={categories} hasSearchBar={false} className='mb-5' />

        {!loading && notifications.length ? (
          <div>
            {notifications.map((notification, i) => {
              return (
                <Notification
                  notification={notification}
                  categories={categories}
                />
              );
            })}
          </div>
        ) : (
          <div className='noResults text-center'>
            {loading && notifications.length == 0 ? (
              <img src={Loader} key={1} />
            ) : (
              <ErrorMSJ
                title={"Ups! Parece que aún no tienes notificaciones"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsList;
