import BTNBack from "components/BTNBack";
import MyReCreditCard from "components/MyReCreditCard";
import { useState } from "react";
import { useParams } from "react-router-dom";
import useRecredit from "screens/ReCredit/useRecredit";
import useSendMail from "./useSendMail";

const ReCreditContact = () => {
  const [message, setMessage] = useState("");
  const param = useParams<{ id: string }>();
  const { recredit, recreditID } = useRecredit(param.id);
  const sendMail = useSendMail();

  const send = (event: React.FormEvent<HTMLFormElement>) => {
    sendMail.save(param.id, message);
    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <div className='container-fluid my-4'>
      <div className='mb-5'>
        <BTNBack />
        <h3 className='text-secondary font-weight-bold text-center'>
          Contacto
        </h3>
      </div>
      <div className='col-12 col-md-6 m-auto'>
        <form onSubmit={send}>
          <b>Sobre qué queres contactarnos</b>
          <select style={{ color: "black" }} name='ReCréditos'>
            <option value={"ReCrédito"}>ReCréditos</option>
          </select>
          {recredit && <MyReCreditCard fullWidth={true} recredit={recredit} />}
          <div className='my-3'>
            <b>Motivo</b>
            <select style={{ color: "black" }} name='Reclamo'>
              <option value={"Reclamo"}>Reclamo</option>
            </select>
          </div>
          <div className='my-3'>
            <b>Mensaje</b>
            <textarea
              onChange={e => setMessage(e.target.value)}
              name='Mensaje'
              rows='5'
            />
          </div>
          <div style={{ marginBottom: 100 }}>
            <button
              type='submit'
              className='secondary secondary--magenta d-block m-auto'>
              {sendMail.loadingAction && (
                <span
                  className='spinner-border spinner-border-sm mr-2'
                  role='status'
                  aria-hidden='true'
                />
              )}
              Enviar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ReCreditContact;
