export default {
  title: "Mis Beneficios",
  tabBenefit: "Beneficios",
  tabReCredit: "Créditos",
  noResultCredit: "No hay Créditos disponibles",

  code: "Código",
  currency: "Vigencia",
  qrLoadError: "No pudimos cargar el código QR"
};
