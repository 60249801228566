import * as React from "react";

function SvgEditCircle(props) {
  return (
    <svg
      width={27}
      height={27}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 27 27'
      {...props}>
      <g fill='none' fillRule='evenodd'>
        <circle
          cx={12.65}
          cy={12.65}
          r={12.65}
          stroke='#666'
          strokeWidth={0.55}
          transform='translate(1 .6)'
        />
        <g fill='#666'>
          <path d='M18.37 19.573H7.81V6.197h5.983V5.14H7.81c-.583 0-1.056.473-1.056 1.056v13.376c0 .583.473 1.056 1.056 1.056h10.56c.584 0 1.056-.473 1.056-1.056v-6.688H18.37v6.688z' />
          <path d='M20.172 6.457l-.747-.746a1.056 1.056 0 00-1.492 0s-5.868 5.86-5.956 6.216l-.264 1.056a1.056 1.056 0 001.281 1.282L14.05 14c.352-.092 6.122-6.048 6.122-6.048a1.056 1.056 0 000-1.492v-.004zm-5.703 5.703l-.721-.722-.025-.021 4.956-4.956.746.746-4.956 4.953z' />
        </g>
      </g>
    </svg>
  );
}

export default SvgEditCircle;
