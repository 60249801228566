export default {
  seeMore: "Ver Más",
  seeLess: "Ver Menos",
  btnViewMap: "VER EN EL MAPA",
  btnReturn: "Volver",
  btnContinue: "Continuar",
  btnSend: "Enviar",
  btnDelete: "Eliminar",
  bookTickets: "Reservar Entradas",
  save: "Guardar",
  all: "Todos",
  shareTextUp: "Compartir este contenido:",
  shareTextDown: "O copia el siguiente enlace:"
};
