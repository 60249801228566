import { useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import useGPS from "hooks/useGPS";
import useSearch from "./useSearch";
import { useRef } from "react";
import Recess from "typedef/Recess";
import useSearchQuery from "../../hooks/useSearchQuery";
import useLangContext from "hooks/useLangContext";

const useRecesses = () => {
  const page = useRef(1);
  const [coords] = useGPS();
  const searchQuery = useSearchQuery();
  const search = searchQuery.get("busqueda") || "";
  const categoryId = searchQuery.get("filtro") || "";
  const [limitReached, setLimitReached] = useState(false);
  const [recesses, setRecesses] = useState<Array<Recess>>([]);
  const [categoryRecesses, setCategoryRecesses] = useState<Array<Recess>>([]);
  const [searchedReceses] = useSearch(search);
  const { lang } = useLangContext();
  const filteredRecesses = useMemo<Recess[]>(() => {
    if (search) return searchedReceses;
    if (categoryId) return categoryRecesses;
    return recesses;
  }, [recesses, searchedReceses, categoryRecesses, categoryId, search]);

  /**Query for the categoryId recesses */
  const { loading } = useQuery(getCategoryRecesses, {
    fetchPolicy: "no-cache",
    onCompleted: ({
      recess_category_detail: { recesses, recesses_multiple }
    }) => setCategoryRecesses([...recesses, ...recesses_multiple]),
    onError: error =>
      console.error(
        `Parece que tuvimos un problema al cargar los recreos de la categoría: ${error}`
      ),
    variables: {
      id: categoryId,
      lang: lang.current
    }
  });

  /**Query for the pagination of recesses */
  const { refetch, fetchMore } = useQuery(getPaginationRecesses, {
    fetchPolicy: "no-cache",
    onCompleted: ({ recess_list: { data: recesses } }) => setRecesses(recesses),
    onError: err =>
      console.error(
        "Tuvimos un problema al cargar los recreos",
        JSON.stringify(err)
      ),
    variables: {
      page: 1,
      latitude: coords?.latitude,
      longitude: coords?.longitude,
      lang: lang.current
    }
  });

  function _fetchMore() {
    if (limitReached) return;
    fetchMore({
      variables: {
        page: ++page.current,
        latitude: coords?.latitude,
        longitude: coords?.longitude,
        lang: lang.current
      }
    })
      .then(
        ({
          data: {
            recess_list: { data: recesses, last_page }
          }
        }) => {
          setRecesses(prev => [...prev, ...recesses]);
          if (parseInt(last_page) <= page.current) {
            setLimitReached(true);
          }
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema al cargar más municipios", error)
      );
  }

  function _refetch() {
    refetch({
      page: 1,
      latitude: coords?.latitude,
      longitude: coords?.longitude,
      lang: lang.current
    })
      .then(
        ({
          data: {
            recess_list: { data: recesses }
          }
        }) => {
          setRecesses(recesses);
          page.current = 1;
          setLimitReached(false);
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema recargando los municipios", error)
      );
    page.current = 1;
  }

  return useMemo(
    () => ({
      list: filteredRecesses,
      loading,
      refetch: _refetch,
      fetchMore: _fetchMore,
      limitReached
    }),
    [filteredRecesses, loading, limitReached]
  );
};

const getCategoryRecesses = gql`
  query getRecessesCategory($id: String!, $lang: String) {
    recess_category_detail(id: $id, lang: $lang) {
      name
      icon {
        uri
      }
      recesses(lang: $lang) {
        id
        title
        date_from
        longitude
        latitude
        is_favorite
        is_multiple
        score
        icon {
          uri
        }
        categories(lang: $lang) {
          id
          name
          icon {
            uri
          }
        }
        city_hall {
          name
        }
      }
      recesses_multiple {
        id
        title
        date_from
        score
        is_favorite
        is_multiple
        circuit {
          id
        }
        agenda {
          id
        }
        icon {
          uri
        }
      }
    }
  }
`;

const getPaginationRecesses = gql`
  query getRecesses(
    $page: Int
    $longitude: Float
    $latitude: Float
    $limit: Int
    $lang: String
  ) {
    recess_list(
      limit: $limit
      page: $page
      longitude: $longitude
      latitude: $latitude
      lang: $lang
      space_proximity: 100
    ) {
      data {
        id
        title
        date_from
        date_to
        longitude
        score
        latitude
        is_favorite
        is_multiple
        agenda {
          id
        }
        circuit {
          id
        }
        categories(lang: $lang) {
          id
          name
          icon {
            uri
          }
        }
        city_hall {
          name
        }
        icon {
          uri
        }
      }
      last_page
    }
  }
`;

export default useRecesses;
