import * as React from "react";

function SvgBicolorBlueMagenta(props) {
  return (
    <svg width={46} height={61} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path fill='#12B6CA' d='M-17 0h61v61h-61z' />
        <path fill='#E6017E' d='M13.5 0h32v61h-32z' />
      </g>
    </svg>
  );
}

export default SvgBicolorBlueMagenta;
