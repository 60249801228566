import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Alert } from "react-native";

/**
 * Hook to get categories of recesses
 * @return {[Array<RecessCategory>, boolean]}
 */
const useRecessCategories = () => {
  const [categories, setCategories] = useState([]);
  const { loading } = useQuery(query, {
    onCompleted: ({ recess_category_list: { data: categories } }) =>
      setCategories(categories),
    onError: error => {
      setTimeout(() => {
        Alert.alert(
          "Tuvimos un problema",
          "No pudimos cargar las categorías de ReCreos."
        );
      }, 1000);
      console.error(
        `Tuvimos un problema al cargar las categorías de recreos: ${JSON.stringify(
          error
        )}`
      );
    }
  });

  return [categories, loading];
};

const query = gql`
  {
    recess_category_list {
      data {
        id
        name
        icon {
          uri
        }
      }
    }
  }
`;

export default useRecessCategories;
