import { useState } from "react";
import { gql, useQuery } from "@apollo/client";

const useRecess = id => {
  const [recess, setRecess] = useState({});
  const { loading } = useQuery(query, {
    onCompleted: ({ recess_detail: recess }) => setRecess(recess),
    onError: error =>
      console.error(`Tuvimos un error: ${JSON.stringify(error)}`),
    variables: {
      id
    }
  });

  return [recess, loading];
};

const query = gql`
  query getDetails($id: String!) {
    recess_detail(id: $id) {
      title
    }
  }
`;

export default useRecess;
