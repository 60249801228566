import { RegisterContext } from "hooks/useRegisterContext";
import React, { useCallback, useContext, useMemo, useReducer } from "react";
import User from "typedef/User";
import reducer from "./reducer";
import useRegister from "./useRegister";

const useForm = () => {
  const context = useContext(RegisterContext);
  const [user, dispatch] = useReducer(reducer, { tag_ids: [] });
  const [register, { loading }] = useRegister({
    state: "Buenos Aires",
    city_hall_id: 68,
    locality_id: 1079,
    ...user,
    ...context.data
  });

  /** Function to get a ChangeEvent that set's the given property */
  const set = useCallback(
    (attribute: keyof User | "tag_ids") => (
      event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
    ) => {
      dispatch({
        type: attribute,
        payload: event.currentTarget.value
      });
    },
    []
  );

  const submit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      register();
    },
    [user, register]
  );

  return useMemo(
    () => ({
      loading,
      submit,
      user,
      set
    }),
    [user, loading, submit, set]
  );
};

export default useForm;
