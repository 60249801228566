import useLangContext from "hooks/useLangContext";
import { FC } from "react";
import Map from "../../assets/images/map-card.svg";

export interface BTNProps extends React.HTMLProps<HTMLDivElement> {
  goToMap?: () => void;
  difClass?: string; //si no se setea otro estilo , se agregara por defecto el estilo del boton magenta
  filter?: string; //cambia el color del icono
  Text?: string; // si deseo ponerle otro texto que no sea ver en el mapa lo puedo hacer
}

const BTNViewInTheMap: FC<BTNProps> = ({ goToMap, difClass, filter, Text }) => {
  const { lang } = useLangContext();
  return (
    <button
      onClick={() => goToMap?.()}
      style={{ width: "max-content" }}
      className={`btn-view-map text-uppercase ${
        !difClass ? "secondary secondary--magenta d-block" : difClass
      }`}>
      {!Text ? lang.Elements.btnViewMap : Text}
      <img style={{ filter: `${!filter ? "invert(100%)" : ""}` }} src={Map} />
    </button>
  );
};

export default BTNViewInTheMap;
