export default {
  title: "Crie sua Conta",
  subTitle: "Como você quer criar sua conta?",
  loginFacebook: "Conectar com FaceBook",
  loginGmail: "Conectar com Gmail",
  createwithEmail: "Criar conta com e-mail",
  acepptTermAndCondi:
    "Ao clicar em continuar você está aceitando nossos termos e condições ",
  ifHaveAccount: "Já possui uma conta? ",
  registerWithEmail: "Cadastre-se com seu e-mail",
  registerWithEmailPlaceholder: "Complete seu e-mail",
  registerWithEmailError: "E-mail inválido",
  createAPass: "Criar uma senha",
  passwordHelper:
    "Sua senha deve incluir mínimo de 8 caracteres, pelo menos um número e uma letra maiúscula",
  passworRepeat: "Repetir senha",
  passwordRule: "As senhas não coincidem",
  passwordNoCorrect: "A senha não está correta",

  recoverPassword: "Recupere sua senha",
  copyCode:
    "Copiá y pegá el código que te enviamos por e-mail para poder crear una nueva contraseña."
};
