export default {
  LogoTitle: "ReCreo",
  header: {
    top: {
      vaccination: "Vaccination and COVID Testing",
      destiny: "Selected Destinations",
      termsAndCondi: "Terms and Conditions",
      frequentQuestions: "FAC",
      downloadRecreo: "Download ReCreo"
    },
    main: {
      knowCityHall: "Know the Province",
      recreos: "ReCreos",
      schedule: "Events",
      convocatorias: "Calls",
      benefit: "Benefits",
      login: "Sign In",
      singIn: "Create Account"
    },
    login: {
      editProfile: "Edit My Data",
      level: "Level",
      virtual: "virtual",
      curious: "Ciropis",
      explorer: "Explorer",
      expert: "Expert",
      points: "points",
      myFavs: "My Favourites",
      myBenefits: "My Benefits",
      myTickets: "My Tickets",
      mySchedule: "My Schedule",
      polls: "Surveys",
      notifications: "Notifications",
      configurate: "Configuration",
      logOut: "Exit"
    }
  }
};
