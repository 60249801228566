export default {
  startTour: "começar a turnê",
  safetyPin: "imperdível",
  pointOfInt: "Pontos de interesse",
  noResultBenefit1: "Opa Ainda não há benefícios ",
  noResultBenefit2:
    " Ingresá a Beneficios y conocé los ReBeneficios que tenemos para vos",
  noResultImperdibles: "Ainda não há alfinetes de segurança disponíveis em ",
  didYouKnow: "¿Sabia que?"
};
