const translateDayApply = daysEnglish => {
  let daysSpanish = [
    "lunes",
    "martes",
    "miercoles",
    "jueves",
    "viernes",
    "sabado",
    "domingo"
  ];
  let apply_days = [];
  if (daysEnglish != undefined) {
    daysEnglish.map(day => {
      switch (day) {
        case "sunday":
          apply_days?.push("domingo");
          break;
        case "monday":
          apply_days?.push("lunes");
          break;
        case "tuesday":
          apply_days?.push("martes");
          break;
        case "wednesday":
          apply_days?.push("miercoles");
          break;
        case "thursday":
          apply_days?.push("jueves");
          break;
        case "friday":
          apply_days?.push("viernes");
          break;
        case "saturday":
          apply_days?.push("sabado");
          break;
        default:
          break;
      }
    });
    let diasApplicado = daysSpanish.map((dia: string) => {
      return apply_days?.indexOf(dia) !== -1 ? dia : undefined;
    });
    return diasApplicado;
  }
};

export default translateDayApply;
