import React, { useState } from "react";
import useBenefit from "./useBenefit";
import TextualHero from "../../components/TextualHero/index";
import DaysList from "../../components/DaysList/DaysList";
import dayjs from "dayjs";
import "dayjs/locale/es";
import classes from "./classes.module.scss";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import RecreoFeatures from "components/RecreoFeatures";
import useScrollToTop from "../../hooks/useScrollToTop";
import useScrollToMap from "./useScrollToMap";
import Map from "components/Map";
import WhereToUse from "components/WhereToUse";
import { formatExternalAssetUrl } from "helpers";
import useLangContext from "hooks/useLangContext";
import { useParams } from "react-router-dom";

const Benefit = () => {
  const param = useParams<{ id: string; map?: string }>();
  const [tabPosition, setTabPosition] = useState(param.map ? 2 : 0);

  const benefit = useBenefit(param.id, () => setTabPosition(2));
  const mapa = React.useRef(null);
  const { lang } = useLangContext();
  useScrollToTop();
  useScrollToMap();

  return (
    <div className='benefit'>
      <TextualHero
        onSaveBenefit={benefit.save}
        loadingOnSaveBenefit={benefit.loadingAction}
        children={benefit}
        ref={mapa.current}
      />
      <div className={`container-fluid p-0 ${classes.marginButton}`}>
        <div className='overflow-auto'>
          <div className='container d-flex list-unstyled  undefined'></div>
          <Tabs
            moveTabTo={tabPosition}
            position='justify-content-center'
            hasSearch={false}
            colorHasGray={true}>
            {/* DETALLES */}
            <Tab title={lang.Benefit.tabDetail}>
              <div className='container'>
                <div className='container-fluid py-4 col-12 col-md-7 col-lg-9 mx-auto my-5 '>
                  <div className='description'>{benefit.description}</div>
                  <hr />
                  <div className={`${classes.details}`}>
                    <DaysList days={benefit.apply_days} />
                    <div className={`${classes.validity_to}`}>
                      Vigente hasta el{" "}
                      {dayjs(benefit.validity_to).format("DD/MM/YYYY")}
                    </div>
                  </div>
                  <hr />
                  {benefit?.is_combinable && benefit?.combinate && (
                    <>
                      <div>
                        <b className='my-2'>Combinalos</b>
                        <div className='row justify-content-between my-3 p-0 m-0'>
                          {benefit.combinate?.services_combinate?.map(
                            (service_combinate, index) => (
                              <img
                                key={index}
                                className={`col p-0 m-1 ${classes.combinateImage}`}
                                src={formatExternalAssetUrl({
                                  uri: service_combinate.logo
                                })}
                                alt={service_combinate.product}
                              />
                            )
                          )}
                        </div>
                        <div>{benefit.combinate?.description}</div>
                      </div>
                      <div className='text-muted my-3'>
                        {benefit.combinate?.legals}
                      </div>
                      <hr />
                    </>
                  )}
                  <div className='text-muted my-4'>{benefit.legals}</div>
                  {benefit.type !== "pretrip" && (
                    <button
                      onClick={benefit.save}
                      className='secondary secondary--magenta d-block my-5'>
                      {benefit.loadingAction && (
                        <span
                          className='spinner-border spinner-border-sm mr-2'
                          role='status'
                          aria-hidden='true'
                        />
                      )}
                      {lang.Benefit.getBenefit}
                    </button>
                  )}
                  <hr />
                  <div className='d-flex align-items-center mb-4'>
                    <RecreoFeatures
                      features={[
                        {
                          icon: "Recycle",
                          available: benefit.commerce?.sustainability
                        },
                        {
                          icon: "Accesibility",
                          available: benefit.commerce?.is_accessibility
                        },
                        {
                          icon: "HandSanitizer",
                          available: benefit.commerce?.preventive_measures
                        },
                        {
                          icon: "GenderFluid",
                          available: benefit.commerce?.gender_diversity
                        }
                      ]}
                    />
                  </div>
                </div>
              </div>
            </Tab>
            <Tab title={lang.Benefit.whereUse}>
              {/* Donde lo uso */}
              {benefit.commerce?.branch_offices && (
                <WhereToUse branch_offices={benefit.commerce?.branch_offices} />
              )}
            </Tab>
            {!benefit.commerce?.branch_offices[0]["is_virtual"] ? (
              <Tab title={lang.Benefit.tabMap}>
                {/* Mapa */}
                <div className={`container-fluid py-4`} id='mapa' ref={mapa}>
                  <div className='container'>
                    <div className='row' id='mapaBenefit'>
                      <div className='w-100'>
                        {benefit?.commerce && (
                          <Map
                            isVisible={true}
                            ubications={[
                              {
                                id: benefit?.id,
                                name: benefit.commerce?.name,
                                longitude: benefit.commerce?.longitude,
                                latitude: benefit.commerce?.latitude
                              }
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Tab>
            ) : (
              <></>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Benefit;
