import Icon from "assets/icons";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import ReCredit from "typedef/ReCredits";

type MyReCreditCardProps = {
  recredit?: ReCredit;
  fullWidth?: boolean;
  useTime?: string;
  recreditId?: string;
};

const MyReCreditCard: React.FC<MyReCreditCardProps> = ({
  recredit,
  fullWidth,
  useTime,
  recreditId
}) => {
  const history = useHistory();
  return (
    <div className={`${fullWidth ? "w-100" : "col-12 col-md-6 mb-3"}`}>
      <div
        style={{ height: 120 }}
        className={`bg-white p-3 d-flex flex-row overflow-hidden rounded`}>
        <div className='d-flex flex-column overflow-hidden w-100'>
          <div className='d-flex align-items-center mb-2'>
            <h5 className='d-flex'>
              <small className='mr-1 font-weight-bold'>
                {recredit?.cost_recredits}
              </small>
            </h5>
            Recréditos
          </div>
          <div>
            <b>{recredit?.commerce.name}</b>
          </div>
          <div>
            {recredit?.commerce.city_hall?.name}{" "}
            <span className='text-muted ml-2'>
              {recredit?.commerce.city_hall?.address}
            </span>{" "}
          </div>
        </div>
        <div className='d-flex flex-column justify-content-between'>
          {useTime && (
            <div style={{ width: "max-content" }} className='text-muted'>
              {dayjs(useTime).format("DD MMM YYYY")}
            </div>
          )}
          <div
            onClick={() => history.push(`contacto-recredito/${recreditId}`)}
            className='ml-auto clickable'>
            <Icon custom='Complain' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyReCreditCard;
