import Icon from "assets/icons";
import { formatExternalAssetUrl } from "helpers";
import { useHistory } from "react-router";
import ReCredit from "typedef/ReCredits";
import ShareIcon from "assets/images/share.svg";
import useSocialShare from "hooks/useSocialShare";

type ReCreditCardProps = {
  recredit: ReCredit;
  className?: string;
};

const ReCreditCard: React.FC<ReCreditCardProps> = ({ recredit, className }) => {
  const history = useHistory();

  const useShareSocial = useSocialShare(recredit.id, "recredito", recredit);

  const useShare = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    useShareSocial();
  };

  return (
    <div
      className='col-12 col-md-6 mb-3'
      onClick={() => history.push(`/recredit/${recredit.id}`)}>
      <div
        style={{ maxHeight: 150 }}
        className={`bg-white p-0 d-flex flex-row overflow-hidden clickable rounded ${className}`}>
        <div
          className='d-flex flex-column justify-content-end p-2'
          style={{
            minWidth: 150,
            minHeight: 150,
            maxWidth: 150,
            maxHeight: 150,
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 82.67%), url(" +
              `${formatExternalAssetUrl(recredit?.icon)}` +
              ") center / cover no-repeat"
          }}>
          {recredit.category.name && (
            <div className='d-flex align-items-center'>
              <img
                width={20}
                height={20}
                style={{
                  filter:
                    "brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7460%) hue-rotate(125deg) brightness(106%) contrast(100%)"
                }}
                src={formatExternalAssetUrl(recredit.category.icon)}
              />
              <h6 className='text-white ml-1 my-0 font-weight-500'>
                {recredit.category.name}
              </h6>
            </div>
          )}
        </div>
        <div className='px-3 py-2 d-flex flex-column overflow-hidden w-100'>
          <div className='text-secondary d-flex align-items-center'>
            <h5 className={`font-weight-bold mr-1`}>
              {recredit?.cost_recredits}
            </h5>
            <h6 className='font-weight-bold'>ReCréditos</h6>
          </div>
          <div>
            <b>{recredit.name}</b>
          </div>
          <div>{recredit.commerce.name}</div>
          <div>{recredit.commerce.city_hall?.name}</div> {/** a cambiar */}
          <div className='mt-auto text-muted d-flex align-items-center'>
            <b className='mr-1'>{recredit?.weekly_stock}</b> Stock disponible
          </div>
        </div>

        <div className='d-flex flex-column justify-content-between'>
          <button style={{ all: "unset" }} onClick={useShare}>
            <img className='p-1 m-2' src={ShareIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReCreditCard;
