import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Alert } from "react-native";
import CityHall from "typedef/CityHall";

/**
 *  Hook to get a list of City Halls
 */
const useCityHalls = (): [CityHall[], boolean] => {
  const [cityHalls, setCityHalls] = useState([]);
  const { loading } = useQuery(query, {
    onCompleted: ({ city_hall_list: { data: cityHalls } }) =>
      setCityHalls(cityHalls),
    onError: error => {
      console.error(`Error al cargar los municipios ${JSON.stringify(error)}`);
      Alert.alert(
        "Parece que tuvimos un problema",
        "No pudimos cargar correctamente el listado de municipios, por favor intentalo nuevamente más tarde. Podes ingresar el municipio posteriormente en la aplicación."
      );
    }
  });

  return [cityHalls, loading];
};

const query = gql`
  query getCityHalls {
    city_hall_list(limit: 200, filtered: false) {
      data {
        id
        name
      }
    }
  }
`;

export default useCityHalls;
