import BTNBack from "components/BTNBack";
import CardReward from "components/CardReward";
import Map from "components/Map";
import useGPS from "hooks/useGPS";
import useScrollToTop from "hooks/useScrollToTop";
import useImperdibles from "./useImperdibles";
import CardWay from "../../components/CardWay";
import useBurnQRModal from "components/BurnQRModal";
import classes from "./classes.module.scss";
import { useParams } from "react-router-dom";

/** esta ventana, tiene un comportamiento extraño , cuando es mobile , hay que pasar el map abajo y la lista arriba
 *
 * tambien ,el boton de volver atras, este cuando esta en modo ventana , se pone arriba de todo. pero si es escritorio,
 * se pone por encima del mapa.
 */
const WaysRoute = () => {
  const param = useParams<{ id: string }>();

  const [coords] = useGPS();
  const burnQR = useBurnQRModal();
  const { imperdibles, premio } = useImperdibles(param.id);
  useScrollToTop();
  return (
    <div className='mb-5'>
      <div className='my-4 container'>
        <div className={`${classes.buttonLogic}`}>
          {imperdibles.length > 0 && (
            <BTNBack rotateColor={window.screen.width > 768} />
          )}
        </div>
        <h4 className='text-secondary font-weight-bold text-center'>
          Recorrido
        </h4>
      </div>
      {/* si se esta en mobile, se despliega el mapa por debajo y el listado por arriba.*/}
      {window.screen.width > 768 ? (
        <>
          {imperdibles.length > 0 && (
            <Map
              ubications={
                premio?.miPremio?.commerce
                  ? imperdibles.concat(premio.miPremio.commerce)
                  : imperdibles
              }
            />
          )}
          <div className='my-4 container'>
            {imperdibles &&
              imperdibles.map((imperdible, i) => (
                <div key={i} className='my-3'>
                  <CardWay
                    openModal={() => burnQR(imperdible.id)}
                    coords={coords}
                    recess={imperdible}
                  />
                </div>
              ))}
            {premio?.miPremio && (
              <CardReward
                coords={coords}
                active={premio.active}
                reward={premio.miPremio}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className='my-4 container'>
            {imperdibles &&
              imperdibles.map((imperdible, i) => (
                <div key={i} className='my-3'>
                  <CardWay
                    openModal={() => burnQR(imperdible.id)}
                    coords={coords}
                    recess={imperdible}
                  />
                </div>
              ))}
            {premio?.miPremio && (
              <CardReward
                coords={coords}
                active={premio.active}
                reward={premio.miPremio}
              />
            )}
          </div>
          {imperdibles.length > 0 && (
            <Map
              ubications={
                premio?.miPremio?.commerce
                  ? imperdibles.concat(premio.miPremio.commerce)
                  : imperdibles
              }
            />
          )}
        </>
      )}
    </div>
  );
};
export default WaysRoute;
