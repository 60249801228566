import { distance, formatExternalAssetUrl } from "helpers";
import Reward from "typedef/Reward";
import classes from "./classes.module.scss";
import useModalReward from "./ModalReward";
import useUser from "hooks/useUser";

type cardRewardProps = {
  reward: Reward;
  active?: boolean;
  coords?: GeolocationCoordinates;
};

const CardReward: React.FC<cardRewardProps> = ({ reward, coords, active }) => {
  const [user] = useUser();
  const modalReward = useModalReward(reward);

  return (
    <div
      style={{ maxHeight: 170, opacity: active ? 1 : 0.5 }}
      className={`bg-white p-0 d-flex flex-row overflow-hidden rounded`}>
      <div
        className='d-flex flex-column justify-content-end'
        style={{
          minWidth: 150,
          minHeight: 150,
          background: `url(${formatExternalAssetUrl(
            reward?.commerce?.logo
          )}) center / cover no-repeat`
        }}
      />
      <div className='p-2 d-flex flex-column justify-content-between w-100'>
        <div>
          <div className='font-weight-bold text-uppercase'>{reward?.name}</div>
          <div>{reward?.commerce?.name} </div>
          <div>
            <small>{reward?.commerce?.address}</small>
          </div>
        </div>
        <div
          className={`d-flex justify-content-between align-items-end ${classes.bottonCard}`}>
          <p> A {Math.floor(distance(coords, reward?.commerce)) || "--"} Km</p>
          {user && (
            <button
              onClick={() => {
                if (active) {
                  modalReward(user.id);
                }
              }}
              className={`secondary secondary--magenta px-2 py-1`}>
              <small>Reclamar Premio</small>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
export default CardReward;
