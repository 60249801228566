import { QRCode } from "react-qr-svg";
import { LIGHT_BLUE } from "assets/colors";
import BAQR from "./BAQR";
import { HTMLProps } from "react";

export type QRPRops = {
  value: string;
} & HTMLProps<HTMLDivElement>;

const QR: React.FC<QRPRops> = ({ value, ...props }) => {
  return (
    <div style={{ width: 128, height: 128, position: "relative" }} {...props}>
      <BAQR className='w-100 h-100' />
      <div
        style={{ top: -6, left: -4 }}
        className='position-absolute d-flex w-100 h-100 align-items-center justify-content-center'>
        <QRCode
          bgColor='transparent'
          fgColor={LIGHT_BLUE}
          level='Q'
          value={value}
          style={{
            width: 67,
            height: 67
          }}
          color={LIGHT_BLUE}
        />
      </div>
    </div>
  );
};

export default QR;
