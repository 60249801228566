const seleccionarTodo = (e: React.FocusEvent<HTMLInputElement>) => {
  if (e.currentTarget.value.length) {
    const typeDef = e.target.type;
    e.currentTarget.type = "text";
    e.currentTarget.setSelectionRange(0, e.target.value.length);
    e.currentTarget.type = typeDef;
  }
};

export default seleccionarTodo;
