export default {
  titleCompleteForm: "¿Querés sumarte a ReCreo?",
  subTite:
    "Si tenés un establecimiento turístico, cultural o comercial y querés difundir tus actividades a través de nuestro programa, completá nuestro formulario.",
  addered: "Quiero ser parte",
  appDownload: "Descargá la app",
  appDownloadDescribe:
    "ReCreo está disponible para dispositivos móviles iOS y Android.",
  moreInfo: "Más información",
  termsAndConditions: "Términos y Condiciones",
  policity: "Políticas de Privacidad",
  cuentaDNI: "Cuenta DNI",
  turismCatalo: "Catálogo Turístico y Cultural"
};
