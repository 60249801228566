import * as React from "react";

function SvgGem(props) {
  return (
    <svg width={32} height={26} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M1.154 9.377l3.24-7.019C4.855 1.453 5.626 1 6.706 1h17.815c1.542 0 2.545.453 3.008 1.358.462.906 1.465 3.02 3.007 6.34.617 1.208.617 2.113 0 2.717-.617.604-5.013 4.83-13.187 12.68-.926.754-1.851.754-2.777 0-.925-.755-5.244-4.982-12.956-12.68C1 10.66.846 9.981 1.154 9.377zm0 1.132H31M5.55 1.226l3.008 9.283m0 0l7.172-9.283m.231 0l7.173 9.283m.231 0l3.24-8.83M8.325 10.283l7.404 14.49m.463 0l7.172-14.264'
        strokeWidth={2}
        fill='none'
      />
    </svg>
  );
}

export default SvgGem;
