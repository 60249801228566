import useLangContext from "hooks/useLangContext";
import { useHistory } from "react-router";
import BackArrow from "./../../assets/images/back-arrow.svg";
import classes from "./classes.module.scss";

type BTNbakcProps = {
  rotateColor?: boolean;
};

const BTNBack: React.FC<BTNbakcProps> = ({ rotateColor }) => {
  const history = useHistory();
  const { lang } = useLangContext();
  return (
    <button
      className={`${rotateColor ? classes.BTNBackRotate : classes.BTNBack}`}
      onClick={() => history.goBack()}>
      <img src={BackArrow} />
      {lang.Elements.btnReturn}
    </button>
  );
};
export default BTNBack;
