import classes from "./classes.module.scss";
import miAgendaImage from "assets/images/mi_agenda.svg";

type AgendarProps = {
  onUpdateShedule?: () => void;
  isScheduleActive?: boolean;
  loadingShedule?: boolean;
};

const Agendar: React.FC<AgendarProps> = ({
  onUpdateShedule,
  isScheduleActive,
  loadingShedule
}) => {
  return (
    <>
      {isScheduleActive !== undefined && (
        <div className={`${classes.agenda}`}>
          <button
            data-testid='btnAgendar'
            onClick={onUpdateShedule}
            className='p-0 px-1'>
            <img
              data-testid='imgAgendar'
              width={20}
              height={20}
              src={miAgendaImage}
              style={{ opacity: isScheduleActive ? 1.0 : 0.5 }}
            />
          </button>
        </div>
      )}
    </>
  );
};
export default Agendar;
