import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import useSearchQuery from "hooks/useSearchQuery";
import { useMemo, useRef, useState } from "react";
import Recess from "typedef/Recess";
import useSearch from "./useSearch";

const useCircuits = () => {
  const [circuits, setCircuits] = useState<Recess[]>([]);
  const page = useRef(1);
  const { lang } = useLangContext();
  const searchQuery = useSearchQuery();
  const search = searchQuery.get("busqueda") || "";
  const [searchedCircuits] = useSearch(search);
  const [limitReached, setLimitReached] = useState(false);

  const filteredCircuits = useMemo<Recess[]>(() => {
    if (search) return searchedCircuits;
    return circuits;
  }, [circuits, searchedCircuits, search]);

  const { loading, refetch, fetchMore } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ recess_multiple_circuit_list: { data: circuits } }) =>
      setCircuits(circuits),
    onError: error =>
      console.error(`Tuvimos un problema cargando los circuitos: ${error}`),
    variables: {
      page: 1,
      lang: lang.current
    }
  });

  function _fetchMore() {
    if (limitReached) return;
    fetchMore({
      variables: {
        page: ++page.current,
        lang: lang.current
      }
    })
      .then(
        ({
          data: {
            recess_multiple_circuit_list: { data: circuits, last_page }
          }
        }) => {
          setCircuits(prev => [...prev, ...circuits]);
          if (parseInt(last_page) <= page.current - 1) {
            setLimitReached(true);
          }
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema al cargar más municipios", error)
      );
  }

  function _refetch() {
    refetch({
      page: 1,
      lang: lang.current
    })
      .then(
        ({
          data: {
            recess_multiple_circuit_list: { data: circuits }
          }
        }) => {
          page.current = 1;
          setCircuits(circuits);
          setLimitReached(false);
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema recargando los municipios", error)
      );
  }

  return useMemo(
    () => ({
      list: filteredCircuits,
      loading,
      refetch: _refetch,
      fetchMore: _fetchMore,
      limitReached
    }),
    [loading, circuits, limitReached, filteredCircuits]
  );
};

const query = gql`
  query getCircuits($page: Int!, $lang: String) {
    recess_multiple_circuit_list(page: $page, limit: 20, lang: $lang) {
      data {
        id
        title
        is_favorite
        date_from
        date_to
        circuit {
          id
        }
        icon {
          uri
        }
      }
    }
  }
`;

export default useCircuits;
