import { useCallback } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { useHistory } from "react-router";
import useRegisterContext from "hooks/useRegisterContext";
import validatePasswordIsNew from "./validatePasswordIsNew";

const useGoogleRegister = () => {
  const history = useHistory();
  const context = useRegisterContext();
  const register = useCallback(async () => {
    try {
      const response = await firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider());
      firebase.auth().signOut();
      const email = response.user?.email;
      context.dispatch({
        type: "social_token",
        payload: (response.credential as any).accessToken
      });
      context.dispatch({
        type: "first_name",
        payload: response.user?.displayName as string
      });
      context.dispatch({
        type: "email",
        payload: response.user?.email as string
      });
      context.dispatch({
        type: "provider",
        payload: "google"
      });
      if (email && (await validatePasswordIsNew(email))) {
        history.push("registro-perfil");
      } else if (!Boolean(email)) {
        history.push("registro-credenciales");
      } else {
        alert("Parece que ya existe un cuenta con ese correo.");
      }
    } catch (error) {
      if (error.code !== "auth/popup-closed-by-user") {
        alert(
          `Parece que tuvimos un problema. Parece que tuvimos un problema desconocido. ${JSON.stringify(
            error
          )}`
        );
        console.error(
          `Parece que tuvimos un problema al intentar ingresar con Google: ${JSON.stringify(
            error
          )}`
        );
      }
    }
  }, [history]);

  return register;
};

export default useGoogleRegister;
