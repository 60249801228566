import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";

const useRecreoditsPoints = () => {
  const [points, setPoints] = useState();
  const [dateEnd, setDateEnd] = useState();
  const { loading } = useQuery(query, {
    onCompleted: ({
      recredit_amount: { amount: amount, date_end: date_end }
    }) => {
      setPoints(amount);
      setDateEnd(date_end);
    },
    onError: error => {
      console.error(
        "Parece que tuvimos un problema al traer los recreditos",
        error
      );
    }
  });

  return useMemo(
    () => ({
      points,
      dateEnd,
      loadingPoints: loading
    }),
    [points, loading]
  );
};

const query = gql`
  query {
    recredit_amount {
      amount
      date_end
    }
  }
`;

export default useRecreoditsPoints;
