import { formatExternalAssetUrl } from "helpers";
import { useEffect, useState } from "react";

export type FirebaseLinkOptions = {
  link: string;
  title?: string;
  image?: string;
  description?: string;
};

export type DynamicLink = {
  shortLink: string;
  previewLink: string;
  error?: string;
};

const useFirebaseLink = ({
  link,
  title,
  image,
  description
}: FirebaseLinkOptions) => {
  const [dynamicLink, setDynamicLink] = useState<DynamicLink>();
  useEffect(() => {
    fetch(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyCDLMPkz8fip03TfKEqEsEc8PL32O9KmGM`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          dynamicLinkInfo: {
            link,
            domainUriPrefix: "https://recreo.page.link",
            socialMetaTagInfo: {
              socialTitle: title || "ReCreo en Buenos Aires",
              socialDescription:
                description ||
                "Me gustaría invitarte a ver ReCreo y disfrutar de la provincia de Buenos Aires.",
              socialImageLink: formatExternalAssetUrl(image)
            },
            iosInfo: {
              iosBundleId: "org.gba.recreo"
            },
            androidInfo: {
              androidPackageName: "org.gba.recreo"
            }
          }
        })
      }
    )
      .then(res => res.json())
      .then((data: DynamicLink) => setDynamicLink(data))
      .catch(error => console.error(error));
  }, [link, title, description, image]);

  return dynamicLink;
};

export default useFirebaseLink;
