import dayjs from "dayjs";
import useLangContext from "hooks/useLangContext";
import useSearchQuery from "hooks/useSearchQuery";
import { Dispatch, SetStateAction, useState } from "react";

type CategoriesFilterProps = {
  openModal: (e: any) => void;
  setSelectedDay: (date: any) => void;
};

const CategoriesFilter: React.FC<CategoriesFilterProps> = ({
  openModal,
  setSelectedDay
}) => {
  const [selected, setSelected] = useState("");
  const { lang } = useLangContext();

  return (
    <div className='bg-white'>
      <h5 className='pr-4 pl-4 pt-3'>
        {" "}
        <small>{lang.Agendas.search}</small>
      </h5>
      <div className='d-flex flex-row p-3 rounded scrollable'>
        <button
          onClick={() => {
            setSelectedDay(dayjs(new Date()));
            setSelected("hoy");
          }}
          name='filtro'
          value={"hoy"}
          className={`secondary secondary--purple purpleHover mr-2 ${
            selected === "hoy" && "selected"
          } `}>
          <p className='text-nowrap' style={{ fontSize: "16px" }}>
            {lang.Agendas.today}
          </p>
        </button>
        <button
          onClick={() => {
            setSelectedDay(dayjs(new Date()).add(1, "day"));
            setSelected("mañana");
          }}
          name='filtro'
          value={"mañana"}
          className={`secondary secondary--purple purpleHover mr-2 ${
            selected === "mañana" && "selected"
          } `}>
          <p className='text-nowrap' style={{ fontSize: "16px" }}>
            {lang.Agendas.tomorrow}
          </p>
        </button>
        <button
          onClick={() => {
            setSelectedDay(dayjs(new Date()).add(1, "week"));
            setSelected("semana");
          }}
          name='filtro'
          value={"semana"}
          className={`secondary secondary--purple purpleHover mr-2 ${
            selected === "semana" && "selected"
          } `}>
          <p className='text-nowrap' style={{ fontSize: "16px" }}>
            {lang.Agendas.thisWeek}
          </p>
        </button>
        <button
          onClick={() => {
            setSelectedDay(dayjs(new Date()).add(2, "week"));
            setSelected("proxima-semana");
          }}
          name='filtro'
          value={"proxima-semana"}
          className={`secondary secondary--purple purpleHover mr-2 ${
            selected === "proxima-semana" && "selected"
          } `}>
          <p className='text-nowrap' style={{ fontSize: "16px" }}>
            {lang.Agendas.nextWeek}
          </p>
        </button>
        <button
          onClick={() => {
            setSelectedDay(dayjs(new Date()).add(1, "month"));
            setSelected("mes");
          }}
          name='filtro'
          value={"mes"}
          className={`secondary secondary--purple purpleHover mr-2 ${
            selected === "mes" && "selected"
          } `}>
          <p className='text-nowrap' style={{ fontSize: "16px" }}>
            {lang.Agendas.thisMonth}
          </p>
        </button>
        <button
          onClick={() => {
            setSelectedDay(dayjs(new Date()).add(2, "month"));
            setSelected("proximo-mes");
          }}
          name='filtro'
          value={"proximo-mes"}
          className={`secondary secondary--purple purpleHover mr-2 ${
            selected === "proximo-mes" && "selected"
          } `}>
          <p className='text-nowrap' style={{ fontSize: "16px" }}>
            {lang.Agendas.nextMonth}
          </p>
        </button>
        <div className='pr-4'>
          <button
            onClick={e => openModal(e)}
            className={`secondary--purple-border mr-2 w-100 h-100`}>
            <p className='text-nowrap' style={{ fontSize: "16px" }}>
              {lang.Agendas.selectDate}
            </p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoriesFilter;
