export default {
  titleCompleteForm: "Quer fazer parte da ReCreo?",
  subTite:
    "Se você tem um estabelecimento turistico, cultural ou comercial e quer divulgar suas atividades através do nosso programa, preencha nosso formulário.",
  addered: "Quero fazer parte",
  appDownload: "Faça o download do app",
  appDownloadDescribe:
    "ReCreo está disponível para dispositivos móveis iOS e Android.",
  moreInfo: "Mais informações",
  termsAndConditions: "Termos e Condições",
  policity: "Políticas de Provacidade",
  cuentaDNI: "Cuenta DNI",
  turismCatalo: "Cátalogo turistico e cultural"
};
