import * as React from "react";

function SvgInactivePoint(props) {
  return (
    <svg width={20} height={20} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd' opacity={0.275}>
        <path fill='#E70184' d='M0 0h19.2v19.2H0z' />
        <circle fill='#17BACE' cx={9.6} cy={9.6} r={6} />
      </g>
    </svg>
  );
}

export default SvgInactivePoint;
