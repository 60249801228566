export default {
  title: "Destinos Cuidados",
  parrapha1: "Usá siempre tapabocas.",
  parrapha2: "Mantené el distanciamiento social (mínimo 2 metro).",
  parrapha3: "Evitá lugares muy concurridos.",
  parrapha4:
    "Leé y prestá atención a las recomendaciones de los prestadores turísticos para prevenir el contagio.",
  parrapha5: "Privilegiá las actividades al aire libre.",
  parrapha6:
    "Si tenés algunos de estos síntomas, tomá contacto con las autoridades de salud locales: más de 37° de temperatura. Pérdida del olfato o gusto, dolor de garganta o falta de aire.",
  parrapha7:
    "Antes de viajar, consultá las fuentes de información locales para seguir sus recomendaciones.",
  parrapha8:
    "Recordá llevar con vos alcohol en gel, sanitizante de manos y/o tollas o pañuelos descartables.",
  vaccLink: "Consultá postas de vacunación y centros de testeo"
};
