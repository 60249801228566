import * as React from "react";

function SvgCompass(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path d='M16.156.284C7.516.284.512 7.288.512 15.928s7.004 15.644 15.644 15.644S31.8 24.568 31.8 15.928 24.796.284 16.156.284zm0 29.156c-7.462 0-13.511-6.049-13.511-13.511S8.694 2.418 16.156 2.418c7.462 0 13.511 6.049 13.511 13.511S23.618 29.44 16.156 29.44z' />
      <path d='M22.087 8.107l-8.796 4.871h-.05l-.092.064-.1.085-.071.085-.078.107v.05l-4.843 8.96a1.067 1.067 0 001.422 1.422l8.796-4.871h.05l.092-.064.1-.085.071-.085.078-.107v-.05l4.8-8.981a1.067 1.067 0 00-1.422-1.422l.043.021zm-8.028 7.594l2.005 2.062-4.416 2.446 2.411-4.508zm3.491.534l-2.005-2.062 4.416-2.446-2.411 4.508zM26.823 15.218H24.69a.71.71 0 100 1.422h2.133a.71.71 0 100-1.422zM8.334 15.929a.71.71 0 00-.711-.711H5.49a.71.71 0 100 1.422h2.133a.71.71 0 00.711-.711zM16.156 23.751a.71.71 0 00-.711.711v2.133a.71.71 0 101.422 0v-2.133a.71.71 0 00-.711-.711zM16.156 8.107a.71.71 0 00.711-.711V5.263a.71.71 0 10-1.422 0v2.133a.71.71 0 00.711.711z' />
    </svg>
  );
}

export default SvgCompass;
