import * as React from "react";

function SvgArrowLeftCircle(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path d='M15.805.278C7.353.278.501 7.13.501 15.582s6.852 15.304 15.304 15.304 15.304-6.852 15.304-15.304S24.257.278 15.805.278zm0 28.522c-7.3 0-13.217-5.918-13.217-13.217S8.506 2.366 15.805 2.366c7.3 0 13.217 5.918 13.217 13.217A13.215 13.215 0 0115.805 28.8z' />
      <path d='M14.727 15.513l4.793-4.793a1.045 1.045 0 00-.453-1.775 1.045 1.045 0 00-1.022.3l-6.261 6.261 6.282 6.282a1.045 1.045 0 001.775-.453c.094-.37-.02-.762-.3-1.022l-4.814-4.8z' />
    </svg>
  );
}

export default SvgArrowLeftCircle;
