import { VIOLET, MAGENTA, LIGHT_BLUE, CR_BLACK_COLOR } from "assets/colors";
import Icon from "assets/icons";
import useUserPoints from "./useUserPoints";
import useScrollToTop from "hooks/useScrollToTop";
import "./style.scss";
import useLangContext from "hooks/useLangContext";

const UserProfile = () => {
  const points = useUserPoints();
  useScrollToTop();
  const { lang } = useLangContext();

  return (
    <div className='container userProfile'>
      <div className='row justify-content-center'>
        <div className='col col-12 mt-4'>
          <div className='row mb-5 col-lg-7 mx-auto d-flex justify-content-center'>
            <div className='col col-12 col-md-auto mb-sm-4 mb-4 mb-xs-4 mb-md-0 d-flex flex-column align-items-center'>
              <Icon custom='Group' size={160} color={VIOLET} />
            </div>
            <div className='col col-12 col-md-auto d-flex flex-column justify-content-center '>
              <h6 className='text-center text-md-left text-muted'>
                {lang.Profile.title}
              </h6>
              <h4
                className='text-center font-weight-bold text-md-left my-2'
                style={{
                  ...(points >= 0 && { color: MAGENTA }),
                  ...(points >= 1000 && { color: VIOLET }),
                  ...(points >= 2000 && { color: LIGHT_BLUE }),
                  ...(points >= 3000 && { color: CR_BLACK_COLOR })
                }}>
                {lang.Profile.level} {` `}
                {points < 1000 && lang.Profile.virtual}
                {points >= 1000 && points < 2000 && lang.Profile.curious}
                {points >= 2000 && points < 3000 && lang.Profile.explorer}
                {points >= 3000 && points < 4000 && lang.Profile.expert}
              </h4>
              <h6 className='text-muted text-center text-md-left'>
                {points} {lang.Navbar.header.login.points}
              </h6>
            </div>
          </div>
          <div className='bg-white rounded  py-5 px-4  mt-3 col-lg-7 mx-auto'>
            <div className='row'>
              <div className='col'>
                <h6 className='text-danger font-weight-bold '>
                  {" "}
                  {lang.Profile.level} {lang.Profile.virtual}
                </h6>
              </div>
              <div className='col d-flex flex-column justify-content-end'>
                <h6 className='text-right points '>
                  0 a 1.000 {lang.Navbar.header.login.points}{" "}
                  <Icon size={16} custom='Lock' className='mb-1' />
                </h6>
              </div>
            </div>
            <p className='mt-3 description col-lg-11 p-0'>
              {lang.Profile.descriptVirtual}
            </p>
          </div>
          <div className='bg-white rounded py-5 px-4 mt-3 col-lg-7 mx-auto'>
            <div className='row'>
              <div className='col'>
                <h6 className='text-secondary font-weight-bold'>
                  {lang.Profile.level} {lang.Profile.curious}
                </h6>
              </div>
              <div className='col d-flex flex-column justify-content-end'>
                <h6 className='text-right points '>
                  1.001 a 2.000 {lang.Navbar.header.login.points}{" "}
                  <Icon size={16} custom='Lock' className='mb-1' />
                </h6>
              </div>
            </div>
            <p className='mt-3 description col-lg-11 p-0'>
              {lang.Profile.descriptCurius}
            </p>
          </div>
          <div className='bg-white rounded  py-5 px-4 mt-3 col-lg-7 mx-auto'>
            <div className='row'>
              <div className='col'>
                <h6 className='text-primary font-weight-bold'>
                  {lang.Profile.level} {lang.Profile.explorer}
                </h6>
              </div>
              <div className='col d-flex flex-column justify-content-end'>
                <h6 className='text-right points'>
                  2.001 a 3.000 {lang.Navbar.header.login.points}{" "}
                  <Icon size={16} custom='Lock' className='mb-1' />
                </h6>
              </div>
            </div>
            <p className='mt-3 description col-lg-11 p-0'>
              {lang.Profile.descriptExplorer}
            </p>
          </div>
          <div className='bg-white rounded  py-5 px-4 my-3 mb-md-5 col-lg-7 mx-auto'>
            <div className='row'>
              <div className='col'>
                <h6 className='text black font-weight-bold'>
                  {" "}
                  {lang.Profile.level} {lang.Profile.expert}
                </h6>
              </div>
              <div className='col d-flex flex-column justify-content-end'>
                <h6 className='text-right points'>
                  3.001 o más {lang.Navbar.header.login.points}{" "}
                  <Icon size={16} custom='Lock' className='mb-1' />
                </h6>
              </div>
            </div>
            <p className='mt-3 description col-lg-11 p-0'>
              {lang.Profile.desciptExpert}
            </p>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default UserProfile;
