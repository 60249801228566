import "dayjs/plugin/calendar";
import RecessCard from "components/RecessCard";
import Recess from "typedef/Recess";
import ErrorMSJ from "components/ErrorMSJ";
import Loader from "components/Loader";
import useLangContext from "hooks/useLangContext";

type agendaListProps = {
  day?: {
    list: Recess[];
    loading: boolean;
    fetchMore: () => void;
    limitReached: boolean;
  };
};

const AgendaList: React.FC<agendaListProps> = ({ day }) => {
  const { lang } = useLangContext();
  return (
    <div className='my-5'>
      {day?.loading ? (
        <div className='d-flex m-auto text-center'>
          <Loader />
        </div>
      ) : day?.list && day?.list.length > 0 ? (
        <div className='row'>
          {day?.list.map((recess, i) => (
            <RecessCard recess={recess} key={i} />
          ))}
        </div>
      ) : (
        <div className='p-3 mb-5 w-100 d-flex justify-content-center'>
          <ErrorMSJ title={"No se encuentran agendas disponibles"} />
        </div>
      )}
      <div>
        {!day?.loading && !day?.limitReached && day?.fetchMore ? (
          <button
            className='secondary secondary--magenta d-block my-5 mx-auto'
            onClick={() => day?.fetchMore()}>
            {lang.Agendas.loadMore}
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default AgendaList;
