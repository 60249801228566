import * as React from "react";

function SvgLike(props) {
  return (
    <svg
      viewBox='0 0 18 18'
      width={18}
      height={18}
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g>
        <path d='M9.088.16a8.8 8.8 0 100 17.6 8.8 8.8 0 000-17.6zm0 16.4a7.6 7.6 0 110-15.2 7.6 7.6 0 010 15.2z' />
        <path d='M10.568 6.26a2.9 2.9 0 00-1.48.62 2.904 2.904 0 00-1.484-.62A2.228 2.228 0 005.68 7.296a2.096 2.096 0 00-.092 2.056c.316.624 2.284 2.284 3.12 2.972a.6.6 0 00.764 0c.832-.684 2.8-2.348 3.116-2.976a2.104 2.104 0 00-.1-2.06 2.196 2.196 0 00-1.92-1.028zm.944 2.552c-.16.284-1.316 1.336-2.424 2.268A19.864 19.864 0 016.66 8.812a.904.904 0 01.044-.884c.197-.305.541-.483.904-.468.398.124.763.336 1.068.62a.6.6 0 00.832 0 2.772 2.772 0 011.064-.62 1 1 0 01.9.464.912.912 0 01.044.888h-.004z' />
      </g>
    </svg>
  );
}

export default SvgLike;
