import useLangContext from "hooks/useLangContext";
import React from "react";
import { useLocation } from "react-router-dom";

type WithSocialType = {
  withSocial: boolean;
};

const RegisterSuccess: React.FC = () => {
  const { lang } = useLangContext();

  const { state } = useLocation<WithSocialType>();

  return (
    <div className='container'>
      <div className='row justify-content-center'>
        <div className='col col-12 col-md-8 col-lg-6 d-flex flex-column align-items-center'>
          <h1 className='text-danger text-center my-5'>
            {lang.RegisterProfile.finishRegister}
          </h1>
          {state?.withSocial ? (
            <p className='mt-5'>{lang.RegisterProfile.loginWithSocial}</p>
          ) : (
            <p className='mb-5'>{lang.RegisterProfile.logWith}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
