import { useState } from "react";
import { useQuery, gql } from "@apollo/client";
import Recess from "typedef/Recess";
import useLangContext from "hooks/useLangContext";

const useSearch = (search?: string): [Recess[], boolean] => {
  const [circuit, setCircuit] = useState<Recess[]>([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "network-only",
    variables: {
      search: search,
      lang: lang.current
    },
    onCompleted: ({ search }) => {
      setCircuit(search.city_halls);
    },
    onError: error =>
      console.error(
        `Problema buscando Circuitos en la pantalla de Circuitos`,
        error
      )
  });

  return [circuit, loading];
};

const query = gql`
  query searchCircuits($search: String, $lang: String) {
    search(search: $search) {
      recess_multiple_circuit_list(lang: $lang) {
        id
        title
        is_favorite
        date_from
        date_to
        circuit {
          id
        }
        icon {
          uri
        }
      }
    }
  }
`;

export default useSearch;
