import * as React from "react";

function SvgXVioletBlue(props) {
  return (
    <svg width={21} height={21} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h21v21H0z' fill='#24B5C7' />
        <path fill='#8B4390' d='M0 2l19 19h2v-2L2 0H0z' />
        <path fill='#8B4390' d='M0 19v2h2.5L21 2.5V0h-2.5z' />
      </g>
    </svg>
  );
}

export default SvgXVioletBlue;
