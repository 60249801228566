import React, { useCallback } from "react";
import classes from "./index.module.scss";
type Props = {
  title: string;
  index: number;
  active: boolean;
  setSelectedTab: (index: number) => void;
};

const TabTitle: React.FC<Props> = ({
  title,
  setSelectedTab,
  index,
  active
}) => {
  const onClick = useCallback(() => {
    setSelectedTab(index);
  }, [setSelectedTab, index]);

  return (
    <li onClick={onClick} className={active ? classes.active : ""}>
      <h6>{title}</h6>
    </li>
  );
};

export default TabTitle;
