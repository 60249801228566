import { useState, useRef, useEffect } from "react";
import ConvoCardIMG from "assets/images/ReCreoDefaultImage.png";
import { Link } from "react-router-dom";
import useSearchQuery from "hooks/useSearchQuery";
import useConvocatoriaSimple from "./useConvocatoriaSimple";
import useScrollToTop from "hooks/useScrollToTop";
import useDateConvo from "hooks/useDateConvo";
import { formatExternalAssetUrl } from "helpers";
import useParticipar from "./useParticipar";
import useForm from "../../UserEditProfile/useForm";
import useUserHasProposal from "./useUserHasProposal";
import useActualizarPropuesta from "./useActualizarPropuesta";
import useGetDocumentType from "./useGetDocumentType";
import getEnvVariable from "env";
import { LIGHT_BLUE } from "assets/colors";
import Icon from "assets/icons";
import Loader from "components/Loader";
import FormParticipar from "./formComplex";
import Details from "./Tabs";
import getYoutubeLinkId from "helpers/getYoutubeLinkId";
import ShareButton from "hooks/useSocialShare/ShareButton";
import { Convocatoria } from "typedef/Convocatoria";
export const ACCESS_TOKEN_KEY = "Asdflj1n2e2Q@!#rt4ew45";

const ConvoDetail = () => {
  const form = useForm();
  const url = getEnvVariable("SERVER_URL");
  const [title, setTitle] = useState<string | undefined>("");
  const [about, setAbout] = useState<string | undefined>("");
  const [document, setDocument] = useState<File | undefined | object>(
    undefined
  );
  const [inputs, setInputs] = useState<object | undefined>({});
  const [preview, setPreview] = useState<string | undefined>("");
  const [step, setStep] = useState<number>(1);
  const handleChangeStep = () => {
    setStep(2);
  };
  const [isHover, setIsHover] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [mostrarImagen, setMostrarImagen] = useState<boolean>(false);
  const isLoggued = localStorage.getItem(ACCESS_TOKEN_KEY);
  const myRef = useRef<HTMLDivElement>(null);
  const maxLetters = 600;
  const param = useParam<{ id: string }>();
  const convo = useConvocatoriaSimple(param.id);
  const formatoDescarga = useGetDocumentType(convo?.items);
  const date = useDateConvo(
    convo?.state,
    convo?.close_date,
    convo?.close_date_vote,
    convo?.open_date_vote
  );
  const convoId = param.id;
  const propuesta = useUserHasProposal(convoId);
  const actualizarPropuesta = useActualizarPropuesta(
    propuesta.id,
    title,
    about,
    document,
    mostrarImagen,
    handleChangeStep
  );

  useEffect(() => {
    if (propuesta) {
      setTitle(propuesta?.description);
      setAbout(propuesta?.about);
      if (propuesta.multimedia) {
        setPreview(url + propuesta.multimedia[0]?.path);
        setDocument(propuesta.multimedia[0]);
      }
    }
  }, [propuesta]);

  useEffect(() => {
    if (convo?.items) {
      setInputs(convo?.items);
    }
  }, [convo]);
  const participar = useParticipar(
    convoId,
    convo?.items?.[0]?.id,
    about,
    title,
    document,
    handleChangeStep
  );

  const handleDocument = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      setDocument(e.target.files[0]);
      setPreview(URL.createObjectURL(e.target.files[0]));
      setMostrarImagen(true);
    } else {
      setDocument({ type: "link", path: e.target.value });
      setPreview(e.target.value);
    }
  };

  const invocarHook = (e: any) => {
    e.preventDefault();
    if (isLoggued) {
      setLoading(true);
      if (propuesta?.hasProposal) {
        actualizarPropuesta().then(() => {
          setLoading(false);
        });
        window.document
          .getElementById("headerConvo")
          ?.scrollIntoView({ block: "center", behavior: "smooth" });
      } else {
        participar().then(() => {
          setLoading(false);
        });
      }
    } else {
      alert(
        "Registrate o inicia sesión para poder participar de una Convocatoria."
      );
    }
  };

  const executeScroll = () => {
    if (isLoggued) {
      myRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      alert(
        "Registrate o inicia sesión para poder participar de una Convocatoria."
      );
    }
  };
  useScrollToTop();

  const step1 = (
    <div
      className='mx-auto w-75 mt-5 mb-5 p-5 rounded'
      style={{ background: "#fff" }}>
      <h5 id='headerConvo' className='font-weight-bold'>
        Participa de {convo.title}
      </h5>
      <h6 className='mt-5 mb-5' style={{ color: "#666666" }}>
        {convo.description}
      </h6>
      {/* ACA VAN LOS INPUTS */}
      <>
        {convo.annoucement_type === "complex" ? (
          <FormParticipar
            inputs={inputs}
            propuesta={propuesta}
            convoId={convoId}
            handleChangeStep={() => {
              handleChangeStep();
            }}
          />
        ) : (
          <>
            <form onSubmit={invocarHook}>
              <div className='container-fluid'>
                <div className='mt-3'>
                  <input
                    required
                    type='text'
                    name='title'
                    placeholder='Título'
                    value={title}
                    onChange={({ currentTarget }) =>
                      setTitle(currentTarget.value)
                    }
                  />
                </div>
                <div className='mt-3'>
                  <input
                    required
                    type='text'
                    name='about'
                    placeholder='Acerca de mi / Nosotros'
                    value={about}
                    onChange={({ currentTarget }) =>
                      setAbout(currentTarget.value)
                    }
                  />
                </div>
              </div>
              <br />
              <p className='mt-3 font-weight-bold'>Subí un archivo</p>
              <p className='mt-1' style={{ color: "#8C8C8C" }}>
                Puede ser un archivo de formato{" "}
                <strong>{formatoDescarga}</strong>
              </p>
              <div className='uploadDiv mt-3 d-flex flex-column align-items-center'>
                <p className='m-3'>
                  {document ? (
                    <p style={{ color: LIGHT_BLUE }}>
                      <strong>{document?.name}</strong>
                    </p>
                  ) : (
                    "Podés subir tu archivo completando el siguiente campo"
                  )}
                </p>
                {preview && document?.type?.includes("image") ? (
                  <img
                    className='rounded'
                    style={{ maxWidth: "100%", maxHeight: "600px" }}
                    src={preview}
                  />
                ) : preview && document?.type?.includes("application") ? (
                  <>
                    <p className='mb-3' style={{ color: LIGHT_BLUE }}>
                      <strong>Descargar Documento</strong>
                    </p>
                    <button
                      type='button'
                      onMouseEnter={() => setIsHover(true)}
                      onMouseLeave={() => setIsHover(false)}
                      style={{ all: "unset" }}>
                      <a href={preview} target='_blank'>
                        <Icon
                          custom='Document'
                          style={{ alignSelf: "center", cursor: "pointer" }}
                          size={150}
                          stroke={LIGHT_BLUE}
                          fill={isHover ? "#fff" : LIGHT_BLUE}
                          colorLine={isHover ? LIGHT_BLUE : "#fff"}
                        />
                      </a>
                    </button>
                  </>
                ) : preview && document?.type?.includes("video") ? (
                  <video
                    style={{ maxWidth: "100%", maxHeight: "600px" }}
                    controls>
                    <source src={preview} type='video/mp4' />
                  </video>
                ) : preview && document?.type?.includes("audio") ? (
                  <audio
                    src={preview}
                    style={{ maxWidth: "100%" }}
                    preload='metadata'
                    controls></audio>
                ) : preview && document?.type?.includes("link") ? (
                  <iframe
                    width='100%'
                    height='480px'
                    src={`https://www.youtube.com/embed/${getYoutubeLinkId(
                      preview
                    )}?enablejsapi=1&playsinline=1&controls=1&enablejsapi=1&showinfo=0`}
                    title='YouTube video player'
                    frameBorder='0'
                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                    allowFullScreen
                  />
                ) : null}

                {convo?.items?.map(index => {
                  if (index.type?.includes("link")) {
                    return (
                      <input
                        required
                        type='text'
                        onChange={handleDocument}
                        placeholder={index.title}
                        name='Link'
                      />
                    );
                  } else {
                    return (
                      <div className='d-flex'>
                        <div className='fakeButton mb-3 mt-3'>
                          Subir archivo
                          <input
                            type='file'
                            required
                            onClick={() => {
                              setPreview(null);
                            }}
                            onChange={handleDocument}
                            accept={formatoDescarga}
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              opacity: 0
                            }}></input>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
              <div className='d-flex flex-column align-items-center'>
                <p className='terminos mt-5'>
                  Al continuar estás aceptando nuestros{" "}
                  <div
                    className='clickable'
                    onClick={() =>
                      window.document
                        ?.getElementById("TermsAndConditions")
                        ?.scrollIntoView({
                          block: "center",
                          behavior: "smooth"
                        })
                    }>
                    <strong className='recreoBlue '>
                      Términos y Condiciones
                    </strong>
                  </div>
                  <br />
                  Por favor, revisá tus datos personales en la siguiente
                  pantalla.
                </p>
                <button
                  type='submit'
                  className='secondary secondary--magenta mt-3'>
                  {loading ? (
                    <span
                      className='spinner-border spinner-border-sm mr-2'
                      role='status'
                      aria-hidden='true'
                    />
                  ) : null}
                  {"Continuar"}
                </button>
              </div>
            </form>
          </>
        )}
      </>
    </div>
  );
  const step2 = (
    <div
      className='mx-auto container mt-5 mb-5 p-5 rounded'
      style={{ background: "#fff" }}>
      <h5 className='font-weight-bold'>¡Ya casi!</h5>
      <h6 className='mt-5 mb-5' style={{ color: "#666666" }}>
        Por favor, revisá tus datos personales para confirmar el envío de tu
        proyecto. Recordá que también utilizamos el correo electrónico de tu
        usuario para comunicarnos.{" "}
      </h6>
      <form className='bg-50' onSubmit={form.submit}>
        <div className='email'>
          <input
            required
            type='text'
            name='name'
            className='email'
            defaultValue={form.user.first_name}
            onBlur={form.set("first_name")}
            placeholder='Nombre'
          />
          <input
            required
            type='text'
            name='lastName'
            className='email'
            defaultValue={form.user.last_name}
            onBlur={form.set("last_name")}
            placeholder='Apellido'
          />
          <input
            required
            type='text'
            name='phone'
            className='email'
            defaultValue={form.user.phone}
            onBlur={form.set("phone")}
            placeholder='Teléfono'
          />
          <input
            required
            type='text'
            name='dni'
            className='email'
            defaultValue={form.user.document_number}
            onBlur={form.set("document_number")}
            placeholder='DNI'
          />
        </div>
        <button className='secondary secondary--magenta mt-3'>Confirmar</button>
      </form>
    </div>
  );
  return (
    <>
      {convo.loading ? (
        <div className='d-flex justify-content-center m-5'>
          <Loader />
        </div>
      ) : (
        <>
          <div className='card mb-4'>
            <div className='row'>
              <div className='col-md-6'>
                <img
                  src={
                    convo.image
                      ? formatExternalAssetUrl(convo.image)
                      : ConvoCardIMG
                  }
                  width='100%'
                  height='500'
                  className=''
                  alt='...'
                />
              </div>

              <div className='col-md-6'>
                <div className='card-body pr-5'>
                  <h5 className='card-title text-secondary font-weight-bold mt-4 mb-4'>
                    {convo.title}
                  </h5>
                  <h4 className='card-text'>
                    {convo.description?.slice(0, maxLetters)}
                  </h4>
                  <br />
                  <p className='card-text text-muted titleOwn mt-3'>{date}</p>
                  {convo.state === "opened" ? (
                    <>
                      <button
                        className='secondary secondary--magenta d-none d-sm-block mt-3'
                        onClick={executeScroll}>
                        {propuesta?.hasProposal
                          ? "Editar Propuesta"
                          : "Participar"}
                      </button>
                    </>
                  ) : null}
                  <div
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "60px"
                    }}>
                    <ShareButton
                      idEntity={convo.id}
                      object={convo as Convocatoria}
                      entity={"convocatoria"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Details convo={convo} />
        </>
      )}

      <div ref={myRef}>
        {convo.state == "opened" && step === 1
          ? step1
          : step === 2
          ? step2
          : null}
      </div>
    </>
  );
};

export default ConvoDetail;
