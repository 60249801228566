import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";

const useCityHalls = () => {
  const [cityHalls, setCityHalls] = useState([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ city_hall_list: { data: cityHalls } }) =>
      setCityHalls(cityHalls)
  });

  return [cityHalls, loading];
};

const query = gql`
  query getCityHalls($lang: String) {
    city_hall_list(limit: 200, filtered: false, lang: $lang) {
      data {
        id
        name
      }
    }
  }
`;

export default useCityHalls;
