import React from "react";
import useTermsAndConditions from "./useTermsAndConditions";
import classes from "./index.module.scss";
import useScrollToTop from "hooks/useScrollToTop";
import useLangContext from "hooks/useLangContext";

const TermsAndConditions = () => {
  const [termsAndConditions] = useTermsAndConditions();
  const { lang } = useLangContext();

  let paragraph = termsAndConditions.split("\n");
  useScrollToTop();

  return (
    <div className={`container ${classes.terms}`}>
      <h2 className='text-center font-weight-bold text-secondary my-5'>
        {lang.Navbar.header.top.termsAndCondi}
      </h2>
      <div className={classes.separador}></div>
      {paragraph.map((pa, index) => {
        return (
          <div key={index} className={classes.paragraph}>
            <p>{pa}</p>
          </div>
        );
      })}
    </div>
  );
};

export default TermsAndConditions;
