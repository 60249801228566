export default {
  title: "Mi Agenda",
  mear: "Proximos",
  from: "Hace",
  daysEnd: "dias",
  past: "Pasados",
  missing: "faltan",
  days: "dias",
  noResult: "Aún no has sumado un ReCreo a tu agenda"
};
