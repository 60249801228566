import { gql, useQuery } from "@apollo/client";
import dayjs from "dayjs";
import useLangContext from "hooks/useLangContext";
import { useMemo, useState } from "react";

const useMySchedule = () => {
  const [schedules, setSchedules] = useState({
    pastShedule: [],
    nowShedule: []
  });
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ schedules: { data: schedulesReturned } }) => {
      {
        //ordeno la agenda por fecha de menor a mayor
        sortSchedules(schedulesReturned);
      }
    },
    variables: { page: 1, limit: 99999, lang: lang.current },
    onError: error =>
      console.error("Parece que tuvimos un problema cargando la agenda", error)
  });

  const sortSchedules = (schedulesReturned: Array<{}>) => {
    schedulesReturned.sort((a, b) => {
      let dateA = new Date(a.recess.date_to),
        dateB = new Date(b.recess.date_to);
      return dateA - dateB;
    });
    //agendas de ahora en adelante.
    let now = schedulesReturned.filter(
      (schedule: any) => dayjs(new Date()).diff(schedule.recess?.date_to) < 0
    );
    //agendas pasadas.
    let past = schedulesReturned.filter(
      (schedule: any) => dayjs(new Date()).diff(schedule.recess?.date_to) > 0
    );
    setSchedules({ pastShedule: past, nowShedule: now });
  };

  return useMemo(
    () => ({
      schedules,
      loading
    }),
    [loading, schedules]
  );
};

const query = gql`
  query getSchedules($page: Int, $limit: Int, $lang: String) {
    schedules(limit: $limit, page: $page) {
      data {
        id
        recess(lang: $lang) {
          id
          title
          address
          date_from
          date_to
          score
          is_free
          date_from
          date_to
          categories(lang: $lang) {
            icon {
              uri
            }
            name
          }
          city_hall {
            name
          }
          icon {
            uri
          }
          images {
            uri
          }
        }
      }
    }
  }
`;

export default useMySchedule;
