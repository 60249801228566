import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";

const useTermsAndConditions = (): [string, boolean] => {
  const [termsAndConditions, setTermsAndConditions] = useState(``);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ term_and_condition: { text } }) =>
      setTermsAndConditions(text),
    onError: error =>
      console.error(
        "Tuvimos un problema al cargar los terminos y condiciones",
        error
      )
  });

  return [termsAndConditions, loading];
};

const query = gql`
  query getTermsAndConditions($lang: String) {
    term_and_condition(lang: $lang) {
      text
    }
  }
`;

export default useTermsAndConditions;
