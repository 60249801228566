import React from "react";

function SvgWarning(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 28 32'
      width={28}
      height={32}
      {...props}>
      <path
        color='currentColor'
        fill='currentColor'
        stroke='currentColor'
        d='M27.814 4.802C27.389 3.117 25.064.288 14.199.288 9.08.288 5.365.85 3.154 1.958.713 3.182.519 4.838.519 5.328v9.576a16.56 16.56 0 008.438 14.4l4.183 2.354c.657.369 1.46.369 2.117 0l4.183-2.354a16.559 16.559 0 008.438-14.4V5.328c0-.177-.022-.354-.065-.526zm-2.096 10.08c0 5.201-2.804 9.998-7.337 12.55l-4.183 2.376-4.183-2.354a14.4 14.4 0 01-7.337-12.55V5.328s0-2.88 11.52-2.88c10.8 0 11.52 2.88 11.52 2.88v9.554z'
      />
      <path
        color='currentColor'
        fill='currentColor'
        stroke='currentColor'
        d='M15.838 23.688a2 2 0 11-3.999.001 2 2 0 013.999-.001zM13.838 6.408a1.08 1.08 0 00-1.08 1.08v11.52a1.08 1.08 0 002.16 0V7.488a1.08 1.08 0 00-1.08-1.08z'
      />
    </svg>
  );
}

export default SvgWarning;
