import { gql, useMutation } from "@apollo/client";
import { useMemo } from "react";

const useCodeBurner = (recessId?: string) => {
  const [burnCode, { loading: loadingAction }] = useMutation(query, {
    onCompleted: () => {
      alert("¡QR Escaneado!");
      window.location.reload();
    }
  });

  async function burn(codeQr: string) {
    await burnCode({
      variables: { code: codeQr, recess_id: recessId }
    });
  }

  return useMemo(
    () => ({
      burn,
      loadingAction
    }),
    [burnCode, loadingAction]
  );
};

const query = gql`
  mutation burnCode($code: String!, $recess_id: Int!) {
    save_recess_visited_qr(code_qr: $code, recess_id: $recess_id)
  }
`;

export default useCodeBurner;
