import React from "react";
import { distance } from "helpers";
import useFaved from "hooks/useFaved";
import CityHall from "typedef/CityHall";
import useOnClick from "./useOnClick";
import Card from "components/Card";
import useSocialShare from "hooks/useSocialShare";
import { useHistory } from "react-router-dom";

export type CityHallCardProps = {
  cityHall: CityHall;
  index?: number;
  coords?: {
    latitude: number;
    longitude: number;
  };
};

const CityHallCard: React.FC<CityHallCardProps> = ({
  cityHall,
  index,
  coords
}) => {
  const history = useHistory();
  const [faved, fav] = useFaved(cityHall.id, "municipio", cityHall.is_favorite);
  const onClick = useOnClick(cityHall.id);
  const useShareSocial = useSocialShare(cityHall.id, "municipio", cityHall);

  return (
    <Card
      key={cityHall.id}
      faved={faved}
      onClick={() => {
        onClick();
        history.push(`cityhall/${cityHall.id}`);
      }}
      onFav={fav}
      onShare={useShareSocial}
      score={cityHall.score}
      containerClassName={`col-12 col-md-6 mb-3`}
      img={cityHall.icon}>
      {coords ? (
        <h5 className='text-muted small'>
          A {Math.floor(distance(coords, cityHall)) || "--"} Km
        </h5>
      ) : null}
      <h6 className='mb-2 font-weight-bold'>{cityHall.name}</h6>
      <p
        className='small text-muted description overflow-hidden '
        style={{ maxHeight: "50px" }}>
        {/*pequeño calculo matematico para acortar el texto segun el ancho de la pantalla*/}
        {cityHall.review?.slice(0, 110)}...
      </p>
    </Card>
  );
};

export default CityHallCard;
