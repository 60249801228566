import * as React from "react";

function SvgSearch(props) {
  return (
    <svg
      width={22}
      viewBox='0 0 22 23'
      height={23}
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M21.466 21.224l-5.082-5.054a9.438 9.438 0 10-1.264 1.067l5.18 5.181a.825.825 0 001.166-1.166v-.027zM2.046 9.57a7.7 7.7 0 1115.4 0 7.7 7.7 0 01-15.4 0z' />
    </svg>
  );
}

export default SvgSearch;
