import { LIGHT_GRAY } from "assets/colors";
import Icon from "assets/icons";
import classes from "./classes.module.scss";
import useHowUseRecredits from "./useHowUseRecredits";

type howToUseModalProps = {
  onClose: (param: any) => any;
};

const HowToUseModal: React.FC<howToUseModalProps> = ({ onClose }) => {
  const { preItems, itemsStructured } = useHowUseRecredits();

  return (
    <div
      className={`position-fixed shadow m-auto bg-white ${classes.howtouseModal}`}>
      <button
        style={{ zIndex: 3 }}
        onClick={onClose}
        className='position-absolute mt-2'>
        <Icon className='d-flex' custom='Cross' fill={LIGHT_GRAY} size={14} />
      </button>
      <div className='w-100 d-flex p-4 '>
        <div className='px-4'>
          <div className='my-4'>
            <h5 className='text-primary text-center font-weight-bold '>
              ¿Cómo se usa?
            </h5>
            <div className='text-center mx-3 my-4'>{preItems}</div>
            <div className=''>
              {itemsStructured.map((item, i) => {
                return (
                  <div key={i} className='d-flex align-items-top'>
                    <b className='mr-2 mt-2'>{item[0]}</b>
                    <div className='m-2'>{item[1]}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HowToUseModal;
