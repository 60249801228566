import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import useAccessToken from "./useAccessToken";
import User from "typedef/User";

/**
 * Hook to get users data
 */
const useUser = (): [User | undefined, boolean] => {
  const [user, setUser] = useState<User>();
  // @ts-ignore
  const accessToken = useAccessToken();
  const { loading } = useQuery(query, {
    onCompleted: response => setUser(response?.me),
    onError: error => {
      if (accessToken) {
        console.error(
          `Tuvimos un problema cargando los datos del usuario ${JSON.stringify(
            error
          )}`
        );
      }
    }
  });

  return [user, loading];
};

const query = gql`
  {
    me {
      id
      first_name
      last_name
      email
      is_city_hall_member
      is_commerce_member
      profile_app {
        city_hall {
          id
          name
          latitude
          longitude
        }
      }
    }
  }
`;

export default useUser;
