import "./style.scss";
import { useRedirectBySistemOperative } from "./useRedirectBySistemOperative";

const AgendaCultural = () => {
  useRedirectBySistemOperative();

  return (
    <div className='agenda-cultural-container container-full'>
      <div className='p-0'>
        <div className='agendaCulturalPage'>
          <iframe
            src='https://recreoagendacultural.gba.gob.ar/'
            width='100%'
            height='1410px'
            id='0'
            frameBorder='0'
            className=''
            display='block'
            position='relative'
          />
        </div>
      </div>
    </div>
  );
};

export default AgendaCultural;
