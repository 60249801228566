import CarruselHero from "components/CarruselHero/CarruselHero";
import FooterHero from "components/TextualHero/FooterHero";
import { formatExternalAssetUrl } from "helpers";
import useGPS from "hooks/useGPS";
import ReCredit from "typedef/ReCredits";
import classes from "components/TextualHero/style/index.module.scss";
import CenterHero from "components/TextualHero/CenterHero";
import answer from "assets/images/answer.svg";
import useHowToUseRecredits from "components/HowToUseRecredits";
import Benefit from "typedef/Benefit";
import useSocialShare from "hooks/useSocialShare";

type recreditHeroProps = {
  recredit: ReCredit;
  openModal: () => void;
  loadingRecredit?: boolean;
};

const ReCreditHero: React.FC<recreditHeroProps> = ({
  recredit,
  openModal,
  loadingRecredit
}) => {
  const howToUseuse = useHowToUseRecredits();
  const useShareSocial = useSocialShare(recredit.id, "recredito", recredit);

  const useShare = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    useShareSocial();
  };

  return (
    <div className='container-fluid bg-white p-0 overflow-hidden'>
      <div className='row '>
        <CarruselHero videos={recredit?.videos} uris={recredit.images} />
        <div
          className={`col-12 col-md-12 col-lg-6 col-xl-6 ${classes.textSide}`}>
          <div className='flex-wrap d-flex justify-content-between h-100 container'>
            <div className={`${classes.top} w-100`}>
              <div className='d-flex justify-content-between '>
                <div className='rounded bg-primary text-white px-2 py-1'>
                  <h4>
                    <small>{recredit.cost_recredits} recréditos</small>
                  </h4>
                </div>
                <img
                  style={{ cursor: "pointer" }}
                  onClick={howToUseuse}
                  className='mr-3'
                  src={answer}
                  alt='preguntas?'
                />
              </div>
              <div className='text-secondary mt-3'>
                <h3>
                  <b>{recredit.name}</b>
                </h3>
                <h3>{recredit.commerce.name}</h3>
              </div>
              <div className={`d-flex ${classes.category}`}>
                <img
                  className='col-auto p-0'
                  src={formatExternalAssetUrl(recredit.category?.icon)}
                />
                <h6 className='text-muted m-0 col'>
                  {recredit.category?.name}
                </h6>
              </div>
            </div>
            <div className='bottom d-flex flex-wrap justify-content-between w-100'>
              <CenterHero
                loadingRecredit={loadingRecredit}
                openModal={openModal}
                children={recredit}
              />
              <hr
                style={{ borderBottom: "solid 1px lightgray", width: "100%" }}
              />
              <FooterHero onShare={useShareSocial} stars={recredit.score} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReCreditHero;
