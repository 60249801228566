import { useState, useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { Alert } from "react-native";
import Convocatoria from "typedef/Convocatoria";
import { useHistory } from "react-router";

export interface AsyncConvocatoria extends Partial<Convocatoria> {
  loading: boolean;
}

const useSimpleConvo = (id: string | null) => {
  const history = useHistory();
  const [convo, setConvo] = useState<Convocatoria | undefined>();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ annoucement_detail }) => {
      if (annoucement_detail) {
        setConvo(annoucement_detail);
      } else {
        console.error("Parece que no existe el recreo", annoucement_detail);
        alert("Parece que no existe el recreo que intenta ver.");
      }
    },
    onError: error => {
      if (id === null) {
        console.error(
          "Esta pantalla debería venir con un id para indicar la Convocatoria que se busca",
          error
        );
        alert("Parece que no ha indicado la Convocatoria que desea ver.");
      }
      console.error(
        "Tuvimos un problema al cargar la Convocatoria",
        JSON.stringify(error)
      );
      history.goBack();
      setTimeout(() => {
        Alert.alert("Parece que no pudimos cargar la Convocatoria");
      }, 1000);
    },
    variables: {
      id
    }
  });

  return useMemo(
    () => ({
      ...convo,
      loading
    }),
    [convo, loading]
  );
};

const query = gql`
  query($id: String!) {
    annoucement_detail(id: $id) {
      id
      title
      annoucement_type
      open_date
      close_date
      open_date_vote
      close_date_vote
      terms_and_conditions
      requirements
      description
      evaluation_metodology
      has_prize
      prize
      city_hall {
        id
        name
      }
      image {
        uri
      }
      items {
        id
        type
        title
      }
      state
      juries {
        id
        first_name
        last_name
        email
        title_role
        description
        know_more
        photo
        url_instagram
        url_twitter
        url_youtube
        url_facebook
        city_hall {
          name
        }
      }
    }
  }
`;

export default useSimpleConvo;
