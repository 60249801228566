import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import getEnvVariable from "env";
import { Linking } from "react-native";
import RecreoDefaultImage from "assets/images/ReCreoDefaultImage.png";

export function distance(A, B) {
  const lat1 = parseFloat(A?.latitude);
  const lon1 = parseFloat(A?.longitude);
  const lat2 = parseFloat(B?.latitude);
  const lon2 = parseFloat(B?.longitude);
  if (lat1 == lat2 && lon1 == lon2) {
    return 0;
  } else {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344;
    return dist;
  }
}

export function validatePassword(password) {
  return !(
    password?.length < 8 ||
    !password
      ?.split("")
      .some(char => char === char.toUpperCase() && isNaN(parseInt(char))) ||
    !password?.split("").some(char => !isNaN(parseInt(char)))
  );
}

export async function emailExists(email) {
  const client = new ApolloClient({
    uri: getEnvVariable("API_URL"),
    cache: new InMemoryCache()
  });

  try {
    const {
      data: { search_email_exist }
    } = await client.query({
      query: gql`
        query checkEmail($email: String!) {
          search_email_exist(email: $email)
        }
      `,
      variables: {
        email
      }
    });
    return search_email_exist;
  } catch (err) {
    console.error(
      "Tuvimos un problema al validar la disponibilidad del correo",
      err
    );
  }
}

/**
 *
 * @param {{longitude: string, latitude: string}} coords coordenadas para abrir en el mapa
 * @param {string} title Titulo para referenciar el punto en el mapa
 */
export function showInMap(coords, title) {
  // const scheme = Platform.select({ios: 'maps:0,0?q=', android: 'geo:0,0?q='});
  // const latLng = `${parseFloat(coords.latitude)},${parseFloat(coords.longitude,)}`;
  // const url = Platform.select({
  //   ios: `${scheme}${title.replace(/&/g,"And")}@${latLng}`,
  //   android: `${scheme}${latLng}(${title.replace(/&/g,"And")})`,
  // });
  // Linking.openURL(url);
}

export function formatExternalAssetUrl(link) {
  if (link?.uri) {
    return `${getEnvVariable("SERVER_URL")}${link.uri}`;
  } else {
    return link?.url;
  }
}
export function returnDefaultImg(link) {
  if (link && link.length > 9) {
    return `${getEnvVariable("SERVER_URL")}${link}`;
  } else {
    return RecreoDefaultImage;
  }
}
/**
 * Parse the notifications coming from the server
 * to a usable RecessCategory JavaScript Object with it's main category assigned.
 * @param {object} unparsedNotification
 * @returns {Promise<Array<RecreoNotification>>}
 */
export async function parseNotification(unparsedNotification) {
  const parsedNotification = JSON.parse(
    JSON.stringify(unparsedNotification)
      .replace(/\\/g, "")
      .replace(/"{/g, `{`)
      .replace(/}"/g, `}`)
      .replace(/"\[/g, `[`)
      .replace(/\]"/g, `]`)
  );
  return parsedNotification;
}

export function YoutubeIdVideosToEmbed(videosUrl) {
  try {
    videosUrl = videosUrl.split("/")[3];
    videosUrl = videosUrl.split("=")[1];
    videosUrl = videosUrl.split("&")[0];
  } catch (e) {
    console.log(e);
  }
  return videosUrl;
}
