import { validatePassword } from "helpers";

function validate(token, password, password_confirmation) {
  if (!validatePassword(password)) return false;
  if (password_confirmation !== password) return false;
  if (!token) return false;
  return true;
}

export default validate;
