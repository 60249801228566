import useFirebaseEvent from "hooks/useFirebaseEvent";
import Recess from "typedef/Recess";

const useSimpleRecessFirebaseEvent = (recess: Partial<Recess>) => {
  useFirebaseEvent<"se_abrio_recreo_simple">({
    eventName: "se_abrio_recreo_simple",
    onMount: true,
    eventData: {
      id_recreo_simple: recess.id,
      id_categoria_recreo: recess.categories?.[0].id
    }
  });
  useFirebaseEvent<"se_abrio_punto_de_interes">({
    eventName: "se_abrio_punto_de_interes",
    onMount: true,
    eventData: {
      id_punto_de_interes: recess.is_interest_point ? recess.id : undefined
    }
  });
  useFirebaseEvent<"se_abrio_imperdible">({
    eventName: "se_abrio_imperdible",
    onMount: true,
    eventData: {
      id_recreo_imperdible: recess.is_safety_pin ? recess.id : undefined
    }
  });
};

export default useSimpleRecessFirebaseEvent;
