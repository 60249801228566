import BenefitCard from "components/BenefitCard";
import UtiliyBar from "components/UtilityBar";
import useBenefits from "./useBenefits";
import useCategories from "./useCategories";
import Loader from "assets/images/loader.svg";
import { useHistory } from "react-router";
import useBenefitsLocations from "hooks/getLocationsByType/useBenefitsLocation";
import Map from "components/Map";
import useLangContext from "hooks/useLangContext";

type benefitListProps = {
  mapVisible: boolean;
};

const BenefitsList: React.FC<benefitListProps> = ({ mapVisible }) => {
  const benefits = useBenefits();
  const history = useHistory();
  const categories = useCategories();
  const { lang } = useLangContext();
  const locations = useBenefitsLocations();

  return (
    <>
      {!locations.loading && locations.benefitLocation && (
        <div className='mb-5'>
          <Map
            isVisible={mapVisible}
            ubications={locations.benefitLocation}
            type={"benefit"}
          />
        </div>
      )}
      <UtiliyBar
        className='mt-3 mb-5'
        utils={categories.list}
        searchBar={lang.Beneficios.search}
        title={lang.Beneficios.searchPlaceholder}
      />
      <div className='row justify-content-between'>
        {benefits.loading ? (
          <div data-testid='loader' className='d-flex m-auto'>
            <img src={Loader} />
          </div>
        ) : benefits?.list.length == 0 ? (
          <div className='p-3 mb-5 w-100 d-flex justify-content-center'>
            <h5 className='text-center'>{lang.Beneficios.noResult}</h5>
          </div>
        ) : (
          benefits.list.map((benefit, i) => (
            <BenefitCard
              key={i}
              item={benefit}
              index={i}
              onClick={() => history.push(`/benefit/${benefit.id}`)}
            />
          ))
        )}
      </div>
      <div className='d-block m-auto'>
        {!benefits?.loading &&
        !benefits?.limitReached &&
        benefits?.fetchMore ? (
          <button
            className='secondary secondary--magenta d-block my-5 mx-auto'
            onClick={() => benefits?.fetchMore()}>
            {lang.Beneficios.seeMore}
          </button>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default BenefitsList;
