export default {
  title: "My benefits",
  tabBenefit: "Benefits",
  tabReCredit: "Credits",
  noResultCredit: "No credits avaiable",

  code: "Code",
  currency: "Currency",
  qrLoadError: "We could not load the QR code"
};
