export default {
  title: "Login",
  noRemind: "",
  forgotPassword: "Password recovery",
  loginFacebook: "Login with FaceBook",
  loginGmail: "Login with Gmail",
  loginEmail: "Login with e-mail",
  notLogin: "",
  setPass: "Enter your password",
  createAccount: "Create account"
};
