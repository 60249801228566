import { gql, useQuery } from "@apollo/client";
import { ReducerAction } from "./reducer";

const useUser = (dispatch: React.Dispatch<ReducerAction>) => {
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: user => {
      dispatch({
        type: "set_user",
        payload: {
          ...user?.me,
          ...user?.me?.profile_app,
          phone_prefix: (user?.me?.profile_app.phone as string).substring(0, 3),
          phone: (user?.me?.profile_app.phone as string).substring(3, Infinity),
          locality_id: user?.me?.profile_app.locality.id,
          city_hall_id: user?.me?.profile_app.city_hall.id,
          tag_ids: user?.me?.profile_app.recess_categories.map((t: any) => t.id)
        }
      });
    },
    onError: error => console.error(2, error)
  });

  return loading;
};

const query = gql`
  {
    me {
      id
      first_name
      last_name
      email
      document_number
      profile_app {
        birth_day
        total_family_group
        total_children
        country
        phone
        state
        recess_categories {
          id
          tag
        }
        locality {
          id
        }
        city_hall {
          id
        }
      }
    }
  }
`;

export default useUser;
