import { useState, useEffect } from "react";
import useUser from "./useUser";

/**
 * Hook to get gps position
 */
const useGPS = (): [GeolocationCoordinates | undefined, boolean] => {
  const [user] = useUser();
  const [coords, setCoords] = useState<GeolocationCoordinates>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const userCityHall = user?.profile_app.city_hall;
    if (userCityHall && coords === undefined) {
      setCoords({
        latitude: userCityHall.latitude,
        longitude: userCityHall.longitude,
        accuracy: 1,
        altitude: 0,
        altitudeAccuracy: 0,
        heading: null,
        speed: 0
      });
    }
  }, [user]);

  useEffect(() => {
    setLoading(true);
    navigator.geolocation.getCurrentPosition(
      position => {
        setCoords(position.coords);
        setLoading(false);
      },
      error => {
        console.error(
          `No se pudo obtener acceso a la ubicación del usuario. ${error.message}`
        );
        setLoading(false);
      }
    );
  }, []);

  return [coords, loading];
};

export default useGPS;
