import { useCallback } from "react";
import { useHistory } from "react-router";
import Recess from "typedef/Recess";

const useOnClick = () => {
  const history = useHistory();
  return useCallback(
    (amenity?: Recess) => () => {
      if (amenity) {
        if (Boolean(amenity.agenda?.length))
          history.push(`/recess/multiple/${amenity.id}`);
        else if (Boolean(amenity.circuit?.length))
          history.push(`/recess/circuit/${amenity.id}`);
        else history.push(`/recess/simple/${amenity.id}`);
      } else {
        console.error("Parece que no tiene un recreo conectado");
      }
    },
    [history]
  );
};

export default useOnClick;
