import * as React from "react";

function SvgMapCard(props) {
  return (
    <svg width={23} height={23} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M21.305 2.56L16.185.512a1.536 1.536 0 00-1.336.103 1.536 1.536 0 00-1.337-.103L8.392 2.56c-.069.028-.135.06-.2.098a1.536 1.536 0 00-.199-.098L2.873.512A1.536 1.536 0 00.769 1.941v16.896c-.001.63.383 1.195.967 1.428l5.12 2.048c.438.173.93.135 1.337-.102.406.237.898.275 1.336.102l5.12-2.048c.069-.027.136-.06.2-.097.064.037.13.07.2.097l5.12 2.048a1.536 1.536 0 002.104-1.428V3.989c0-.63-.383-1.196-.968-1.429zm-19 16.277V1.94l5.12 2.048v16.896l-5.12-2.048zm6.656 2.048V3.989l5.12-2.048v16.896l-5.12 2.048zm11.776 0l-5.12-2.048V1.94l5.12 2.048v16.896z'
        strokeWidth={1.4}
      />
    </svg>
  );
}

export default SvgMapCard;
