export default {
  LogoTitle: "ReCreo",
  header: {
    top: {
      vaccination: "Vacunación y Testeo",
      destiny: "Destinos Cuidados",
      termsAndCondi: "Términos y Condiciones",
      frequentQuestions: "Preguntas Frecuentes",
      downloadRecreo: "Descargá ReCreo"
    },
    main: {
      knowCityHall: "Conocé la ReProvincia",
      recreos: "ReCreos",
      schedule: "Agenda",
      convocatorias: "Convocatorias",
      benefit: "Beneficios",
      login: "Iniciá sesión",
      singIn: "Creá tu cuenta"
    },
    login: {
      editProfile: "Editar mis datos",
      level: "Nivel",
      virtual: "virtual",
      curious: "Curioso",
      explorer: "Explorador",
      expert: "Experto",
      points: "puntos",
      myFavs: "Mis Favoritos",
      myBenefits: "Mis Beneficios",
      myTickets: "Mis Entradas",
      mySchedule: "Mi Agenda",
      polls: "Encuestas",
      notifications: "Notificaciones",
      configurate: "Configuracion",
      logOut: "Salir"
    }
  }
};
