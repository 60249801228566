import Loader from "assets/images/loader.svg";
import useRecreoditsPoints from "screens/Benefits/ReCreditsList/useRecreditsPoints";
import answerMuted from "assets/images/answer-muted.svg";
import MyReCreditCard from "components/MyReCreditCard";
import ErrorMSJ from "components/ErrorMSJ";
import useMyRecredits from "./useMyRecredits";
import dayjs from "dayjs";

const MyRecreditsList = () => {
  const { points, dateEnd } = useRecreoditsPoints();
  const { recredits, loading } = useMyRecredits();

  return (
    <>
      <div
        className='bg-white p-3 rounded w-100 d-flex align-items-center'
        style={{ color: "#666666", width: "fit-content" }}>
        <div
          className='badge-pill pr-3 pl-3 pt-1 pb-1'
          style={{ background: "rgba(204, 204, 204, 0.3)" }}>
          {points || 0}
        </div>
        <div className='ml-3'>
          <b>ReCréditos Disponibles</b>
        </div>
        {dateEnd && (
          <div className='mx-2 text-muted'>
            Haste el {dayjs(dateEnd).format("DD/MM/YY")}
          </div>
        )}
        <img className='ml-auto' src={answerMuted} />
      </div>

      {loading ? (
        <div className='d-flex justify-content-center align-items-center'>
          <img src={Loader} />
        </div>
      ) : recredits && recredits.length > 0 ? (
        <div className='row my-4'>
          {recredits.map((recredit, i) => (
            <MyReCreditCard
              key={i}
              useTime={recredit.date_use}
              recreditId={recredit.id}
              recredit={recredit.benefit}
            />
          ))}
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center my-5'>
          <ErrorMSJ title='Ups. Aún no usaste tus ReCréditos' />
        </div>
      )}
    </>
  );
};

export default MyRecreditsList;
