import React from "react";
import CheckBox from "components/CheckBox";

const CheckBoxGroup = ({
  checkBoxes,
  selected,
  onSelect,
  style,
  className
}) => {
  return (
    <div className={`${className}`} style={style}>
      {checkBoxes.map(({ value, label }, index) => (
        <CheckBox
          style={index && { marginTop: 10 }}
          onSelect={select => (select ? onSelect(value) : onSelect(undefined))}
          selected={selected === value}
          label={label}
        />
      ))}
    </div>
  );
};

export default CheckBoxGroup;
