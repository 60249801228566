import React from "react";
import { Link } from "react-router-dom";
import BackArrow from "assets/images/back-arrow.svg";
import useLangContext from "hooks/useLangContext";

export type SubtitleProps = {
  title: string;
  to: string;
};

const Title: React.FC<SubtitleProps> = ({ title, to }) => {
  const { lang } = useLangContext();
  return (
    <div className='d-flex justify-content-between align-items-baseline mt-5 mb-5'>
      <div>
        <Link to={to}>
          <button className='backButton'>
            <img src={BackArrow} />
            {lang.Elements.btnReturn}
          </button>
        </Link>
      </div>

      <div>
        <h5 className='text-secondary font-weight-bold'>{title}</h5>
      </div>
      <div></div>
    </div>
  );
};

export default Title;
