import usePrompt from "hooks/usePrompt";
import { useCallback } from "react";
import HowToUseModal from "./HowToUseModal";

const useHowToUseRecredits = () => {
  const prompt = usePrompt();

  return useCallback(async () => {
    return prompt<any>(props => <HowToUseModal {...props} />)
      .then(response => console.log())
      .catch(error => console.error(error));
  }, []);
};

export default useHowToUseRecredits;
