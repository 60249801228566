import * as React from "react";

function SvgMenu(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      className='menu_svg__feather menu_svg__feather-menu'
      color='#FFFFFF'
      {...props}>
      <path
        fill='currentColor'
        stroke='currentColor'
        d='M3 12h18M3 6h18M3 18h18'
      />
    </svg>
  );
}

export default SvgMenu;
