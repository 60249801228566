import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { useMemo, useState } from "react";
import Reward from "typedef/Reward";

const useRewardQR = (id: string) => {
  const [reward, setReward] = useState<Reward>();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      reward_id: id
    },
    onError: error =>
      console.error(
        "Tuvimos un problema al cargas los municipios favoritos",
        error
      ),
    onCompleted: ({ reward_detail }) => {
      setReward(reward_detail);
    }
  });
  return useMemo(
    () => ({
      reward,
      loading
    }),
    [reward, loading]
  );
};

const query = gql`
  query getReward($reward_id: String!) {
    reward_detail(reward_id: $reward_id) {
      code_qr
    }
  }
`;

export default useRewardQR;
