import * as React from "react";

function SvgStar(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 15 15'
      width={32}
      height={32}
      {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.50068 0.5C7.08341 0.500358 6.70429 0.742882 6.52904 1.12156L4.67151 5.14384H1.07074C0.637593 5.14422 0.247261 5.40532 0.0815532 5.80552C-0.084155 6.20572 0.00734706 6.66632 0.313441 6.9728L3.11403 9.77339L2.16026 14.2029C2.06843 14.6311 2.24637 15.0721 2.60965 15.3166C2.97293 15.5612 3.44846 15.5601 3.81061 15.3139L7.49711 12.8133L11.1765 15.3139C11.5272 15.5529 11.9859 15.5625 12.3463 15.3382C12.7066 15.1139 12.9007 14.6981 12.8411 14.2779L12.2052 9.80554L15.0416 6.96923C15.3447 6.66289 15.4348 6.20479 15.2703 5.80646C15.1059 5.40813 14.7188 5.14707 14.2878 5.14384H10.3298L8.47231 1.12156C8.29706 0.742882 7.91794 0.500358 7.50068 0.5Z'
        fill='#8B2C75'
      />
    </svg>
  );
}
export default SvgStar;
