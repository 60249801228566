export default {
  startTour: "iniciar recorrido",
  safetyPin: "Imperdibles",
  pointOfInt: "Puntos de Interés",
  noResultBenefit1: "Ups. Aún no hay beneficios en ",
  noResultBenefit2:
    " Ingresá a Beneficios y conocé los ReBeneficios que tenemos para vos",
  noResultImperdibles: "Todavía no hay imperdibles disponibles en ",
  didYouKnow: "¿Sabías que?"
};
