import "dayjs/locale/es";
import useRecesses from "../Home/Recesses/useRecesses";
import MainIcon from "assets/images/agenda-title.svg";
import UtiliyBar from "components/UtilityBar/index";
import useCategories from "screens/Home/useCategories";
import useScrollToTop from "hooks/useScrollToTop";
import RecessCard from "components/RecessCard";
import { useEffect, useState } from "react";
import Recess from "typedef/Recess";

const Recesses = () => {
  const [cantResult, setCantResult] = useState(20);
  const [recesses, loading] = useRecesses(cantResult);
  useScrollToTop();

  return (
    <div className='container'>
      <div className='section-title d-flex align-items-center my-4'>
        <img src={MainIcon} className='mr-4 logo-sub-title' />
        <h2 className='text-secondary font-weight-bold mt-2'>
          ReCreos Para Vos
        </h2>
      </div>

      <div className='row my-5'>
        {recesses?.map(recess => (
          <RecessCard recess={recess} key={recess.id} />
        ))}
        <button
          className='secondary secondary--magenta d-block my-5 mx-auto'
          onClick={() => {
            setCantResult(cantResult + 20);
          }}>
          Cargar más recreos para vos
        </button>
      </div>
    </div>
  );
};

export default Recesses;
