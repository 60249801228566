import { useEffect, useMemo, useRef, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Recess from "typedef/Recess";
import useLangContext from "hooks/useLangContext";

const useDay = (day: string) => {
  const [recesses, setRecesses] = useState<Recess[]>([]);
  const page = useRef(1);
  const { lang } = useLangContext();
  const [limitReached, setLimitReached] = useState(false);

  let { refetch, fetchMore, loading } = useQuery(getPaginationAgenda, {
    fetchPolicy: "no-cache",
    onCompleted: ({ calendar: { data: recesses } }) => setRecesses(recesses),
    onError: err =>
      console.error(
        "Tuvimos un problema al cargar los recreos",
        JSON.stringify(err)
      ),
    variables: {
      date: day,
      page: 1,
      lang: lang.current
    }
  });

  function _fetchMore() {
    if (limitReached) return;
    fetchMore({
      variables: {
        date: day,
        page: ++page.current,
        lang: lang.current
      }
    })
      .then(
        ({
          data: {
            calendar: { data: recesses, last_page }
          }
        }) => {
          setRecesses(prev => [...prev, ...recesses]);
          if (parseInt(last_page) <= page.current) {
            setLimitReached(true);
          }
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema al cargar más municipios", error)
      );
  }

  function _refetch() {
    refetch({
      date: day,
      page: 1,
      lang: lang.current
    })
      .then(
        ({
          data: {
            calendar: { data: recesses }
          }
        }) => {
          setRecesses(recesses);
          page.current = 1;
          setLimitReached(false);
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema recargando los municipios", error)
      );
    page.current = 1;
  }

  return useMemo(
    () => ({
      list: recesses,
      loading,
      fetchMore: _fetchMore,
      limitReached
    }),
    [recesses, loading, limitReached, fetchMore]
  );
};

const getPaginationAgenda = gql`
  query getRecesses(
    $page: Int
    $longitude: Float
    $latitude: Float
    $limit: Int
    $date: String!
    $lang: String
  ) {
    calendar(
      limit: $limit
      page: $page
      longitude: $longitude
      latitude: $latitude
      date: $date
      lang: $lang
    ) {
      data {
        id
        title
        date_from
        date_to
        is_favorite
        is_multiple
        circuit {
          id
        }
        agenda {
          id
        }
        categories {
          name
          id
          icon {
            uri
          }
        }
        icon {
          uri
        }
        city_hall {
          name
        }
      }
      last_page
    }
  }
`;

export default useDay;
