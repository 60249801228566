const validURL = (str: string) => {
  var regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(str)) {
    // return alert("Please enter valid URL.");
    return false;
  } else {
    return true;
  }
};

export default validURL;
