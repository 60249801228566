import usePrompt from "hooks/usePrompt";
import { useCallback, useEffect } from "react";
import TicketModal from "./TicketModal";
import Tikcet from "typedef/Ticket";
import Ticket from "typedef/Ticket";

const useTicketQrModal = (
  ticket: Ticket,
  userId: String,
  goToMap?: () => void
) => {
  const prompt = usePrompt();

  return useCallback(async () => {
    prompt<any>(props => (
      <TicketModal
        ticket={ticket}
        userId={userId}
        onGoToRecess={goToMap}
        {...props}
      />
    ))
      .then(response => console.log(response))
      .catch(error => console.error(error));
  }, []);
};

export default useTicketQrModal;
