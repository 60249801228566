import { useEffect, useRef } from "react";
import Icon from "assets/icons/index";
import styles from "./modal.module.css";

const Modal = ({ modalStyle, children, show, backdropStyle, onClose }) => {
  const modalRef = useRef(null);
  useEffect(() => {
    if (show) {
      modalRef.current.classList.add(styles.visible);
    } else {
      modalRef.current.classList.remove(styles.visible);
    }
  }, [show]);
  return (
    <>
      <div
        ref={modalRef}
        style={backdropStyle}
        className={`${styles.modal__wrap}`}>
        <div style={modalStyle} className={styles.modal}>
          <Icon
            custom='Cross'
            fill={"gray"}
            width={12}
            style={{
              marginLeft: "0.8em",
              marginTop: "0.5em",
              cursor: "pointer"
            }}
            onClick={() => onClose()}
          />
          {children}
        </div>
      </div>
    </>
  );
};

export default Modal;
