import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useCallback, useEffect, useMemo } from "react";
import { useState } from "react";
import { Alert } from "react-native";
import Benefit from "typedef/Benefit";

const useMyBenefits = () => {
  const [benefits, setBenefits] = useState<Array<Benefit>>();
  const { lang } = useLangContext();
  const { loading, refetch } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({
      me: {
        profile_app: { benefits: benefits }
      }
    }) => {
      setBenefits(benefits);
    },
    variables: {
      page: 1,
      limit: 99999,
      lang: lang.current
    },
    onError: error =>
      console.error(
        "Parece que tuvimos un problema cargando los beneficios",
        error
      )
  });

  const _refetch = useCallback(async () => {
    try {
      const {
        data: {
          me: {
            profile_app: { benefits }
          }
        }
      } = await refetch();
      setBenefits({ ...benefits, benefits: benefits });
    } catch (error) {
      setTimeout(() => {
        Alert.alert(
          "Tuvimos un problema",
          "No se pudieron recargar los beneficios."
        );
      }, 1000);
      console.error(
        `Problema recargando los beneficios del usuario ${JSON.stringify(
          error
        )}`
      );
    }
  }, []);

  return useMemo(
    () => ({
      benefits,
      loading,
      refetch: _refetch
    }),
    [benefits, loading, _refetch]
  );
};

const query = gql`
  query getBenefits($lang: String) {
    me {
      profile_app {
        benefits(lang: $lang) {
          id
          name
          used
          icon {
            uri
          }
          category(lang: $lang) {
            id
            name
            icon {
              uri
            }
          }
          commerce {
            name
            longitude
            latitude
            city_hall {
              address
              name
            }
          }
        }
      }
    }
  }
`;

export default useMyBenefits;
