import DesktopCard from "./DesktopCard";
import MobileCard from "./MobileCard";

const JuradoCard: React.FC<{ jurado: any }> = ({ jurado }) => {
  return window.outerWidth > 576 ? (
    <DesktopCard jurado={jurado} />
  ) : (
    <MobileCard jurado={jurado} />
  );
};

export default JuradoCard;
