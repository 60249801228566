import { useEffect, useState } from "react";

interface ConvoItems {
  items: {
    id: number;
    type: string;
    title: string;
  }[];
}

const useFormatFile = (param: object | string | undefined) => {
  var type = param;
  if (Array.isArray(param)) {
    param.map(index => {
      type = index.type;
    });
  }
  const [formatoDescarga, setFormatoDescarga] = useState<string | undefined>();
  useEffect(() => {
    if (type == "document") {
      setFormatoDescarga(".docx, .pdf, .xlsx");
    } else if (type == "image") {
      setFormatoDescarga(".jpg, .jpeg, .png, .gif, .svg");
    } else if (type == "video") {
      setFormatoDescarga(".mp4, .mov, .webm, .3gp");
    } else if (type == "audio") {
      setFormatoDescarga(".mp3");
    } else if (type == "link") {
      setFormatoDescarga("link de youtube");
    }
  }, [type]);

  return formatoDescarga;
};

export default useFormatFile;
