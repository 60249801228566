import useFirebaseEvent from "hooks/useFirebaseEvent";

const useMultipleRecessFirebaseEvent = (id_recreo_multiple: string | null) => {
  useFirebaseEvent<"se_abrio_recreo_multiple">({
    eventName: "se_abrio_recreo_multiple",
    onMount: true,
    eventData: {
      id_recreo_multiple
    }
  });
};

export default useMultipleRecessFirebaseEvent;
