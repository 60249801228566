import { useEffect, useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Propuesta from "typedef/Propuesta";

export type usePropuestasReturnType = [Array<Propuesta | boolean>, boolean];

const usePropuestas = (id: string, search: string) => {
  const [propuestas, setPropuestas] = useState<Array<Propuesta>>([]);
  const [filteredPropuestas, setFilteredPropuestas] = useState<
    Array<Propuesta>
  >([]);
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: proposals_list => {
      setPropuestas(proposals_list.proposals_list);
    },
    onError: error => {
      console.error(
        "Parece que tuvimos un problema cargando las Convocatorias Abiertas",
        error
      );
    },
    variables: {
      annoucement_id: parseInt(id)
    }
  });

  useEffect(() => {
    setFilteredPropuestas(
      propuestas.filter(propuesta => {
        return propuesta.about.toLowerCase().includes(search.toLowerCase());
      })
    );
    // propuestas.map((propuesta: Propuestas) => {
    //   if (propuesta.about.toLowerCase().includes(search.toLowerCase())) {
    //     setFilteredPropuestas(filteredPropuestas => filteredPropuestas.concat(propuesta))
    //   }
    // })
  }, [search, propuestas]);
  return useMemo(
    () => ({
      propuestas: filteredPropuestas,
      loading
    }),
    [loading, filteredPropuestas]
  );
};
const query = gql`
  query($annoucement_id: Int!) {
    proposals_list(annoucement_id: $annoucement_id) {
      id
      user {
        first_name
        last_name
      }
      annoucement {
        title
      }
      about
      description
      votes
      hasVotedByUser
      multimedia {
        path
        type
      }
    }
  }
`;
export default usePropuestas;
