import React, { ReactElement, useEffect, useState } from "react";
import TabTitle from "./TabTitle";
import classes from "./index.module.scss";
import useSearchQuery from "../../hooks/useSearchQuery";
import Icon from "../../assets/icons/index";
import BTNViewInTheMap from "./../../components/BTNViewInTheMap";

type Props = {
  children: ReactElement[];
  position?: string;
  hasSearch?: boolean;
  searchBar?: string;
  moveTabTo?: number;
  colorHasGray?: boolean; // apply boostrap color
  isView?: boolean; // si quiero mostrar el tab o no.
};

const Tabs: React.FC<Props> = ({
  children,
  position,
  hasSearch,
  searchBar,
  colorHasGray,
  moveTabTo,
  isView
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  useEffect(() => {
    moveTabTo ? setSelectedTab(moveTabTo) : setSelectedTab(selectedTab);
  }, [moveTabTo]);

  const searchQuery = useSearchQuery();
  const [focus, setFocus] = useState(false);

  return (
    <div className={`container-fluid p-0`}>
      <div
        className={`${
          isView === false
            ? "d-none"
            : `container-fluid scrollable overflow-auto ${classes.tabs} ${
                hasSearch ? "d-flex align-items-center" : ""
              } ${colorHasGray ? "bg-transparent" : "bg-white"}`
        } `}>
        <ul className={`container d-flex list-unstyled  ${position}`}>
          {children.map(
            (item, index) =>
              item?.props?.title && (
                <TabTitle
                  key={index}
                  title={item.props.title}
                  index={index}
                  setSelectedTab={setSelectedTab}
                  active={selectedTab === index}
                />
              )
          )}
        </ul>
        {hasSearch ? (
          <div className={classes.searchFormWrapper}>
            <form>
              <div className='d-flex flex-row align-items-center'>
                <label className={`search input ${focus ? "focus" : ""}`}>
                  <input
                    defaultValue={searchQuery.get("busqueda") || ""}
                    className='flex-fill'
                    name='busqueda'
                    onChange={e => {
                      e.target.value ? setFocus(true) : setFocus(false);
                    }}
                    placeholder={searchBar}
                  />
                  <Icon className='' custom='Search' size={15} />
                  <Icon
                    className={focus ? "clear visible" : "clear"}
                    custom='Cross'
                  />
                </label>
              </div>
            </form>
          </div>
        ) : null}
      </div>
      {children[selectedTab]}
    </div>
  );
};

export default Tabs;
