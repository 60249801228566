import useTermsAndConditions from "./useTermsAndConditions";
import Loader from "assets/images/loader.svg";
import classes from "./../classes.module.scss";

export type termsAndCoditionsProps = {
  userId: string;
};

const TermsAndConditions: React.FC<termsAndCoditionsProps> = ({ userId }) => {
  const { text, loading } = useTermsAndConditions();
  let formatText = text.split("\n");
  let termsAndCondition = formatText.slice(6, text.length);

  return (
    <>
      {loading ? (
        <div className='d-flex justify-content-center align-items-center'>
          <img src={Loader} />
        </div>
      ) : (
        <div
          className={`bg-white p-4 ounded mr-auto ml-auto ${classes.termsAndConditions}`}>
          <p className='text-center'>
            Para utilizar ReCréditos, tenés que leer y aceptar nuestros términos
            y condiciones.
          </p>
          <div className='d-flex justify-content-center align-items-center my-2'>
            <button
              onClick={e => {
                e.preventDefault();
                localStorage.setItem("acceptTerm&condUser" + userId, "true");
                window.location.reload();
              }}
              className='secondary secondary--magenta'>
              Aceptar y continuar
            </button>
            <a className='p-3' href='/'>
              Aceptaré mas tarde
            </a>
          </div>

          <hr className='my-4' />
          <div className='text-left'>
            <p>
              <b>Términos y Condiciones</b>
            </p>
            {/*despliego el parrafo letra por letra, si alguna de las primeros 5 letras es un numero, lo pongo en Blod */}
            {termsAndCondition.map((paragraph, i) => {
              return (
                <p className='my-3' key={i}>
                  {paragraph.split("").map((letter, i) => {
                    if ((Number(letter) || letter === "0") && i < 5) {
                      return <b>{letter}</b>;
                    } else {
                      return letter;
                    }
                  })}
                </p>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default TermsAndConditions;
