import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import validate from "./validate";
import { Link, useHistory } from "react-router-dom";
import CrossedEye from "../../assets/images/crossed-eye.svg";
import Eye from "../../assets/images/eye.svg";
import classes from "../Login/style/index.module.scss";
import "./style.scss";
import DangerIcon from "../../assets/images/alert.svg";
import { validatePassword } from "../../helpers";
import useScrollToTop from "hooks/useScrollToTop";
import useLangContext from "hooks/useLangContext";

const PasswordRecoveryCode = () => {
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const { lang } = useLangContext();
  const [showPass, setShowPass] = useState(false);
  const [showConfirmationPass, setShowConfirmationPass] = useState(false);
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const history = useHistory();
  useScrollToTop();
  const [changePassword, { loading, data, error }] = useMutation(
    gql`
        mutation changePassword ($token: String!, $password: String!, $password_confirmation: String!) {
            recovery_password (
                email:"${history.location.state}",
                token: $token,
                password: $password,
                password_confirmation: $password_confirmation
            )
        }
    `,
    {
      onError: error => {
        if (error.message === "validation") {
          alert("El código ingresado no es válido");
        } else {
          alert(
            `Parece que tuvimos un problema al reestablecer su contraseña: ${JSON.stringify(
              error.message
            )}`
          );
        }
      }
    }
  );

  const sendChangePassword = (
    /** @type {React.MouseEvent<HTMLButtonElement, MouseEvent>} */ e
  ) => {
    e.preventDefault();

    changePassword({
      variables: {
        token,
        password,
        password_confirmation
      }
    });
  };

  useEffect(() => {
    if (data) {
      if (data.recovery_password) {
        if (
          window.confirm(
            "Listo! Ya hemos cambiado la contraseña de tu cuenta con éxito "
          )
        ) {
          history.push("/login");
        }
      } else {
        alert(
          "Tuvimos un problema Parece que hemos tenido un problema desconocido. Por favor, intenta nuevamente más tarde."
        );
      }
    } else if (error) {
      alert(`Tuvimos un problema El servidor nos dice que:  ${error}`);
    }
  }, [data]);

  return (
    <div className='container-full bg-white py-5'>
      <div className='reset-password container bg-white'>
        <h5 className='text-secondary text-center bold title'>
          {lang.CreateAccount.recoverPassword}
        </h5>
        <h6 className='text-muted text-center col-lg-4 p-0 m-auto'>
          {lang.CreateAccount.copyCode}
        </h6>

        <form
          className='col-lg-6 col-sm-12 col-12 mx-auto'
          autoComplete='new-password'>
          <div className='codigo'>
            <input
              required
              type='text'
              name='token'
              className='code'
              placeholder='Código'
              defaultValue={token}
              readOnly
              onFocus={({ currentTarget }) =>
                currentTarget.hasAttribute("readonly")
                  ? currentTarget.removeAttribute("readonly")
                  : null
              }
              onChange={({ currentTarget }) => setToken(currentTarget.value)}
            />
          </div>

          <div className='password'>
            <label>
              <img
                onClick={() => setShowPass(prev => !prev)}
                src={showPass ? CrossedEye : Eye}
              />
              <input
                required
                minLength={8}
                className={`password ${classes.password}`}
                name='password'
                defaultValue={password}
                autoComplete='off'
                type={showPass ? "text" : "password"}
                pattern='^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$'
                placeholder={lang.CreateAccount.createAPass}
                onChange={({ currentTarget }) =>
                  setPassword(currentTarget.value)
                }
              />
            </label>
            {}

            {password && !validatePassword(password) ? (
              <p className='info-text'>
                <img className='mr-2' src={DangerIcon} />
                La contraseña ingresa no es valida
              </p>
            ) : (
              <p className='info-text'>{lang.CreateAccount.passwordHelper}</p>
            )}
          </div>
          <div className='check-password password'>
            <label>
              <img
                onClick={() => setShowConfirmationPass(prev => !prev)}
                src={showConfirmationPass ? CrossedEye : Eye}
              />
              <input
                required
                className='password'
                name='passwordConfirmation'
                type={showConfirmationPass ? "text" : "password"}
                placeholder={lang.CreateAccount.passworRepeat}
                defaultValue={password_confirmation}
                onChange={({ currentTarget }) =>
                  setPasswordConfirmation(currentTarget.value)
                }
              />
            </label>

            {password_confirmation !== password ? (
              <p className='info-text'>
                <img className='mr-2' src={DangerIcon} />{" "}
                {lang.CreateAccount.passwordRule}
              </p>
            ) : (
              <p className='info-text'>{lang.CreateAccount.passwordRule}</p>
            )}
          </div>
          <button
            disabled={!validate(token, password, password_confirmation)}
            onClick={e => sendChangePassword(e)}
            className='secondary secondary--magenta mx-auto'
            type='submit'>
            {lang.Elements.btnContinue}
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordRecoveryCode;
