import * as React from "react";

function SvgGroup(props) {
  return (
    <svg
      viewBox='0 0 75 75'
      width={75}
      height={75}
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g
        color='currentColor'
        fill='currentColor'
        stroke='currentColor'
        transform='translate(-.78 -.6)'
        fill='#8C4091'
        fillRule='evenodd'>
        <path
          color='currentColor'
          fill='currentColor'
          stroke='currentColor'
          d='M37.5 42c5.51 0 10-5.38 10-12s-4.49-12-10-12-10 5.38-10 12 4.5 12 10 12zm0-21c3.87 0 7 4 7 9s-3.13 9-7 9-7-4-7-9 3.13-9 7-9z'
        />
        <path
          color='currentColor'
          fill='currentColor'
          stroke='currentColor'
          d='M46.65 41a1.507 1.507 0 00-.3 3 9.74 9.74 0 018.58 9H20.07a9.82 9.82 0 018.58-9 1.507 1.507 0 00-.3-3C28.24 41 17 42.28 17 54.49a1.5 1.5 0 001.5 1.5h38a1.5 1.5 0 001.5-1.5C58 42.28 46.76 41 46.65 41z'
        />
        <circle
          color='currentColor'
          fill='currentColor'
          stroke='currentColor'
          opacity={0.107}
          cx={38.28}
          cy={38.1}
          r={37.5}
        />
      </g>
    </svg>
  );
}

export default SvgGroup;
