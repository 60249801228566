import dayjs from "dayjs";

const useDateConvo = (state, close_date, close_date_vote, open_date_vote) => {
  var completeDateMessage =
    state === "opened" ? (
      <p>
        <strong>
          Abierta hasta el día {dayjs(close_date).locale("es").format("DD")}
          {"/"}
          {dayjs(close_date).locale("es").format("MM")}
          {"/"}
          {dayjs(close_date).locale("es").format("YYYY")}
        </strong>
      </p>
    ) : state === "in_evaluation" || state === "closed" ? (
      <p>
        <strong>
          Las votaciones inician el día{" "}
          {dayjs(open_date_vote).locale("es").format("DD")}
          {"/"}
          {dayjs(open_date_vote).locale("es").format("MM")}
          {"/"}
          {dayjs(open_date_vote).locale("es").format("YYYY")}
        </strong>
      </p>
    ) : state === "in_voting" ? (
      <p>
        <strong>
          En votación hasta el día{" "}
          {dayjs(close_date_vote).locale("es").format("DD")}
          {"/"}
          {dayjs(close_date_vote).locale("es").format("MM")}
          {"/"}
          {dayjs(close_date_vote).locale("es").format("YYYY")}
        </strong>
      </p>
    ) : state === "closed_vote" || state === "winners_selected" ? (
      <p>
        <strong>
          Esta convocatoria está en estado de revisión. En breve se publicarán
          los resultados.
        </strong>
      </p>
    ) : state === "finished" ? (
      <p>
        <strong>
          Finalizó el día {dayjs(close_date_vote).locale("es").format("DD")}
          {"/"}
          {dayjs(close_date_vote).locale("es").format("MM")}
          {"/"}
          {dayjs(close_date_vote).locale("es").format("YYYY")}
        </strong>
      </p>
    ) : null;
  return completeDateMessage;
};

export default useDateConvo;
