import * as React from "react";

function SvgMoreVertical(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      viewBox='0 0 24 24'
      height={24}
      fill='none'
      stroke='currentColor'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
      className='more-vertical_svg__feather more-vertical_svg__feather-more-vertical'
      {...props}>
      <circle cx={12} cy={12} r={1.7} />
      <circle cx={12} cy={5} r={1.7} />
      <circle cx={12} cy={19} r={1.7} />
    </svg>
  );
}

export default SvgMoreVertical;
