import { useState } from "react";

const AccordionConvo: React.FC<{ convo: any }> = ({ convo }) => {
  const [requisitos, setRequisitos] = useState(true);
  const [terminos, setTerminos] = useState(true);
  const [premios, setPremios] = useState(true);
  return (
    <div className='container-accordion mt-3'>
      <div key={0} className={requisitos ? "item active mb-1" : "item mb-1"}>
        <button
          className='accordion'
          onClick={() => setRequisitos(!requisitos)}>
          <p className='font-weight-bold'>
            Requisitos <span></span>
          </p>
        </button>
        <div className='panel small text-muted description overflow-hidden '>
          <p key={0}>{convo.requirements}</p>
        </div>
      </div>
      <div key={1} className={premios ? "item active mb-1 " : "item mb-1"}>
        <button className='accordion' onClick={() => setPremios(!premios)}>
          <p className='font-weight-bold'>
            Premios <span></span>
          </p>
        </button>
        <div className='panel small text-muted description overflow-hidden '>
          <p key={2}>{convo.prize}</p>
        </div>
      </div>
      <div key={2} className={terminos ? "item active mb-1 " : "item mb-1"}>
        <button className='accordion' onClick={() => setTerminos(!terminos)}>
          <p id='TermsAndConditions' className='font-weight-bold'>
            Términos y Condiciones <span></span>
          </p>
        </button>
        <div className='panel small text-muted description overflow-hidden '>
          <p key={1}>{convo.terms_and_conditions}</p>
        </div>
      </div>
    </div>
  );
};

export default AccordionConvo;
