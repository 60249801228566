import useCircuit from "./useCircuit";
import TextualHero from "components/TextualHero";
import { useState } from "react";
import DecoSeparator from "assets/images/deco-circuit.svg";
import "./style.scss";

import RecessCard from "components/RecessCard";
import useScrollToTop from "hooks/useScrollToTop";
import Map from "components/Map";
import useLangContext from "hooks/useLangContext";
import { useParams } from "react-router-dom";

const CircuitRecess = () => {
  const param = useParams<{ id: string }>();
  const [circuit, locations, loading] = useCircuit(param.id);
  const [more, setMore] = useState(false);
  const { lang } = useLangContext();

  useScrollToTop();

  return (
    <div>
      {!loading ? <TextualHero children={circuit}></TextualHero> : null}

      <div className='container-fluid py-4'>
        <div className='container p-0'>
          <div className='col-12 col-md-7 col-lg-9 mx-auto mt-5 '>
            <div className='row align-items-center mb-4'>
              <div className='row m-0'>
                {circuit.description_text &&
                circuit.description_text.length > 400 ? (
                  <>
                    {!more ? (
                      <>
                        <p className='description'>
                          {circuit.description_text?.slice(0, 320)}...
                        </p>
                        <span
                          className='text-magenta more clickable'
                          onClick={() => setMore(true)}>
                          {lang.Elements.seeMore}
                        </span>
                      </>
                    ) : (
                      <>
                        {circuit.description_text
                          ?.split("\n")
                          .map(descriptionParagraph => (
                            <p
                              key={descriptionParagraph}
                              className='description'>
                              {descriptionParagraph}
                            </p>
                          ))}
                        <span
                          className='text-magenta less clickable'
                          onClick={() => setMore(false)}>
                          {lang.Elements.seeLess}
                        </span>
                      </>
                    )}
                  </>
                ) : (
                  circuit.description_text
                    ?.split("\n")
                    .map(descriptionParagraph => (
                      <p key={descriptionParagraph} className='description'>
                        {descriptionParagraph}
                      </p>
                    ))
                )}
              </div>
              <div className='separador deco w-100 mx-auto'>
                <img
                  src={DecoSeparator}
                  className='w-100 mx-auto mt-5 mb-3 h-100'
                />
              </div>
            </div>
          </div>
          <div className='col-12 col-md-17 col-lg-9 m-auto p-0 '>
            <div className='subtitle text-secondary font-weight-bold mb-5'>
              {lang.Circuits.contain}
            </div>
            <div className='row my-5 cards d-flex justify-content-between'>
              {circuit.recess &&
                circuit.recess.map((recess, i) => {
                  return <RecessCard recess={recess} key={i} />;
                })}
            </div>
          </div>
        </div>

        <div className='mapa container-full p-0' id='mapaRecess'>
          {!loading && locations.length ? (
            <Map isVisible={true} ubications={locations} />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CircuitRecess;
