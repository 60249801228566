import { gql, useMutation } from "@apollo/client";

const useVotar = (propuestaID?: string | number) => {
  const [mutate] = useMutation(query, {
    variables: {
      proposal_id: propuestaID
    }
  });
  async function votar() {
    try {
      await mutate();
      alert("Su votación ha sido Éxitosa!");
    } catch (error) {
      console.error(
        `Parece que tuvimos un problema al votar la Propuesta, ${JSON.stringify(
          error
        )}`
      );
      alert("Parece que tuvimos un problema al votar la Propuesta");
    }
  }
  return votar;
};
const query = gql`
  mutation($proposal_id: Int!) {
    add_proposal_vote(proposal_id: $proposal_id) {
      user_id
      proposal_id
    }
  }
`;
export default useVotar;
