import * as React from "react";

function SvgCurrentLocation(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={31} height={32} {...props}>
      <g strokeWidth={1.5}>
        <path d='M15.801 9.529a6.044 6.044 0 106.044 6.044 6.05 6.05 0 00-6.044-6.044zm0 9.955a3.911 3.911 0 110-7.822 3.911 3.911 0 010 7.822z' />
        <path d='M29.668 14.507h-2.19a11.747 11.747 0 00-10.61-10.61v-2.19a1.067 1.067 0 00-2.134 0v2.19a11.747 11.747 0 00-10.61 10.61H1.223a1.067 1.067 0 000 2.134h2.901a11.748 11.748 0 0010.617 10.617v2.901a1.067 1.067 0 002.134 0v-2.844-.057a11.749 11.749 0 0010.603-10.617h2.19a1.067 1.067 0 000-2.134zM15.801 25.173c-5.302 0-9.6-4.298-9.6-9.6s4.298-9.6 9.6-9.6 9.6 4.298 9.6 9.6-4.298 9.6-9.6 9.6z' />
      </g>
    </svg>
  );
}

export default SvgCurrentLocation;
