import ES from "lang/es/";
import EN from "lang/en/";
import PT from "lang/pt/";

const storageLang = () => {
  const localLang = localStorage.getItem("langrecreo");
  if (localLang) {
    return localLang == "es"
      ? ES
      : localLang == "en"
      ? EN
      : localLang == "pt"
      ? PT
      : ES;
  } else {
    localStorage.setItem("langrecreo", "es");
    return ES;
  }
};

export default storageLang;
