import { CR_BLACK_COLOR, LIGHT_GRAY_COLOR } from "assets/colors";
import Icon, { IconName } from "assets/icons";

export type RecreoFeaturesProps = {
  features: Array<{ icon: IconName; available: boolean | undefined }>;
};

const RecreoFeatures: React.FC<RecreoFeaturesProps> = ({ features }) => {
  return (
    <div className='row'>
      {features.map(({ icon, available }, index) => (
        <div key={icon} className={index ? "border-left" : ""}>
          <Icon
            size={30}
            className='mx-3'
            strokeWidth='30'
            fill={available ? CR_BLACK_COLOR : LIGHT_GRAY_COLOR}
            custom={icon}
          />
        </div>
      ))}
    </div>
  );
};

export default RecreoFeatures;
