import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useState } from "react";
import { Alert } from "react-native";
import { useHistory } from "react-router";
import Recess from "typedef/Recess";

/**
 *  Hook para obtener los datos de un circuito
 *  @param id id del circuito del que se quieren los datos
 */
const useCircuit = (
  id: string | null
): [Partial<Recess>, Partial<Recess>, boolean, () => void] => {
  const [recess, setRecess] = useState({});
  const [locations, setLocations] = useState({});
  const { lang } = useLangContext();
  const history = useHistory();
  const { loading, refetch } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ recess_circuit_detail }) => {
      if (recess_circuit_detail) {
        setRecess(recess_circuit_detail);

        let circuitLocations = recess_circuit_detail.recess.map(
          (recessLocation: { title: any; latitude: any; longitude: any }) => {
            return {
              name: recessLocation.title,
              longitude: recessLocation.longitude,
              latitude: recessLocation.latitude
            };
          }
        );
        if (circuitLocations) {
          setLocations(circuitLocations);
        }
      } else {
        Alert.alert("Tuvimos un problema", "Parece que el circuito no existe.");
        history.goBack();
      }
    },
    onError: error =>
      console.error(
        `Parece que tuvimos un problema cargando los datos del recreo circuito ${error}`
      ),
    variables: {
      id: id,
      lang: lang.current
    }
  });

  async function _refetch() {
    const {
      data: { recess_circuit_detail }
    } = await refetch();
    setRecess(recess_circuit_detail);
  }

  return [recess, locations, loading, _refetch];
};

const query = gql`
  query getCircuit($id: String!, $lang: String) {
    recess_circuit_detail(id: $id, lang: $lang) {
      id
      title
      date_from
      score
      is_favorite
      date_to
      description_text
      published_at
      score
      reward {
        id
        name
        commerce {
          name
          address
          latitude
          longitude
          logo {
            uri
          }
        }
      }
      unpublished_at
      recess(lang: $lang) {
        id
        title
        visited
        date_from
        date_to
        visited
        is_favorite
        is_multiple
        icon {
          uri
        }
        city_hall {
          name
        }
        categories(lang: $lang) {
          id
          icon {
            uri
          }
          name
        }
        longitude
        latitude
      }
      icon {
        name
        uri
      }
      images {
        name
        uri
      }
      videos {
        name
        uri
        url
      }
    }
  }
`;

export default useCircuit;
