import { useMemo, useRef, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import useGPS from "hooks/useGPS";
import CityHall from "typedef/CityHall";
import useSearchQuery from "../../hooks/useSearchQuery";
import useSearch from "./useSearch";
import useLangContext from "hooks/useLangContext";

type QueryResponse = {
  city_hall_list: {
    data: CityHall[];
    last_page: string;
  };
};

const useCityHalls = () => {
  const page = useRef(1);
  const { lang } = useLangContext();
  const [coords] = useGPS();
  const searchQuery = useSearchQuery();
  const search = searchQuery.get("busqueda") || "";
  const [limitReached, setLimitReached] = useState(false);
  const [searchedCityHalls] = useSearch(search);
  const [cityHalls, setCityHalls] = useState<CityHall[]>([]);
  const filteredCityHalls = useMemo<CityHall[]>(() => {
    if (search) return searchedCityHalls;
    return cityHalls;
  }, [cityHalls, searchedCityHalls, search]);

  const { loading, fetchMore, refetch } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ city_hall_list: { data: cityHalls } }: QueryResponse) => {
      setCityHalls(cityHalls);
    },
    onError: error =>
      console.error(
        `Tuvimos un problema cargando los municipios para cityHall: ${error}`
      ),
    variables: {
      page: 1,
      latitude: coords?.latitude,
      longitude: coords?.longitude,
      lang: lang.current
    }
  });

  function _fetchMore() {
    if (limitReached) return;
    fetchMore({
      variables: {
        page: ++page.current
      }
    })
      .then(
        ({
          data: {
            city_hall_list: { data: cityHalls, last_page }
          }
        }) => {
          setCityHalls(prev => [...prev, ...cityHalls]);
          if (parseInt(last_page) <= page.current - 1) {
            setLimitReached(true);
          }
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema al cargar más municipios", error)
      );
  }

  function _refetch() {
    refetch({
      page: 1
    })
      .then(
        ({
          data: {
            city_hall_list: { data: cityHalls }
          }
        }) => {
          page.current = 1;
          setCityHalls(cityHalls);
          setLimitReached(false);
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema recargando los municipios", error)
      );
  }

  return useMemo(
    () => ({
      list: filteredCityHalls,
      loading,
      refetch: _refetch,
      fetchMore: _fetchMore,
      limitReached
    }),
    [filteredCityHalls, cityHalls, loading, limitReached]
  );
};

const query = gql`
  query getCityHalls(
    $page: Int
    $latitude: Float
    $longitude: Float
    $lang: String
  ) {
    city_hall_list(
      limit: 20
      page: $page
      longitude: $longitude
      latitude: $latitude
      lang: $lang
    ) {
      data {
        id
        name
        longitude
        latitude
        is_favorite
        icon {
          uri
        }
        review
      }
      current_page
      last_page
    }
  }
`;

export default useCityHalls;
