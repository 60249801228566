import { gql, useMutation } from "@apollo/client";
import RegisterData from "typedef/RegisterData";
import dayjs from "dayjs";
import { useHistory } from "react-router";

const useUpdateUser = (user: RegisterData) => {
  const history = useHistory();
  const [mutateUser] = useMutation(mutateUserQuery, {
    variables: {
      first_name: user.first_name,
      last_name: user.last_name,
      birth_day: dayjs(user.birth_day).format("YYYY-MM-DD"),
      city_hall_id: user.city_hall_id,
      locality_id: user.locality_id,
      country: user.country,
      state: user.state,
      phone: `${user.phone_prefix}${user.phone}`,
      document_number: user.document_number,
      total_family_group: user.total_family_group,
      total_children: user.total_children
    }
  });

  const [mutateTags] = useMutation(mutateTagsQuery, {
    variables: {
      tag_ids: user.tag_ids
    }
  });

  async function updateData() {
    try {
      await mutateUser();
      await mutateTags();
      alert(
        "Perfil guardado con éxito. La información de tu perfil se guardó con éxito."
      );
      window.location.reload();
    } catch (error) {
      console.error(
        `Parece que tuvimos un problema al editar datos del usuario, ${JSON.stringify(
          error
        )}`
      );
      alert(
        "Parece que tuvimos un problema. No pudimos editar los datos del usuario en este dayjso. Por favor intentalo más tarde."
      );
    }
  }

  return updateData;
};

const mutateUserQuery = gql`
  mutation updateUser(
    $first_name: String!
    $last_name: String!
    $birth_day: String!
    $city_hall_id: Int!
    $locality_id: Int!
    $country: String!
    $state: String!
    $phone: String!
    $document_number: String!
    $total_family_group: Int!
    $total_children: Int!
  ) {
    update_user(
      first_name: $first_name
      last_name: $last_name
      document_type: dni
      document_number: $document_number
      birth_day: $birth_day
      phone: $phone
      state: $state
      country: $country
      terms_and_conditions: true
      total_family_group: $total_family_group
      total_children: $total_children
      city_hall_id: $city_hall_id
      locality_id: $locality_id
      tag_ids: ["1", "2"]
    ) {
      email
    }
  }
`;

const mutateTagsQuery = gql`
  mutation saveTags($tag_ids: [String]!) {
    update_favorite_recess_categories(tag_ids: $tag_ids) {
      id
    }
  }
`;

export default useUpdateUser;
