const Ubicacion = props => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={27}
      height={27}
      viewBox='0 6 35 30'
      {...props}>
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.9082 17.0909C7.08805 9.93682 12.845 4.17985 19.9991 4C27.1532 4.17985 32.9102 9.93682 33.09 17.0909C32.9597 19.2083 32.3663 21.2714 31.3518 23.1345L31.25 23.3091C31.1773 23.44 29.3737 26.3491 27.2718 29.2582C23.2646 34.8945 21.57 36 19.9991 36C18.9955 36 16.6318 36 8.71911 23.3164L8.61729 23.1345C7.61296 21.269 7.02958 19.2061 6.9082 17.0909ZM19.9991 6.66771C25.6635 6.84476 30.2189 11.3847 30.4207 17.0428C30.3011 18.7178 29.8263 20.3486 29.0276 21.8265L28.9325 21.9896L28.9189 22.014C28.9229 22.0069 28.9081 22.0306 28.8756 22.0828L28.8752 22.0834C28.7513 22.2822 28.3717 22.8912 27.7888 23.7843C27.0806 24.8693 26.1289 26.2867 25.1103 27.6965L25.1044 27.7047L25.0985 27.713C23.1178 30.4989 21.8403 31.9706 20.9657 32.736C20.5466 33.1027 20.2935 33.2408 20.1744 33.2924C20.0847 33.3313 20.0473 33.3333 19.9991 33.3333C19.963 33.3333 19.943 33.3336 19.9324 33.3339C19.9337 33.3336 19.934 33.333 19.9322 33.3323C19.8398 33.2946 19.4713 33.1174 18.7657 32.4615C17.3292 31.126 14.9193 28.2115 11.0149 21.9582L10.9553 21.8519C10.1592 20.3667 9.68951 18.729 9.57733 17.0484C9.77629 11.3877 14.3329 6.84482 19.9991 6.66771ZM16.8476 16.3636C16.8476 14.6231 18.2586 13.2121 19.9991 13.2121C21.7396 13.2121 23.1506 14.6231 23.1506 16.3636C23.1506 18.1042 21.7396 19.5151 19.9991 19.5151C18.2586 19.5151 16.8476 18.1042 16.8476 16.3636ZM19.9991 10.5455C16.7858 10.5455 14.1809 13.1503 14.1809 16.3636C14.1809 19.5769 16.7858 22.1818 19.9991 22.1818C23.2124 22.1818 25.8173 19.5769 25.8173 16.3636C25.8173 13.1503 23.2124 10.5455 19.9991 10.5455Z'
        fill='#8B2C75'
      />
    </svg>
  );
};

export default Ubicacion;
