import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import BellIcon from "../../assets/images/bell-icon.svg";
import useLangContext from "hooks/useLangContext";

const useCategories = () => {
  const [categories, setCategories] = useState([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ recess_category_list: { data: categories } }) =>
      setCategories([{ id: -1, name: "Todos" }, ...categories]),
    onError: error =>
      console.error(
        "Tuvimos un problema al cargar las categorías para la pantalla de notificaciones.",
        error
      )
  });

  return [categories, loading];
};

const query = gql`
  query getCategories($lang: String) {
    recess_category_list(lang: $lang) {
      data {
        id
        name
        icon {
          uri
        }
      }
    }
  }
`;

export default useCategories;
