const SvgCalendar = (
  /** @type {JSX.IntrinsicAttributes & import("react").SVGProps<SVGSVGElement>} */ props
) => {
  return (
    <svg
      width={80}
      height={80}
      viewBox='0 0 80 80'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path d='M26.89 0a2.75 2.75 0 012.75 2.75l-.001 4.745h18.588V2.75A2.75 2.75 0 0150.978 0h.027a2.75 2.75 0 012.75 2.75l-.001 4.745h20.056c1.02 0 1.847.243 2.479.73.991.58 1.516 1.507 1.574 2.783l.006.244v62.506c0 2.21-.736 3.505-2.209 3.883-.626.86-1.575 1.317-2.847 1.37l-.259.006H5.583c-1.753 0-2.967-.403-3.645-1.208C.646 77.344 0 76.073 0 73.995v-62.73c0-2.51 1.675-3.764 5.024-3.764l-.001.18c.522-.124 1.127-.186 1.815-.186h17.275V2.75A2.75 2.75 0 0126.864 0h.027zm-2.777 12.518H5.023v61.475h67.821V12.518H53.753v1.735a2.75 2.75 0 01-2.75 2.75h-.025a2.75 2.75 0 01-2.75-2.75l-.001-1.735H29.639l.001 1.735a2.75 2.75 0 01-2.75 2.75h-.026a2.75 2.75 0 01-2.75-2.75l-.001-1.735zm38.188 35.99a1.5 1.5 0 011.5 1.5v7.002a1.5 1.5 0 01-1.5 1.5h-7.047a1.5 1.5 0 01-1.5-1.5v-7.002a1.5 1.5 0 011.5-1.5H62.3zm-19.592 0a1.5 1.5 0 011.5 1.5v7.002a1.5 1.5 0 01-1.5 1.5H35.66a1.5 1.5 0 01-1.5-1.5v-7.002a1.5 1.5 0 011.5-1.5h7.048zm-19.593 0a1.5 1.5 0 011.5 1.5v7.002a1.5 1.5 0 01-1.5 1.5H16.07a1.5 1.5 0 01-1.5-1.5v-7.002a1.5 1.5 0 011.5-1.5h7.047zm0-19.003a1.5 1.5 0 011.5 1.5v7.002a1.5 1.5 0 01-1.5 1.5H16.07a1.5 1.5 0 01-1.5-1.5v-7.002a1.5 1.5 0 011.5-1.5h7.047zm19.593 0a1.5 1.5 0 011.5 1.5v7.002a1.5 1.5 0 01-1.5 1.5H35.66a1.5 1.5 0 01-1.5-1.5v-7.002a1.5 1.5 0 011.5-1.5h7.048zm19.592 0a1.5 1.5 0 011.5 1.5v7.002a1.5 1.5 0 01-1.5 1.5h-7.047a1.5 1.5 0 01-1.5-1.5v-7.002a1.5 1.5 0 011.5-1.5H62.3z' />
    </svg>
  );
};

export default SvgCalendar;
