import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useSearchQuery = () => {
  const location = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(location.search), [location]);

  query.append = useCallback((name: string, value: string) => {
    const currentQuery = new URLSearchParams(location.search);
    currentQuery.append(name, value);
    history.push({ search: currentQuery.toString() });
  }, []);

  query.delete = useCallback((name: string) => {
    const currentQuery = new URLSearchParams(location.search);
    currentQuery.delete(name);

    history.push({ search: currentQuery.toString() });
  }, []);

  return useMemo(() => query, [location]);
};

export default useSearchQuery;
