import * as React from "react";

function SvgLuggageCircle(props) {
  return (
    <svg
      width={27}
      height={27}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 30 30'
      {...props}>
      <g transform='translate(1 1)' fill='none' fillRule='evenodd'>
        <g transform='translate(7 5)' fill='#666'>
          <path d='M9.808 1.452h-2.28a2.188 2.188 0 00-4.017 0H1.228a.99.99 0 00-.99.99v10.23c0 .547.443.99.99.99h8.58a.99.99 0 00.99-.99V2.442a.99.99 0 00-.99-.99zm-4.29-.33c.312-.01.616.11.838.33H4.693c.219-.217.516-.336.825-.33zm4.29 11.55h-8.58V2.442h8.58v10.23z' />
          <circle cx={3.208} cy={14.157} r={1} />
          <circle cx={7.828} cy={14.157} r={1} />
          <path d='M2.218 3.432h.66v8.25h-.66zM4.198 3.432h1v8.25h-1zM6.178 3.432h1v8.25h-1zM8.158 3.432h1v8.25h-1z' />
        </g>
        <circle
          cx={12.65}
          cy={12.65}
          r={12.65}
          stroke='#666'
          strokeWidth={0.55}
          transform='translate(1 .6)'
        />
      </g>
    </svg>
  );
}

export default SvgLuggageCircle;
