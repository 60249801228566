export default {
  title: "Destinos Cuidados",
  parrapha1: "Sempre use máscara",
  parrapha2: "Mantenha o distanciamento social ( mínimo de 2 metros)",
  parrapha3: "Evite lugares cheios",
  parrapha4:
    "Preste atenção às recomendações dos prestadores turísticos para prevenir o contágio",
  parrapha5: "Prefira atividades ao ar livre",
  parrapha6:
    "Se você apresenta algum deste sintomas, entre em contato com as autoridades locais de saúde: 37° graus de temperatura, perda de olfato e paladar, dor de garganta ou falta de ar",
  parrapha7:
    "Antes de viajar, consulte as fontes de informação local e siga suas recomendações",
  parrapha8:
    "Lembre-se de levar álcool em gel, desinfetante de mãos e/ou toalhas ou lenços descartáveis",
  vaccLink: "Consultar postos de vacinação e centros de teste"
};
