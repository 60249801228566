import { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import BenefitCategory from "typedef/BenefitCategory";
import useLangContext from "hooks/useLangContext";

const useCategories = () => {
  const [categories, setCategories] = useState<BenefitCategory[]>([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ benefit_category_list: { data } }) => setCategories(data),
    onError: error =>
      console.error(
        "Parece que tuvimos un problema cargando las categorías de beneficios",
        error
      )
  });

  return useMemo(
    () => ({
      list: categories,
      loading
    }),
    [categories, loading, lang]
  );
};

const query = gql`
  query getCategories($lang: String) {
    benefit_category_list(lang: $lang) {
      data {
        id
        name
        icon {
          uri
        }
      }
    }
  }
`;

export default useCategories;
