export default {
  seeMore: "More",
  seeLess: "Less",
  btnViewMap: "SEE IN THE MAP",
  btnReturn: "Return",
  btnContinue: "Continue",
  btnSend: "Send",
  btnDelete: "Remove",
  bookTickets: "Book Ticket",
  save: "Save",
  all: "All",

  shareTextUp: "Share this content",
  shareTextDown: "Copy this link"
};
