/**
 * @param {{ recessCategories: any[]; benefitsCategories: any[]; cityHalls: any[]; }} configs
 */
export default function reducer(configs, { type, payload }) {
  switch (type) {
    case "setInitialConfig":
      return { ...configs, ...payload };
    case "setRecessCategories":
      const {
        categoryId: recessCategoryId,
        seted: recessCategorySeted
      } = payload;
      const recessCategories = [
        ...(configs?.recessCategories.filter(
          id => parseInt(id) !== parseInt(recessCategoryId)
        ) || [])
      ];
      if (recessCategorySeted) recessCategories.push(recessCategoryId);
      return { ...configs, recessCategories };
    case "setEveryRecessCategory":
      const {
        seted: recessAllSeted,
        categories: allRecessCategories
      } = payload;
      let _recessCategories = [];
      if (recessAllSeted)
        _recessCategories = allRecessCategories.map(({ id }) => id);
      return { ...configs, recessCategories: _recessCategories };
    case "setBenefitsCategories":
      const {
        categoryId: benefitCategoryId,
        seted: benefitCategorySeted
      } = payload;
      const benefitsCategories = [
        ...(configs?.benefitsCategories.filter(
          id => parseInt(id) !== parseInt(benefitCategoryId)
        ) || [])
      ];
      if (benefitCategorySeted) benefitsCategories.push(benefitCategoryId);
      return { ...configs, benefitsCategories };
    case "setEveryBenefitsCategory":
      const {
        seted: benefitAllSeted,
        categories: allBenefitCategories
      } = payload;
      let _benefitsCategories = [];
      if (benefitAllSeted)
        _benefitsCategories = allBenefitCategories.map(({ id }) => id);
      return { ...configs, benefitsCategories: _benefitsCategories };
    case "addCityHall":
      return { ...configs, cityHalls: [...configs.cityHalls, undefined] };
    case "deleteCityHall":
      return {
        ...configs,
        cityHalls: configs.cityHalls.filter((_, index) => index !== payload)
      };
    case "updateCityHall":
      const { index, newId } = payload;
      return {
        ...configs,
        cityHalls: configs.cityHalls.map((id, i) => (i === index ? newId : id))
      };
    case "enableRecessCategories":
      return { ...configs, recessCategoriesEnabled: payload };
    case "enableBenefitsCategories":
      return { ...configs, benefitsCategoriesEnabled: payload };
    case "enableCityHalls":
      return { ...configs, cityHallsEnabled: payload };
    case "enableGeoNotifications":
      return { ...configs, geoNotificationsEnabled: payload };
    default:
      return configs;
  }
}
