import React from "react";
import { returnDefaultImg } from "helpers";
import { LIGHT_GRAY, MAGENTA } from "assets/colors";
import Youtube from "assets/images/youtube.svg";
import Twitter from "assets/images/twitter-logo.svg";
import Facebook from "assets/images/facebook-logo.svg";
import Instagram from "assets/images/instagram-logo.svg";
import validURL from "./validURL";

const DesktopCard: React.FC<{ jurado: any }> = ({ jurado }) => {
  return (
    <div className='container row py-3'>
      <div className='col-md-4 col-sm-12'>
        <div className='d-flex justify-content-md-end justify-content-sm-start'>
          <img
            src={returnDefaultImg(jurado.photo)}
            width={130}
            height={130}
            className='rounded my-3'
          />
        </div>
      </div>
      <div className='col-md-8 p-0'>
        <div className={`card-body pr-5 mt-2`}>
          <h5 className='card-title font-weight-bold'>
            {jurado.first_name} {jurado.last_name}
          </h5>
          <p
            className='card-title font-weight-bold mb-0'
            style={{ color: LIGHT_GRAY }}>
            {jurado.title_role}
          </p>
          <p className='card-title font-weight-bold m-0'>Ente u organismo</p>
          <p className='card-title'>{jurado.city_hall?.name}</p>
          <p className='card-text'>{jurado.description}</p>
          <div
            className='d-flex justify-content-between'
            style={{ maxWidth: 260 }}>
            {jurado.url_instagram && (
              <a className='m-1' href={jurado.url_instagram} target='_blank'>
                <img src={Instagram} />
              </a>
            )}
            {jurado.url_twitter && (
              <a className='m-1' href={jurado.url_twitter} target='_blank'>
                <img src={Twitter} />
              </a>
            )}
            {jurado.url_facebook && (
              <a className='m-1' href={jurado.url_facebook} target='_blank'>
                <img src={Facebook} />
              </a>
            )}
            {jurado.url_youtube && (
              <a className='m-1' href={jurado.url_youtube} target='_blank'>
                <img src={Youtube} />
              </a>
            )}

            {validURL(jurado.know_more) ? (
              <p className='m-2 d-flex align-items-center'>
                <a
                  style={{ color: MAGENTA }}
                  href={jurado.know_more}
                  target='_blank'>
                  Más Información
                </a>
              </p>
            ) : (
              <p className='mt-3'>
                <a
                  style={{ color: MAGENTA, cursor: "pointer" }}
                  onClick={() => alert("url invalida")}>
                  Más Información
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DesktopCard;
