import "dayjs/locale/es";
import useAmenities from "./useAmentities";
import RecessCard from "components/RecessCard";

const Amenities = () => {
  const amenities = useAmenities();

  return (
    <div className='container-full amenities d-flex flex-row overflow-auto align-items-center justify-content-between scrollable mt-3'>
      {amenities.list.map(amenity => (
        <RecessCard isSmallCard={true} recess={amenity} key={amenity.id} />
      ))}
    </div>
  );
};

export default Amenities;
