type ConvoFilterUtilProps = {
  activeIndex: number;
  setFilterIndex: (value: number) => void;
};

const ConvoFilterUtil: React.FC<ConvoFilterUtilProps> = ({
  activeIndex,
  setFilterIndex
}) => {
  return (
    <div className='bg-white'>
      <h5 className='pr-4 pl-4 pt-3'>
        <small>Ordenar por</small>
      </h5>
      <div className='d-flex flex-row p-3 rounded scrollable'>
        <button
          onClick={() => setFilterIndex(0)}
          name='filtro'
          value={activeIndex}
          className={`secondary secondary--purple purpleHover mr-2  ${
            activeIndex === 0 && "selected"
          }`}>
          <p className='text-nowrap' style={{ fontSize: "16px" }}>
            Fecha de finalización
          </p>
        </button>
        <button
          onClick={() => setFilterIndex(1)}
          name='filtro'
          value={activeIndex}
          className={`secondary secondary--purple purpleHover mr-2 ${
            activeIndex === 1 && "selected"
          }`}>
          <p className='text-nowrap' style={{ fontSize: "16px" }}>
            Fecha de creación
          </p>
        </button>
      </div>
    </div>
  );
};

export default ConvoFilterUtil;
