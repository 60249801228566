import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";

const useNotificationsCount = () => {
  const [notificationsCount, setNotificationsCount] = useState(0);
  const { loading } = useQuery(query, {
    onCompleted: ({ notification_count: count }) => {
      setNotificationsCount(count);
    }
  });

  return useMemo(
    () => ({
      notificationsCount,
      loading
    }),
    [notificationsCount, loading]
  );
};

const query = gql`
  query getNotificationCount {
    notification_count
  }
`;

export default useNotificationsCount;
