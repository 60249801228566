import { gql, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { useState } from "react";
/**
 * @param {string} id
 * */
const useGanadores = (id: string) => {
  const [ganadores, setGanadores] = useState([]);
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ annoucement_winners }) => {
      if (annoucement_winners) {
        setGanadores(annoucement_winners);
      } else {
        alert("Parece que los ganadores no existen.");
      }
    },
    onError: error => {
      console.log(
        "Parece que tuvimos un problema al cargar los Ganadores de la Convocatoria.",
        error
      );
      // alert("Parece que no pudimos cargar los Ganadores.");
    },
    variables: {
      annoucement_id: parseInt(id)
    }
  });
  return useMemo(
    () => ({
      loading: loading,
      ganadores: ganadores
    }),
    [ganadores, loading]
  );
};
const query = gql`
  query($annoucement_id: Int!) {
    annoucement_winners(annoucement_id: $annoucement_id) {
      id
      user {
        first_name
        last_name
        email
      }
      proposal {
        id
        about
        description
        multimedia {
          path
          type
          annoucement_item_id
        }
        user {
          first_name
          last_name
          email
        }
        votes
      }
      annoucement {
        id
        title
      }
      position
    }
  }
`;
export default useGanadores;
