import { LIGHT_BLUE } from "assets/colors";
import PropuestaPreview from "components/PropuestaPreview/Index";
import { Link } from "react-router-dom";
import Ganador from "typedef/Ganador";

const GanadorCard: React.FC<{ ganador: Ganador }> = ({ ganador }) => {
  return (
    <div className='d-flex flex-column bg-white my-3 p-2 col-md-8 col-sm-12 rounded'>
      <div className='d-flex'>
        {ganador?.proposal?.multimedia && (
          <PropuestaPreview
            size={128}
            multimedia={ganador.proposal.multimedia[0]}
          />
        )}
        <Link
          to={`/propuesta/${ganador.proposal.id}`}
          className='linkTo mx-2 my-0 d-flex justify-content-between flex-column w-100'>
          <div className=''>
            <p className=' text-muted'>
              {`${ganador.proposal.user.first_name} ${ganador.proposal.user.last_name}`}
            </p>
          </div>
          <h6 className=''>
            <strong>{ganador.proposal.description}</strong>
          </h6>

          <h6 style={{ color: LIGHT_BLUE }}>
            <strong>{`votos: ${ganador.proposal.votes}`}</strong>
          </h6>
        </Link>
      </div>
    </div>
  );
};
export default GanadorCard;
