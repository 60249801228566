export default {
  title: "Creá tu Cuenta",
  subTitle: "Elegí de qué forma registrarte",
  loginFacebook: "Conectar con Facebook",
  loginGmail: "Conectar con Gmail",
  createwithEmail: "Crear cuenta con e-mail",
  acepptTermAndCondi:
    "Al continuar con alguna de las opciones previas estás aceptando nuestros Términos y Condiciones",
  ifHaveAccount: "¿Ya tenés cuenta? ",
  registerWithEmail: "Registrate con tu e-mail",
  registerWithEmailPlaceholder: "Completá tu e-mail",
  registerWithEmailError: "El correo no es válido",
  createAPass: "Creá una contraseña",
  passwordHelper:
    "La contraseña debe incluir mínimo 8 caracteres, al menos un número y una letra mayúscula",
  passworRepeat: "Repetí contraseña",
  passwordRule: "Las contraseñas deben coincidir",
  passwordNoCorrect: "La contraseña no es correcta",

  recoverPassword: "Recuperá tu contraseña",
  copyCode:
    "Copiá y pegá el código que te enviamos por e-mail para poder crear una nueva contraseña."
};
