import { gql, useMutation, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";

const useUpdateShedule = (recess_id: string) => {
  const [isActiveShedule, setIsActiveShedule] = useState<boolean>(false);
  const [mutate, { loading }] = useMutation(mutation, {
    onCompleted: ({ update_schedule: isActive }) => {
      if (isActive) {
        alert("Se agendó este ReCreo en Mi Agenda");
      }
      setIsActiveShedule(isActive);
    },
    onError: error => console.log(error)
  });
  useQuery(query, {
    variables: { recess_id: recess_id },
    onCompleted: ({ schedule: isActive }) => {
      setIsActiveShedule(isActive);
    }
  });

  const update = () => {
    mutate({
      variables: { recess_id }
    });
  };

  return useMemo(
    () => ({
      update,
      isActiveShedule,
      loading
    }),
    [isActiveShedule, update]
  );
};

const mutation = gql`
  mutation addShedule($recess_id: String) {
    update_schedule(recess_id: $recess_id)
  }
`;

const query = gql`
  query getShedule($recess_id: String) {
    schedule(recess_id: $recess_id)
  }
`;

export default useUpdateShedule;
