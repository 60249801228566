// @ts-nocheck
import React from "react";
import CRScreen from "components/CRScreen";
import CRHeader from "components/CRHeader";
import CRCarousel from "components/CRCarousel";
import { formatExternalAssetUrl } from "helpers";
import { Dimensions, ScrollView, StyleSheet, View } from "react-native";
import CRText from "components/CRText";
import { FONT_SEMIBOLD } from "assets/fonts/constants";
import { CR_BLACK_COLOR, GRAY, LIGHT_GRAY, VIOLET } from "assets/colors";
import dayjs from "dayjs";
import QR from "components/QR";
import useTicket from "screens/MyTicket/useTicket";
import useSearchQuery from "hooks/useSearchQuery";
import useSimpleRecess from "screens/SimpleRecess/useSimpleRecess";
import CarruselHero from "components/CarruselHero/CarruselHero";
import classes from "components/TextualHero/style/index.module.scss";
import "./style.scss";
import { useHistory } from "react-router-dom";

const Ticket = () => {
  const history = useHistory();
  if (!Boolean(history.location.state?.ticket)) history.replace("/");
  const ticketDetail = history.location.state.ticket;
  const recess = history.location.state.ticket.recess;

  return (
    <>
      <div className='container-fluid bg-white p-0 overflow-hidden ticket'>
        <div className='row'>
          {recess.images ? <CarruselHero uris={recess.images} /> : null}
          <div
            className={`col-12 col-md-12 col-lg-6 col-xl-6 bg-white ${classes.textSide}`}>
            <div className='flex-wrap d-flex flex-column justify-content-between h-100 container'>
              <div className={classes.top}>
                {recess.date_from && recess.date_to ? (
                  <div>
                    <h2 className='font-weight-bold text-muted  text-capitalize'>
                      {dayjs(recess.date_from)
                        .locale("es")
                        .format("dddd DD  MMMM")}
                    </h2>
                    <h3 className='time'>
                      {dayjs(recess.date_from).format("HH:mm")} hs.
                    </h3>
                  </div>
                ) : null}
                {/* TITULO */}
                <h3 className='font-weight-bold title my-4'>{recess.title}</h3>
              </div>
              <div className={classes.bottomContent}>
                <h6 className='locality font-weight-bold'>
                  {recess.city_hall?.name}
                </h6>
                <p className='address'>{recess.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid bg-white my-5 p-3'>
        <div className='container content-block my-5'>
          <div className='row m-auto justify-content-center'>
            <div className='col-lg-3 col-md-3 col-sm-12 col-xs-12 qr-block'>
              <QR value={ticketDetail.code_qr} />
            </div>
            <div className='more-info'>
              <h6 className='sub '>Valor</h6>
              {recess.is_free ? (
                <h5 className='font-weight-bold mb-3 mt-1'>Gratis</h5>
              ) : null}
              <h6 className='sub'>Código</h6>
              <h6 className='code font-weight-bold'>{ticketDetail.code_qr}</h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ticket;
