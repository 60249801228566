import * as React from "react";

function SvgPalmTree(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={27} height={32} {...props}>
      <path d='M25.83 28.812h-5.558c0-6.669-3.416-12.124-6.277-15.562l-.126-.141a3.899 3.899 0 012.305-1.341c2.527-.437 5.232 1.571 5.276 1.601a1.112 1.112 0 001.342-1.771c-.141-.104-3.498-2.608-6.981-2.023a5.926 5.926 0 00-2.823 1.297c1.149-1.356 3.224-3.12 5.928-2.49a1.113 1.113 0 00.512-2.164c-3.172-.741-5.632.741-7.24 2.275.23-1.771.963-4.098 3.12-5.432a1.112 1.112 0 00-1.171-1.89c-3.705 2.305-4.261 6.543-4.283 8.678a11.175 11.175 0 00-8.515 3.335 1.11 1.11 0 101.571 1.571 8.968 8.968 0 016.314-2.705c-2.223.934-4.506 2.868-4.506 6.366a1.112 1.112 0 002.224 0c0-2.49 1.727-3.705 3.142-4.313-1.986 3.179-.949 8.011-.897 8.255a1.112 1.112 0 102.171-.481c-.319-1.482-.667-5.254 1.008-7.107 2.608 3.135 5.684 8.077 5.684 14.021H2.117a1.112 1.112 0 000 2.224h23.714a1.112 1.112 0 000-2.224v.022z' />
    </svg>
  );
}

export default SvgPalmTree;
