import { useEffect, useState } from "react";

export const ACCESS_TOKEN_KEY = "Asdflj1n2e2Q@!#rt4ew45";

const useAccessToken = () => {
  const [token, setToken] = useState<string | null>(null);
  useEffect(() => {
    const savedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (savedToken) setToken(savedToken);
    window.addEventListener("storage", event => {
      if (event.key === ACCESS_TOKEN_KEY) {
        setToken(event.newValue);
      }
    });
  }, []);

  return token;
};

export default useAccessToken;
