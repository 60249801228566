import useSafetyTips from "./useSafetyTips";
import "./Style.scss";
import { LIGHT_GRAY_COLOR, WHITE, LIGHT_BLUE } from "./../../assets/colors";
import Icon from "./../../assets/icons";
import useScrollToTop from "hooks/useScrollToTop";
import useLangContext from "hooks/useLangContext";

const SafeDestinations = () => {
  const safetyTips = useSafetyTips();
  const { lang } = useLangContext();
  useScrollToTop();

  return (
    <div className='safe-destinations'>
      <div className='container'>
        <h2 className='text-center font-weight-bold text-secondary my-5'>
          {lang.Navbar.header.top.destiny}
        </h2>
        <hr></hr>
        <ul>
          {safetyTips.map((item, index) => {
            return (
              <div className='item d-flex' key={item.key}>
                <Icon size={50} custom={item.icon} color={LIGHT_GRAY_COLOR} />
                <p> {lang.SafeDestiny[`parrapha${index + 1}`]}</p>
              </div>
            );
          })}
          <div
            className='vaccination-wrapper'
            style={{ backgroundColor: LIGHT_BLUE }}>
            <a
              href='https://portal-coronavirus.gba.gob.ar/'
              target='_blank'
              rel='noopener noreferrer'>
              <Icon
                custom={"Location"}
                size={25}
                fill={WHITE}
                className='mr-3 align-self-center subtitle-home'
              />
              {lang.SafeDestiny["vaccLink"]}
            </a>
          </div>
        </ul>
      </div>
    </div>
  );
};

export default SafeDestinations;
