export default {
  title: "Iniciar sessão",
  noRemind: "",
  forgotPassword: "Esqueceu sua senha?",
  loginFacebook: "Entrar com FaceBook",
  loginGmail: "Entrar com Gmail",
  loginEmail: "Entrar com e-mail",
  notLogin: "Ainda não está registrado? ",
  setPass: "Coloque sua senha",
  createAccount: "Crie sua conta"
};
