export default {
  title: "Completá tu perfil",
  personalDates: "Datos personales",
  name: "Nombre",
  lastName: "Apellido",
  codAreaTel: "Código de área y teléfono",
  document: "DNI",
  dateOfBirth: "Fecha de Nacimiento",
  province: "Provincia",
  country: "País de residencia",
  familyGropu: "Grupo Familiar",
  cantFamily: "¿Cuántos son en tu grupo familiar?",
  hasChilldren: "¿Hijos?",
  preferences: "Gustos y Preferencias",
  activities: "¿Qué actividades te gustan realizar?",
  scapeQuestion: "Cuando viajás o hacés una escapada ¿qué preferís?",
  createAccount: "Creá tu Cuenta",
  finishRegister: "¡Ya casi, falta un paso!",
  logWith: "Accedé a tu email para activar tu cuenta.",
  countrySelect: "Seleccionar Pais",
  provinceSelect: "Seleccionar Provincia",
  dateSelect: "DD/MM/AA",
  loginWithSocial: "Ya podés ingresar a tu cuenta"
};
