import useCityHalls from "./useCityHalls";
import PointIcon from "assets/images/pinpoint-title.svg";
import useGPS from "hooks/useGPS";
import Tabs from "components/Tabs/Tabs";
import Tab from "components/Tabs/Tab";
import useCircuits from "../Circuits/useCircuits";
import "./style.scss";
import useScrollToTop from "../../hooks/useScrollToTop";
import CityHallCard from "components/CityHallCard";
import Loader from "components/Loader";
import BTNViewInTheMap from "components/BTNViewInTheMap";
import Map from "components/Map/index";
import { useState } from "react";
import RecessCard from "components/RecessCard";
import ErrorMSJ from "components/ErrorMSJ";
import useLangContext from "hooks/useLangContext";

const CityHalls = () => {
  const cityHalls = useCityHalls();
  const [isVisbleMap, setisVisbleMap] = useState(false);
  const circuits = useCircuits();
  const [coords] = useGPS();
  const { lang } = useLangContext();
  useScrollToTop();

  return (
    <div className='container cityhalls'>
      <div className='d-flex align-items-center justify-content-between'>
        <div className='section-title d-flex align-items-center my-4'>
          <img src={PointIcon} className='mr-4 logo-sub-title' />
          <h2 className='text-secondary font-weight-bold'>
            {lang.Navbar.header.main.knowCityHall}
          </h2>
        </div>
        <div className={`btn-viewmap`}>
          <BTNViewInTheMap goToMap={() => setisVisbleMap(!isVisbleMap)} />
        </div>
      </div>

      <div>
        <Tabs
          position='justify-content-start'
          hasSearch={true}
          searchBar={lang.CityHalls.search}>
          <Tab title={lang.CityHalls.tabMinicipio}>
            {!cityHalls.loading && cityHalls.list && isVisbleMap ? (
              <div className='mb-5'>
                <Map
                  isVisible={isVisbleMap}
                  ubications={cityHalls.list}
                  type={"city-hall"}
                />
              </div>
            ) : null}
            {!cityHalls.loading ? (
              <>
                {cityHalls.list.length > 0 ? (
                  <div className='row justify-content-between'>
                    {cityHalls.list.map((cityHall, i) => (
                      <CityHallCard
                        key={i}
                        coords={coords}
                        index={i}
                        cityHall={cityHall}
                      />
                    ))}
                  </div>
                ) : (
                  <ErrorMSJ title={"no se encuentran Municipios disponibles"} />
                )}
                {!cityHalls.limitReached && (
                  <button
                    className='secondary secondary--magenta d-block mt-5 mx-auto'
                    onClick={() => cityHalls.fetchMore()}>
                    {lang.CityHalls.loadMore}
                  </button>
                )}
              </>
            ) : (
              <div data-testid='loader' className='d-flex m-auto text-center'>
                <Loader />
              </div>
            )}
          </Tab>
          <Tab title={lang.CityHalls.tabCircuits}>
            {circuits.loading ? (
              <div className='d-flex m-auto text-center'>
                <Loader />
              </div>
            ) : (
              <>
                {circuits.list.length > 0 ? (
                  <div className='row'>
                    {circuits.list.map((circuit, i) => (
                      <RecessCard key={i} recess={circuit} />
                    ))}
                  </div>
                ) : (
                  <ErrorMSJ title={"no se encuentran Circuitos disponibles"} />
                )}
                {circuits.limitReached && (
                  <button
                    className='secondary secondary--magenta d-block mt-5 mx-auto'
                    onClick={() => circuits.fetchMore()}>
                    Cargar más circuitos
                  </button>
                )}
              </>
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default CityHalls;
