import * as React from "react";

function Accesibility(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 31 32'
      width={31}
      height={32}
      {...props}>
      <path d='M25.804 15.693V1.803h1.069c.325 0 .589-.26.589-.581s-.264-.581-.589-.581h-1.658a.585.585 0 00-.589.581v1.755h-.203c-1.496 0-2.712 1.201-2.712 2.677v3.347h-3.149c-.325 0-.589.26-.589.581s.264.581.589.581h3.149v1.613H8.665v-.981c0-.348.287-.632.64-.632h3.931c.325 0 .589-.26.589-.581s-.264-.581-.589-.581H9.305c-1.002 0-1.818.805-1.818 1.794v1.562c0 .321.264.581.589.581h2.892v2.787H8.705c-1.63 0-2.956 1.309-2.956 2.918v5.389a3.238 3.238 0 00-2.632 2.728H1.873c-.325 0-.589.26-.589.581s.264.581.589.581h1.27l.03-.001a3.248 3.248 0 003.166 2.482c1.792 0 3.25-1.441 3.25-3.213 0-1.573-1.149-2.885-2.661-3.16v-1.811h5.808v.048c0 4.486 3.692 8.136 8.23 8.136s8.23-3.65 8.23-8.136c0-2.699-1.337-5.096-3.39-6.577zm-2.915-10.04c0-.835.688-1.515 1.534-1.515h.203v10.845a8.243 8.243 0 00-1.737-.626V5.652zm-10.742 7.286h9.565v1.228a8.244 8.244 0 00-5.636 1.563.687.687 0 00-.065-.004h-3.863v-2.787zM8.41 27.192c0 1.13-.93 2.05-2.072 2.05s-2.072-.92-2.072-2.05.93-2.05 2.072-2.05c1.143 0 2.072.92 2.072 2.05zm-1.483-6.133v-2.415c0-.968.798-1.755 1.778-1.755h6.092a8.07 8.07 0 00-1.971 4.17H6.927zm14.038 8.183c-3.888 0-7.052-3.128-7.052-6.973s3.163-6.973 7.052-6.973c3.888 0 7.052 3.128 7.052 6.973s-3.163 6.973-7.052 6.973z' />
      <path d='M20.965 17.704c-2.546 0-4.618 2.048-4.618 4.565s2.072 4.565 4.618 4.565c2.546 0 4.618-2.048 4.618-4.565s-2.072-4.565-4.618-4.565zm0 7.967c-1.897 0-3.44-1.526-3.44-3.402s1.543-3.402 3.44-3.402 3.44 1.526 3.44 3.402-1.543 3.402-3.44 3.402zM15.366 9.804a.596.596 0 00.553.359.595.595 0 00.548-.394.58.58 0 00-.184-.636.603.603 0 00-.701-.034.583.583 0 00-.217.706z' />
    </svg>
  );
}

export default Accesibility;
