import { gql, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { formatExternalAssetUrl } from "helpers";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import Recess from "typedef/Recess";
import MediaItem from "typedef/MediaItem";
import useLangContext from "hooks/useLangContext";

type QueryResponse = {
  recess_multiple_detail: Recess;
};

const useMultipleRecess = (id: string | null) => {
  const [recess, setRecess] = useState<Partial<Recess>>({ media: [] });
  const history = useHistory();
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    fetchPolicy: "no-cache",
    onCompleted: ({ recess_multiple_detail: recess }: QueryResponse) => {
      if (recess) {
        const recesses = recess.recess;
        let media: MediaItem[] = [
          ...(recess.images?.map(image => ({
            ...image,
            uri: formatExternalAssetUrl(image)
          })) || []),
          ...(recess.videos || [])
        ].map(mediaItem => ({
          ...mediaItem,
          type: "img",
          id: recess.id,
          title: recess.title,
          subtitle: recesses[0]?.city_hall.name,
          is_favorite: recess.is_favorite,
          score: recess.score
        }));

        setRecess({
          ...recess,
          recess: [...recesses].sort((a, b) =>
            dayjs(a.date_from).diff(dayjs(b.date_from))
          ),
          media
        });
      } else {
        alert("Tuvimos un problema, Parece que el recreo múltiple no existe.");
        history.goBack();
      }
    },
    onError: error =>
      console.error(
        `Parece que tuvimos un problema cargando los datos del recreo multiple ${error}`
      ),
    variables: {
      id: id,
      lang: lang.current
    }
  });

  return useMemo(() => ({ ...recess, loading }), [recess, loading]);
};

const query = gql`
  query getMultiple($id: String!, $lang: String) {
    recess_multiple_detail(id: $id, lang: $lang) {
      id
      title
      date_from
      date_to
      description_text
      is_favorite
      published_at
      score
      unpublished_at
      recess(lang: $lang) {
        id
        title
        score
        date_from
        date_to
        icon {
          uri
        }
        city_hall {
          name
          id
        }
        longitude
        latitude
      }
      icon {
        name
        uri
      }
      images {
        name
        uri
      }
      videos {
        name
        uri
        url
      }
    }
  }
`;

export default useMultipleRecess;
