import React, { Reducer, useContext, useReducer } from "react";
import RegisterData from "typedef/RegisterData";

export type RegisterContextType = {
  data: RegisterData;
  dispatch: React.Dispatch<ReducerAction>;
};

export const RegisterContext = React.createContext<RegisterContextType>({
  data: {},
  dispatch: () => undefined
});

const useRegisterContext = () => {
  const context = useContext(RegisterContext);

  return context;
};

export const RegisterContextProvider: React.FC = ({ children }) => {
  const [data, dispatch] = useReducer(reducer, {});

  return (
    <RegisterContext.Provider value={{ data, dispatch }}>
      {children}
    </RegisterContext.Provider>
  );
};

type ReducerAction = {
  type: keyof RegisterData;
  payload?: string | Date;
};

const reducer: Reducer<RegisterData, ReducerAction> = (
  state,
  { type, payload }
) => {
  return {
    ...state,
    [type]: payload
  };
};

export default useRegisterContext;
