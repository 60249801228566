import { ApolloClient, gql, InMemoryCache } from "@apollo/client";
import getEnvVariable from "env";

async function validatePasswordIsNew(email: string) {
  const client = new ApolloClient({
    uri: getEnvVariable("API_URL"),
    cache: new InMemoryCache()
  });

  try {
    const {
      data: { search_email_exist }
    } = await client.query({
      query: gql`
        query checkEmail($email: String!) {
          search_email_exist(email: $email)
        }
      `,
      variables: {
        email
      }
    });
    return !search_email_exist;
  } catch (err) {
    console.error(
      "Tuvimos un problema al validar la disponibilidad del correo",
      JSON.stringify(err)
    );
    throw Error(err);
  }
}

export default validatePasswordIsNew;
