import React, { useContext, useReducer } from "react";
import useStorageLang from "lang/useStorageLang";
import ES from "lang/es";
import EN from "lang/en";
import PT from "lang/pt";

export type LangContextType = {
  lang: typeof ES;
  dispatch: React.Dispatch<{ type: string; payload?: typeof ES }>;
};

export const LangContext = React.createContext<LangContextType>({
  lang: ES,
  dispatch: () => undefined
});

const useLangContext = () => {
  const context = useContext(LangContext);
  return context;
};

export const LangContextProvider: React.FC = ({ children }) => {
  const [lang, dispatch] = useReducer(reducer, useStorageLang());

  return (
    <LangContext.Provider value={{ lang, dispatch }}>
      {children}
    </LangContext.Provider>
  );
};

const reducer = (state: typeof ES, action: { type: string }) => {
  switch (action.type) {
    case "es":
      localStorage.setItem("langrecreo", "es");
      return ES;
    case "en":
      localStorage.setItem("langrecreo", "en");
      return EN;
    case "pt":
      localStorage.setItem("langrecreo", "pt");
      return PT;
    default:
      return ES;
  }
};

export default useLangContext;
