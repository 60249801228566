import React from "react";
import { useHistory } from "react-router";
import useTickets from "./useTickets";
import TicketLogo from "./../../assets/images/ticket-logo.svg";
import dayjs from "dayjs";
import "dayjs/locale/es";
import Ticket from "typedef/Ticket";
import TicketCard from "components/TicketCard";
import useScrollToTop from "hooks/useScrollToTop";
import useUser from "hooks/useUser";
import useLangContext from "hooks/useLangContext";

const MyTickets: React.FC = () => {
  const history = useHistory();
  const tickets = useTickets();
  const [user, loading] = useUser();
  const { lang } = useLangContext();
  useScrollToTop();

  function onGoToRecessMap(ticket: Ticket) {
    if (ticket.recess.__typename === "Recess")
      history.push(`/recess/simple/${ticket.recess.id}`);
    else if (ticket.recess.is_multiple === true)
      history.push(`/recess/multiple/${ticket.recess.id}`);

    setTimeout(() => {
      document
        .getElementById("mapaRecess")
        ?.scrollIntoView({ block: "end", behavior: "smooth" });
    }, 200);
  }

  return (
    <div className='container'>
      <div className='section-title d-flex align-items-center my-4'>
        <img width={37} src={TicketLogo} className='mr-3' />
        <h2 className='text-secondary font-weight-bold'>
          {lang.MyTickets.title}
        </h2>
      </div>

      <div className='row my-5'>
        {tickets.list.length > 0
          ? tickets.list.map((ticket, i) => (
              <TicketCard
                ticket={ticket}
                userId={user?.id}
                key={i}
                onGoToRecess={() => onGoToRecessMap(ticket)}
              />
            ))
          : tickets.loading === false && (
              <div className='p-3 mb-5 w-100 d-flex justify-content-center'>
                <h5 className='text-center'>{lang.MyTickets.noResult}</h5>
              </div>
            )}
      </div>
    </div>
  );
};

export default MyTickets;
