import "dayjs/locale/es";
import useRecesses from "./useRecesses";
import MainIcon from "assets/images/agenda-title.svg";
import UtiliyBar from "components/UtilityBar/index";
import useCategories from "screens/Home/useCategories";
import useScrollToTop from "hooks/useScrollToTop";
import RecessCard from "components/RecessCard";
import ErrorMSJ from "components/ErrorMSJ";
import Loader from "components/Loader";
import useLangContext from "hooks/useLangContext";

const Recesses = () => {
  const recesses = useRecesses();
  const [categories] = useCategories();
  const { lang } = useLangContext();
  useScrollToTop();

  return (
    <div className='container'>
      <div className='section-title d-flex align-items-center my-4'>
        <img src={MainIcon} className='mr-4 logo-sub-title' />
        <h2 className='text-secondary font-weight-bold'>
          {lang.Recreos.title}
        </h2>
      </div>

      <UtiliyBar
        className='mt-3'
        utils={categories}
        title={lang.Recreos.search}
        searchBar={lang.Recreos.searchPlaceholder}
      />

      <div className='row my-5'>
        {recesses.loading ? (
          <div className='d-flex m-auto text-center'>
            <Loader />
          </div>
        ) : recesses.list.length > 0 ? (
          recesses.list.map(recess => (
            <RecessCard recess={recess} key={recess.id} />
          ))
        ) : (
          <ErrorMSJ title={lang.Recreos.noResult} />
        )}
      </div>

      {!recesses.limitReached && recesses.fetchMore && !recesses.loading ? (
        <button
          className='secondary secondary--magenta d-block my-5 mx-auto'
          onClick={() => recesses?.fetchMore()}>
          {lang.Recreos.loadMore}
        </button>
      ) : null}
    </div>
  );
};

export default Recesses;
