import { LIGHT_GRAY } from "assets/colors";
import Icon from "assets/icons";
import { useHistory, useLocation } from "react-router";
import classes from "./classes.module.scss";

type THXContactProps = {
  onClick: () => void;
  onClose: (param: any) => any;
};

const THXContact: React.FC<THXContactProps> = ({ onClick, onClose }) => {
  const onAccept = () => {
    onClick();
    onClose();
  };

  return (
    <div className={`${classes.container} px-3 py-4 shadow `}>
      <div className={`${classes.modal} m-1`}>
        <button
          onClick={onClose}
          className={`p-0 h-auto align-top d-flex ${classes.onCloseButton}`}>
          <Icon className='d-flex' custom='Cross' fill={LIGHT_GRAY} size={14} />
        </button>
        <div className='text-center d-flex flex-column justify-content-center align-items-center'>
          <h3 className='my-3'>
            <b>Gracias por tu contacto.</b>
          </h3>
          <h6 className='text-muted my-4'>
            En breve nos comunicaremos con vos al correo electrónico ingresado
            en tu registro.
          </h6>

          <div className='clickable'>
            <button
              onClick={onAccept}
              className='secondary secondary--magenta '>
              Ir al inicio de ReCreo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default THXContact;
