import { gql, useQuery } from "@apollo/client";
import useSearchQuery from "hooks/useSearchQuery";
import useGPS from "hooks/useGPS";
import React, { useMemo, useState } from "react";
import Benefit from "typedef/Benefit";
import useLangContext from "hooks/useLangContext";

const useBenefits = () => {
  const page = React.useRef(1);
  const searchQuery = useSearchQuery();
  const [coords] = useGPS();
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [limitReached, setLimitReached] = useState(false);
  const search = searchQuery.get("busqueda") || "";
  const categoryId = searchQuery.get("filtro") || "";
  const { lang } = useLangContext();
  /**Query for the pagination of benefit */
  const { refetch, loading, fetchMore } = useQuery(getPaginationBenefist, {
    onCompleted: ({ benefit_list: { data: benefits } }) => {
      let resultSearch: Array<Benefit> = [];
      let resultCategory: Array<Benefit> = [];
      if (search !== "" || categoryId != "") {
        if (search !== "") {
          resultSearch = benefits.filter((benefit: any) =>
            benefit.name.includes(search)
          );
        } else {
          resultCategory = benefits.filter(
            (benefit: Benefit) => benefit.category.id === categoryId
          );
        }
        setBenefits(resultSearch.concat(resultCategory));
      } else {
        setBenefits(benefits);
      }
    },
    fetchPolicy: "no-cache",
    onError: err =>
      console.error(
        "Tuvimos un problema al cargar los beneficios",
        JSON.stringify(err)
      ),
    variables: {
      page: 1,
      limt: search !== "" || categoryId !== "" ? 99999 : 20,
      latitude: coords?.latitude,
      longitude: coords?.longitude,
      lang: lang.current
    }
  });

  function _refetch() {
    refetch({
      page: 1,
      latitude: coords?.latitude,
      longitude: coords?.longitude,
      lang: lang.current
    })
      .then(
        ({
          data: {
            benefits_list: { data: benefits }
          }
        }) => {
          setBenefits(benefits);
          page.current = 1;
          setLimitReached(false);
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema recargando los beneficios", error)
      );
    page.current = 1;
  }

  function _fetchMore() {
    if (limitReached) return;
    fetchMore({
      variables: {
        page: ++page.current,
        latitude: coords?.latitude,
        longitude: coords?.longitude,
        lang: lang.current
      }
    })
      .then(
        ({
          data: {
            benefit_list: { data: benefits, last_page: last_page }
          }
        }) => {
          benefits && setBenefits(prev => [...prev, ...benefits]);
          if (parseInt(last_page) <= page.current) {
            setLimitReached(true);
          }
        }
      )
      .catch(error =>
        console.error("Tuvimos un problema al cargar más beneficios", error)
      );
  }

  return useMemo(
    () => ({
      list: benefits,
      loading,
      refetch: _refetch,
      limitReached,
      fetchMore: _fetchMore
    }),
    [loading, benefits, limitReached, fetchMore]
  );
};

const getPaginationBenefist = gql`
  query getBenefits($page: Int, $lang: String, $limit: Int) {
    benefit_list(page: $page, lang: $lang, limit: $limit) {
      data {
        id
        name
        icon {
          uri
        }
        category(lang: $lang) {
          id
          name
        }
        description
        type
        used
        category(lang: $lang) {
          name
        }
        score
        type_description
        type_value_x
        type_value_y
        commerce {
          name
          longitude
          latitude
          city_hall {
            id
            name
          }
          branch_offices {
            longitude
            latitude
            name
          }
        }
      }
      last_page
    }
  }
`;

export default useBenefits;
