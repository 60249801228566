import classes from "../styles/index.module.scss";
const DownloadWallpapers = () => {
  return (
    <div className={classes.top}>
      <h2>Fondos de pantalla</h2>
      <div>
        <div className={classes.category}>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-01.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-01.jpg'
                download='FONDOS DE PANTLLA-01.jpg'>
                Descargar
              </a>
            </h6>
          </div>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-02.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-02.jpg'
                download='FONDOS DE PANTLLA-02.jpg'>
                Descargar
              </a>
            </h6>
          </div>
        </div>
        <div className={classes.category}>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-03.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-03.jpg'
                download='FONDOS DE PANTLLA-03.jpg'>
                Descargar
              </a>
            </h6>
          </div>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-04.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-04.jpg'
                download='FONDOS DE PANTLLA-04.jpg'>
                Descargar
              </a>
            </h6>
          </div>
        </div>
        <div className={classes.category}>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-05.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-05.jpg'
                download='FONDOS DE PANTLLA-05.jpg'>
                Descargar
              </a>
            </h6>
          </div>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-06.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-06.jpg'
                download='FONDOS DE PANTLLA-06.jpg'>
                Descargar
              </a>
            </h6>
          </div>
        </div>
        <div className={classes.category}>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-07.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-07.jpg'
                download='FONDOS DE PANTLLA-07.jpg'>
                Descargar
              </a>
            </h6>
          </div>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-08.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-08.jpg'
                download='FONDOS DE PANTLLA-08.jpg'>
                Descargar
              </a>
            </h6>
          </div>
        </div>
        <div className={classes.category}>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-09.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-09.jpg'
                download='FONDOS DE PANTLLA-09.jpg'>
                Descargar
              </a>
            </h6>
          </div>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-10.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-10.jpg'
                download='FONDOS DE PANTLLA-10.jpg'>
                Descargar
              </a>
            </h6>
          </div>
        </div>
        <div className={classes.category}>
          <div>
            <img src='descargas/wallpapers/small/FONDOS DE PANTLLA-11.jpg' />
            <h6>
              <a
                href='descargas/wallpapers/FONDOS DE PANTLLA-11.jpg'
                download='FONDOS DE PANTLLA-11.jpg'>
                Descargar
              </a>
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadWallpapers;
