export default {
  LogoTitle: "ReCreo",
  header: {
    top: {
      vaccination: "Vacinação e Teste",
      destiny: "Destinos Cuidados",
      termsAndCondi: "Termos e Condições",
      frequentQuestions: "Perguntas Frequentes",
      downloadRecreo: "Download ReCreo"
    },
    main: {
      knowCityHall: "Cconheça a Provincia",
      recreos: "ReCreos",
      schedule: "Agenda",
      convocatorias: "Convocatórias",
      benefit: "Benefícios",
      login: "Iniciar Sessão",
      singIn: "Crie sua Conta"
    },
    login: {
      editProfile: "Editar dados",
      level: "Nivel",
      virtual: "virtual",
      curious: "Curioso",
      explorer: "Explorador",
      expert: "Expert",
      points: "pontos",
      myFavs: "Meus favoritos",
      myBenefits: "Meus benefícios",
      myTickets: "Meus tickets",
      mySchedule: "Minha agenda",
      polls: "Pesquisas",
      notifications: "Notificações",
      configurate: "Configurações",
      logOut: "Saia",

      descriptVirtual:
        "Te gusta navegar la app pero sólo te animás a hacer un Recorrido de vez en cuando.",
      descriptCurius:
        "Te empieza a gustar cada vez más esto de viajar y ver personalmente lo que descubriste en redes.",
      descriptExplorer:
        "Ya visitaste y viviste un montón de lugares y eventos para recomendar.",
      desciptExpert:
        "Te recibiste de guía especialista para mostrar la Provincia."
    }
  }
};
