import Card from "components/Card";
import dayjs from "dayjs";
import getType from "helpers/getType";
import useFaved from "hooks/useFaved";
import useSocialShare from "hooks/useSocialShare";
import React from "react";
import Recess from "typedef/Recess";
import useOnClick from "./useOnClick";

export type RecessProps = {
  recess: Recess | any;
  isSmallCard?: boolean; // se aplica para que la card sea mas pequeña y pueda entrar en el carrousel
};

const RecessCard: React.FC<RecessProps> = ({ recess, isSmallCard }) => {
  const onClick = useOnClick(recess);
  const useShareSocial = useSocialShare(recess.id, getType(recess), recess);
  const [faved, fav] = useFaved(recess.id, getType(recess), recess.is_favorite);

  return (
    <Card
      key={recess.id}
      faved={faved}
      onClick={onClick}
      onFav={() => fav()}
      onShare={useShareSocial}
      score={recess.score}
      imgIcon={recess.categories?.[0]?.icon}
      imgTitle={recess.categories?.[0]?.name}
      containerClassName={`${
        isSmallCard ? "col-11 col-md-6 mb-3" : "col-12 col-md-6 mb-3"
      }`}
      img={recess.icon}>
      {recess.date_from && recess.date_to ? (
        <h5 className='text-muted small text-capitalize'>
          {dayjs(recess.date_from).locale("es").format("DD  MMM")} ·{" "}
          {dayjs(recess.date_to).locale("es").format("DD  MMM")}
        </h5>
      ) : null}
      <p className='small mb-2 font-weight-bold text-uppercase'>
        {recess.title}
      </p>
      <h6 className='small text-muted'>{recess.city_hall?.name}</h6>
    </Card>
  );
};

export default RecessCard;
