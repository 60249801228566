import { gql, useQuery } from "@apollo/client";
import useLangContext from "hooks/useLangContext";
import { useCallback, useMemo } from "react";
import { useState } from "react";

const useSurvays = () => {
  const [survays, setSurvays] = useState([]);
  const { lang } = useLangContext();
  const { loading, refetch } = useQuery(query, {
    fetchPolicy: "no-cache",
    variables: {
      lang: lang.current
    },
    onCompleted: ({ votes_list: { data: survays } }) => setSurvays(survays),
    onError: error =>
      console.error(
        `Tuvimos un problema cargando las encuestas: ${JSON.stringify(error)}`
      )
  });

  const _refetch = useCallback(() => {
    refetch()
      .then(({ data: { votes_list: { data: survays } } }) =>
        setSurvays(survays)
      )
      .catch(error =>
        console.error(`Tuvimos un problema recargando las encuestas`)
      );
  }, []);

  return useMemo(
    () => ({
      data: survays,
      loading,
      refetch: _refetch
    }),
    [survays, loading, _refetch]
  );
};

const query = gql`
  query getSurvays($lang: String) {
    votes_list {
      data {
        id
        answered
        qualification
        recommendation
        benefit(lang: $lang) {
          id
          name
          description
          icon {
            uri
          }
          commerce {
            name
            city_hall {
              name
            }
          }
        }
        recess(lang: $lang) {
          id
          title
          date_from
          date_to
          city_hall {
            name
          }
          icon {
            uri
          }
        }
        situation
        is_recess
        user_benefit_id
        created_at
        user_visited_recess_id
      }
    }
  }
`;

export default useSurvays;
