//import noLove from "assets/images/noLove.svg";

type errorMSJprops = {
  hasImg?: boolean;
  title: string;
  subtitle?: string;
};

const ErrorMSJ: React.FC<errorMSJprops> = ({ title, subtitle, hasImg }) => {
  return (
    <div className='p-3 mb-5 w-100 my-5 d-flex flex-column justify-content-center align-items-center'>
      {/*hasImg !== false && (
        <div></div>
        /*<img src={noLove} width={100} height={100} alt='imagen de error' />
      )*/}
      <h5 className='text-center'>
        <b>{title}</b>
      </h5>{" "}
      <h5 className='text-center text-muted'>
        <small>{subtitle}</small>
      </h5>
    </div>
  );
};
export default ErrorMSJ;
