export default {
  seeMore: "Ver mais",
  seeLess: "Ver menos",
  btnViewMap: "VER NO MAPA",
  btnReturn: "Volver",
  btnContinue: "Continuar",
  btnSend: "Enviar",
  btnDelete: "Eliminar",
  bookTickets: "Reservar Ingressos",
  save: "Guarda",
  all: "Todos",

  shareTextUp: "Compartilhar este conteúdo:",
  shareTextDown: "Copiar o link:"
};
