import * as React from "react";

function SvgGift(props) {
  return (
    <svg width={28} height={30} xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M26.287 6.811c.215-.401.328-.848.331-1.303 0-1.649-1.159-3.78-4.5-3.78-1.284.12-2.538.458-3.708 1l.267-.374a1.08 1.08 0 10-1.757-1.252L14.3 4.766l-2.046-3.6a1.082 1.082 0 10-1.879 1.073l.49.857a11.038 11.038 0 00-4.594-1.39c-1.39 0-2.225.677-3.089 1.29a3.067 3.067 0 00-1.44 2.49c.004.455.117.902.332 1.304A2.16 2.16 0 00.482 8.863v3.665a2.16 2.16 0 002.16 2.16v12.96a2.16 2.16 0 002.16 2.16h18.72a2.16 2.16 0 002.16-2.16v-12.96a2.16 2.16 0 002.16-2.16V8.885a2.16 2.16 0 00-1.555-2.074zm-1.829-1.303a.98.98 0 01-.482.72c-.432.32-.952.501-1.49.518h-6.293a8.15 8.15 0 015.925-2.88c1.865.022 2.34 1.116 2.34 1.642zm-21.78 3.42h10.08v3.6H2.678v-3.6zm3.6-5.04a8.15 8.15 0 015.926 2.88H5.918c-1.353 0-1.972-.778-1.972-1.224 0-.446.468-1.656 2.332-1.656zm-1.44 10.8h7.92v12.96h-7.92v-12.96zm18.72 12.96h-8.64v-12.96h8.64v12.96zm2.16-15.12h-10.8v-3.6h10.8v3.6z' />
    </svg>
  );
}

export default SvgGift;
