import ConvoCard from "components/Convocatorias/ConvoCard";
import useConvocatorias from "./useConvocatorias";
import Title from "../Title";
import useScrollToTop from "hooks/useScrollToTop";
import ConvoFilterUtil from "components/ConvoFilterUtil";
import useLangContext from "hooks/useLangContext";

export type SubtitleProps = {
  title: string;
  type: string;
};

const ConvocatoriasList = () => {
  const {
    convocatorias,
    fetchMore,
    limitReached,
    filterIndex,
    setFilterIndex
  } = useConvocatorias();
  useScrollToTop();
  const { lang } = useLangContext();

  return (
    <>
      <div className='container'>
        <Title title={lang.Convocatorias.openCalls} to='/convocatorias' />
        <ConvoFilterUtil
          activeIndex={filterIndex}
          setFilterIndex={setFilterIndex}
        />
        {convocatorias?.map((convo, index) => (
          <ConvoCard key={index} convo={convo} />
        ))}
      </div>
      <div className='d-block m-auto'>
        {!limitReached && (
          <button
            className='secondary secondary--magenta d-block my-5 mx-auto'
            onClick={() => fetchMore()}>
            {lang.Convocatorias.loadMore}
          </button>
        )}
      </div>
    </>
  );
};

export default ConvocatoriasList;
