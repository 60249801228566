import Card from "components/Card";
import Stars from "components/Stars/Stars";
import { useHistory } from "react-router";
export type BenefitCardSurvaysProps = {
  benefit: any;
  idEncuesta?: string;
  stars?: number;
};
const BenefitCardSurvays: React.FC<BenefitCardSurvaysProps> = ({
  benefit,
  idEncuesta,
  stars
}) => {
  const history = useHistory();
  return (
    <Card
      img={benefit?.icon}
      score={stars}
      containerClassName={`w-100`}
      onSurvays={() => !stars && history.push(`/survay-benefit/${idEncuesta}`)}>
      <p className='small mb-2 font-weight-bold text-uppercase'>
        {benefit?.name}
      </p>
      <h6 className='small text-muted'>{benefit?.commerce.city_hall?.name}</h6>
    </Card>
  );
};

export default BenefitCardSurvays;
