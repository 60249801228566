export default {
  title: "Actualmente estás en",
  level: "Nivel",
  virtual: "virtual",
  curious: "Curioso",
  explorer: "Explorador",
  expert: "Experto",

  descriptVirtual:
    "Te gusta navegar la app pero sólo te animás a hacer un Recorrido de vez en cuando.",
  descriptCurius:
    "Te empieza a gustar cada vez más esto de viajar y ver personalmente lo que descubriste en redes.",
  descriptExplorer:
    "Ya visitaste y viviste un montón de lugares y eventos para recomendar.",
  desciptExpert: "Te recibiste de guía especialista para mostrar la Provincia."
};
