import Icon from "assets/icons";
import RecreoFeatures from "components/RecreoFeatures";
import useLangContext from "hooks/useLangContext";
import classes from "./classes.module.scss";

type WhereToUseProps = {
  branch_offices: Array<any>;
};

const WhereToUse: React.FC<WhereToUseProps> = ({ branch_offices }) => {
  const { lang } = useLangContext();
  return (
    <>
      {branch_offices.map((item, i) => {
        return (
          <div key={i} className={`${classes.card}`}>
            <div className={`${classes.cardContent}`}>
              <div className='d-flex align-items-center'>
                <div>
                  <Icon custom='Ubicacion' />
                </div>
                {item.is_virtual ? (
                  <div className='ml-3'>
                    <p>{"Online"}</p>
                  </div>
                ) : (
                  <div className='ml-3'>
                    <p>
                      {lang.Benefit.address} : {item.address?.split(",")[0]}
                    </p>
                    <p>Localidad : {item.address?.split(",")[1]}</p>
                  </div>
                )}
              </div>
              {item.phone_number ? (
                <div className='d-flex align-items-center mt-2'>
                  <a
                    href={`https://api.whatsapp.com/send?phone=${item.phone_number}`}>
                    <Icon custom='Whatsapp' />
                  </a>
                  <p className='ml-3'>{lang.Benefit.tabContact}</p>
                </div>
              ) : null}
              <div>
                {item.web_url ? (
                  <div className='d-flex align-items-center mt-2'>
                    <p>Link :</p>
                    <a
                      className='ml-2'
                      style={{ color: "black" }}
                      href={`${item.web_url}`}>{`${item.web_url}`}</a>
                  </div>
                ) : null}
              </div>
              <div className={`${classes.subIcons} pl-5 pt-2`}>
                <RecreoFeatures
                  features={[
                    {
                      icon: "Recycle",
                      available: item.sustainability
                    },
                    {
                      icon: "Accesibility",
                      available: item.is_accessibility
                    },
                    {
                      icon: "HandSanitizer",
                      available: item.preventive_measures
                    },
                    {
                      icon: "GenderFluid",
                      available: item.gender_diversity
                    }
                  ]}
                />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default WhereToUse;
