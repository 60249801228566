import { gql, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
export const ACCESS_TOKEN_KEY = "Asdflj1n2e2Q@!#rt4ew45";

/**
 * Hook para manejar el favoriteo de las entidades.
 *
 * @param id id de la entidad a favoritear
 * @param type Si es recreo simple, múltiple, o municipio
 * @param initialState Si está favoriteado inicialmente
 */
const useFaved = (
  id: string,
  type: "recreo-simple" | "recreo-multiple" | "municipio" | "recreo-circuito",
  initialState: boolean
) => {
  const [faved, setFaved] = useState(initialState);
  const [favCityHall] = useMutation(queryCityHall);
  const [favRecess] = useMutation(queryRecess);

  useEffect(() => {
    setFaved(initialState);
  }, [initialState]);

  async function fav() {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      const newFavState = !faved;
      try {
        setFaved(newFavState);
        switch (type) {
          case "recreo-simple":
            await favRecess({
              variables: {
                id,
                faved: newFavState,
                is_multiple: false
              }
            });
            break;
          case "recreo-multiple":
            await favRecess({
              variables: {
                id,
                faved: newFavState,
                is_multiple: true
              }
            });
            break;
          case "municipio":
            await favCityHall({
              variables: {
                id,
                faved: newFavState
              }
            });
            break;
        }
      } catch (error) {
        console.error(`Tuvimos un problema seteando el favorito ${error}`);
        alert(
          "Lo sentimos, parece que tuvimos un problema agregarlo a tu lista de favoritos."
        );
        setFaved(!newFavState);
        return Promise.reject(JSON.stringify(error));
      }
    } else {
      alert("Registrate o inicia sesión para poder agregarlo a tus Favoritos.");
    }
  }

  return [faved, fav] as const;
};

const queryRecess = gql`
  mutation setFav($id: String!, $faved: Boolean!, $is_multiple: Boolean!) {
    update_favorite_recess(
      recess_id: $id
      add_recess: $faved
      is_multiple: $is_multiple
    ) {
      id
    }
  }
`;

const queryCityHall = gql`
  mutation setFav($id: String!, $faved: Boolean!) {
    update_favorite_city_hall(city_hall_id: $id, add_city_hall: $faved) {
      id
    }
  }
`;

export default useFaved;
