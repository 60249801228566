export default {
  titleHero: "Faça parte da ReCreo",
  subTitleHero: "Participar é muito simples",

  title: "Quero fazer parte",
  subTitle:
    "Complete o formulário com os dados solicitados sobre seu evento cultutral, empreendimento ou negócio e entraremos em contato para avançar com o processo",
  name: "Nome",
  namePlaceholder: "Digite seu nome",
  lastName: "Sobrenome",
  lastNamePlaceholder: "Digite seu sobrenome",
  location: "Município",
  locationSelect: "Selecionar município",
  kindOfActivity: "Tipo de atividade",
  kindOfActivityPlaceholder: "Tipo de atividade",
  category: "Categoria",
  categorySelect: "Selecionar categoria",
  email: "E-mail",
  emailPlaceholder: "examplo@mail.com",
  tel: "Telefone",
  web: "Site",
  webPlaceholder: "www.examplo.com",
  instagram: "Instagram",
  instPlaceholder: "@examplo",
  facebook: "FaceBook",
  facebookPlaceHolder: "@examplo",
  description: "Escrição",
  descriptionPlaceholder: "Escreva um texto de no máximo 300 caracteres"
};
