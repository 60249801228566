import { useMemo, useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Recess from "typedef/Recess";
import useLangContext from "hooks/useLangContext";

const useAmenities = () => {
  const [amenities, setAmenities] = useState<Array<Recess>>([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    onCompleted: ({ recess_list: { data: recesses } }) =>
      setAmenities(recesses),
    onError: error =>
      console.error(
        `Parece que tuvimos un problema cargando los amenities. ${JSON.stringify(
          error
        )}`
      ),
    variables: {
      page: 1,
      lang: lang.current
    }
  });

  return useMemo(
    () => ({
      list: amenities,
      loading
    }),
    [amenities, loading]
  );
};

const query = gql`
  query getAmenities(
    $page: Int
    $longitude: Float
    $latitude: Float
    $lang: String
  ) {
    recess_list(
      limit: 2
      page: $page
      longitude: $longitude
      latitude: $latitude
      space_proximity: 40
      time_proximity: 400
      lang: $lang
      preferences: 40
      home: false
    ) {
      data {
        id
        title
        score
        date_from
        date_to
        is_favorite
        is_multiple
        agenda {
          id
        }
        circuit {
          id
        }
        categories {
          name
          icon {
            uri
          }
        }
        city_hall {
          name
        }
        icon {
          uri
        }
      }
    }
  }
`;

export default useAmenities;
