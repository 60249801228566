import { LIGHT_GRAY } from "assets/colors";
import classes from "./classes.module.scss";
import Icon from "assets/icons";
import Reward from "typedef/Reward";
import QR from "components/QR";
import useRewardQR from "./useRewardQR";
import Loader from "components/Loader";

type ModalRewardProps = {
  onClose: (param: any) => any;
  userId: string;
  reward: Reward;
};

const ModalReward: React.FC<ModalRewardProps> = ({
  onClose,
  reward,
  userId
}) => {
  const { reward: rewardQR, loading } = useRewardQR(reward.id);
  return (
    <div className={classes.container}>
      <div className={`${classes.modal} p-3 shadow align-items-center`}>
        <div className='w-100'>
          <button onClick={onClose} className='p-0 h-auto align-top d-flex'>
            <Icon
              className='d-flex'
              custom='Cross'
              fill={LIGHT_GRAY}
              size={14}
            />
          </button>
          <div className='d-flex flex-column align-items-center w-100'>
            <div className='text-secondary text-uppercase font-weight-bold'>
              {reward?.name}
            </div>
            <div className='text-secondary font-weight-bold'>
              {reward?.commerce.name}
            </div>
            <div>
              {loading ? (
                <Loader />
              ) : (
                <>
                  <div className='my-3'>
                    {rewardQR?.code_qr && (
                      <div className='d-flex justify-content-center'>
                        <QR value={rewardQR.code_qr + ":" + userId} />
                      </div>
                    )}
                  </div>
                  <div className='w-100 mb-2 mt-4 text-center'>
                    <small className='text-muted'>Código</small> <br />
                    {rewardQR?.code_qr && (
                      <span className='text-primary'>
                        {rewardQR?.code_qr}:{userId}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalReward;
