export default {
  whatsMind: "¿Qué ReCreos estás buscando?",
  whatsSearch: "¿Qué estas buscando?",

  nearRecess: "Próximos ReCreos",
  viewRecessSchedule: "Ver la Agenda de ReCreos",
  whatsMindSearch: "¿Qué estas buscando?",
  knowCityHall: "Conocé la ReProvincia",
  viewRecommendRecess: "Ver los Municipios y Circuitos",
  recessForYou: "ReCreos para vos",
  viewMoreRecessRecommend: "Ver más ReCreos recomendados",
  adderedOnTeam: "¿Querés sumarte a ReCreo?"
};
