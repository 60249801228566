export default {
  title: "Iniciá Sesión",
  noRemind: "¿Te la olvidaste? ",
  forgotPassword: "Recuperá tu contraseña",
  loginFacebook: "Ingresar con Facebook",
  loginGmail: "Ingresar con Gmail",
  loginEmail: "Ingresar con e-mail",
  notLogin: "¿Aún no te registraste? ",
  setPass: "Completá tu contraseña",
  createAccount: "Creá tu cuenta"
};
