import { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import Tag from "typedef/Tag";
import useLangContext from "hooks/useLangContext";

type QueryResponse = {
  recess_category_list: {
    data: Array<{
      id: string;
      tag: string;
      sub_category: "Gustos" | "Viajes";
    }>;
  };
};

const useTags = (): [Array<Tag>, boolean] => {
  const [tags, setTags] = useState<Array<Tag>>([]);
  const { lang } = useLangContext();
  const { loading } = useQuery(query, {
    variables: {
      lang: lang.current
    },
    fetchPolicy: "no-cache",
    onCompleted: ({
      recess_category_list: { data: categories }
    }: QueryResponse) => {
      setTags([
        {
          title: lang.RegisterProfile.activities,
          data: categories
            .filter(({ sub_category }) => sub_category === "Gustos")
            .sort((a, b) => (a.tag > b.tag ? 1 : -1))
        },
        {
          title: lang.RegisterProfile.scapeQuestion,
          data: categories
            .filter(({ sub_category }) => sub_category === "Viajes")
            .sort((a, b) => (a.tag > b.tag ? 1 : -1))
        }
      ]);
    },
    onError: error => {
      setTags([
        {
          title: lang.RegisterProfile.activities,
          data: [{ id: "a", tag: "A", sub_category: "Gustos" }]
        },
        {
          title: lang.RegisterProfile.scapeQuestion,
          data: [{ id: "b", tag: "B", sub_category: "Viajes" }]
        }
      ]);
      console.error(
        `Tuvimos un problema cargando las categorías para los tags del registro. ${JSON.stringify(
          error
        )}`
      );
    }
  });

  return [tags, loading];
};

const query = gql`
  query getRecessCategoryList($lang: String) {
    recess_category_list(limit: 9999999, lang: $lang) {
      data {
        id
        tag
        sub_category
      }
    }
  }
`;

export default useTags;
