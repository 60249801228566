import { gql, useMutation, useQuery } from "@apollo/client";
import useAccessToken from "hooks/useAccessToken";
import useBenefitQRModal from "hooks/useBenefitQRModal";
import useFirebaseEvent from "hooks/useFirebaseEvent";
import useLangContext from "hooks/useLangContext";
import { useMemo } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import Benefit from "typedef/Benefit";

/**
 * Hook to get details of a benefit,
 * and a save function to send the benefit
 * to `MyBenefist` list.
 */
const useBenefit = (id: string | null, goToMap?: () => void) => {
  const [benefit, setBenefit] = useState<Benefit>();
  const accessToken = useAccessToken();
  const showBenefitQR = useBenefitQRModal(id, () => goToMap?.());
  const history = useHistory();
  const { lang } = useLangContext();
  const [saveBenefit, { loading: loadingAction }] = useMutation(saveQuery, {
    variables: { benefit_id: id, add_benefit: true }
  });
  const reportBotonObtenerBeneficio = useFirebaseEvent({
    eventName: "botonObtenerBeneficio"
  });
  const reportBenefitBurned = useFirebaseEvent({
    eventName: "se_abrio_beneficio_quemado",
    eventData: {
      id_beneficio_quemado: id,
      id_beneficio_categoria_quemado: benefit?.category.id
    }
  });
  useFirebaseEvent({
    eventName: "se_abrio_beneficio",
    eventData: { id_beneficio: id },
    onMount: true
  });

  const { loading } = useQuery(query, {
    onCompleted: ({ benefit_detail }) => {
      setBenefit(benefit_detail);
    },
    onError: error => {
      console.error(
        "Parece que tuvimos un problema al cargar los datos del beneficio",
        error
      );
      history.goBack();
      alert("Parece que no pudimos cargar el beneficio");
    },
    variables: {
      id: id,
      lang: lang.current
    },
    fetchPolicy: "no-cache"
  });

  async function save() {
    try {
      if (accessToken) {
        await saveBenefit();
        reportBenefitBurned();
        reportBotonObtenerBeneficio();
        showBenefitQR();
      } else {
        alert(
          "Registrate o inicia tu sesión para disfrutar de los beneficios que tenemos para vos."
        );
      }
    } catch (error) {
      console.error(
        `Parece que tuvimos un problema agregando el beneficio al usuario, ${error}`
      );
      alert(
        "Parece que tuvimos un problema guardando tu beneficio. Por favor, intentalo nuevamente más tarde."
      );
    }
  }

  return useMemo(
    () => ({
      ...benefit,
      loading,
      loadingAction,
      save
    }),
    [benefit, , loading, lang.current]
  );
};

const query = gql`
  query getBenefit($id: String!, $lang: String) {
    benefit_detail(id: $id, lang: $lang) {
      id
      name
      type
      is_combinable
      combinate {
        description
        legals
        services_combinate {
          commerce_addered
          product
          logo
        }
      }
      validity_to
      legals
      images {
        uri
      }
      category(lang: $lang) {
        name
        icon {
          uri
        }
      }
      description
      apply_days
      commerce {
        id
        name
        is_ecommerce
        web_url
        instagram_url
        sustainability
        accessibility
        preventive_measures
        gender_diversity
        city_hall {
          id
          name
          address
        }
        images {
          uri
        }
        address
        latitude
        longitude
        branch_offices {
          is_ecommerce
          web_url
          name
          phone_number
          instagram_url
          sustainability
          accessibility
          preventive_measures
          gender_diversity
          address
          longitude
          latitude
          is_virtual
        }
      }
    }
  }
`;

const saveQuery = gql`
  mutation($benefit_id: String!, $add_benefit: Boolean!) {
    update_favorite_benefit(
      benefit_id: $benefit_id
      add_benefit: $add_benefit
    ) {
      id
    }
  }
`;

export default useBenefit;
