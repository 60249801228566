import useFirebaseEvent from "hooks/useFirebaseEvent";

const useCircuitRecessFirebaseEvent = (id_municipio: string | null) => {
  useFirebaseEvent<"se_abrio_municipio">({
    eventName: "se_abrio_municipio",
    onMount: true,
    eventData: {
      id_municipio
    }
  });
};

export default useCircuitRecessFirebaseEvent;
