import { createStore, Reducer, Store } from "redux";

export type AppStoreState = {
  notification?: boolean;
  gqlAuthClient?: string;
  user?: {
    accessToken: string;
  };
};

export type AppStoreAction = {
  type: "LOGIN" | "LOGOUT" | "setAuthClient" | "setNotification";
  payload: any;
};

export type AppStore = Store<AppStoreState, AppStoreAction>;

const reducer: Reducer<AppStoreState, AppStoreAction> = (
  state,
  { type, payload }
) => {
  switch (type) {
    case "LOGIN":
      return { ...state, user: payload as { accessToken: string } };
    case "LOGOUT":
      return { ...state, user: undefined };
    case "setAuthClient":
      return { ...state, gqlAuthClient: payload as string };
    case "setNotification":
      return { ...state, notification: payload as boolean };
    default:
      return { ...state };
  }
};

const store = createStore(reducer);

export { store };
