import useReCredits from "./useReCredits";
import Loader from "assets/images/loader.svg";
import UtiliyBar from "components/UtilityBar";
import Map from "components/Map";
import ReCreditCard from "components/ReCreditCard";
import useRecreoditsPoints from "./useRecreditsPoints";
import useCategories from "../BenefitsList/useCategories";
import ErrorMSJ from "components/ErrorMSJ";
import answer from "assets/images/answer.svg";
import HowToUse from "../../../components/HowToUseRecredits";
import dayjs from "dayjs";

type reCreditsListProps = {
  mapVisible: boolean;
};

const ReCreditsList: React.FC<reCreditsListProps> = ({ mapVisible }) => {
  const { points, dateEnd, loadingPoints } = useRecreoditsPoints();
  const categoriesBenefit = useCategories();
  const howToUseModal = HowToUse();
  const { reCredits, loading, locations } = useReCredits();

  return (
    <>
      {!loading && locations && locations.length > 0 && (
        <div className='mb-5'>
          <Map isVisible={mapVisible} ubications={locations} type={"benefit"} />
        </div>
      )}
      <UtiliyBar
        className='mt-3 mb-5'
        utils={categoriesBenefit.list}
        searchBar='¿Qué ReCredito estás buscando?'
        title='¿Qué promociones estás buscando?'
      />
      {loadingPoints === false && (
        <div
          className='bg-white p-3 my-4 rounded w-100 d-flex align-items-center'
          style={{ color: "#00B2C8", width: "fit-content" }}>
          <div
            className='badge-pill pr-3 pl-3 pt-1 pb-1'
            style={{ background: "rgba(0, 178, 200, 0.2)" }}>
            {points || 0}
          </div>
          <div className='mx-2'>ReCréditos Disponibles</div>
          {dateEnd && (
            <div className='mx-2 text-muted'>
              Haste el {dayjs(dateEnd).format("DD/MM/YY")}
            </div>
          )}
          <a
            style={{ cursor: "pointer" }}
            onClick={() => howToUseModal()}
            className='ml-auto'>
            <img src={answer} />
          </a>
        </div>
      )}
      {loading ? (
        <div className='d-flex justify-content-center m-auto'>
          <img src={Loader} />
        </div>
      ) : reCredits.length !== 0 ? (
        <div className='row justify-content-between'>
          {reCredits.map((recredit, i) => {
            return <ReCreditCard recredit={recredit} key={i} />;
          })}
        </div>
      ) : (
        <div>
          <ErrorMSJ
            title={"No hay Recreditos para esta campaña"}
            subtitle={"Volvé pronto para ver las novedades"}
          />
        </div>
      )}
    </>
  );
};

export default ReCreditsList;
