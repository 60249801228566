export default {
  title: "Selected Destinations",
  parrapha1: "Wear your mask.",
  parrapha2: "Keep social distance (minimum 2 meters).",
  parrapha3: "Avoid crowded places.",
  parrapha4:
    "Read and pay attention to the recommendations of tourist providers to prevent the transmission.",
  parrapha5: "Choose outdoor activities.",
  parrapha6:
    "If you have any of these symptoms, contact local health authorities: temperature over 37°. Loss of smell or taste, pain in the throat or shortness of breath.",
  parrapha7:
    "Before traveling, consult local sources of information to follow their recommendations.",
  parrapha8: "Remember to carry alcohol gel, hand sanitizer and/or tissues.",
  vaccLink: "Find vaccination posts and COVID testing centers"
};
