import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Icon from "assets/icons";
import { LIGHT_BLUE } from "assets/colors";
import useGetDocumentType from "./useGetDocumentType";
import useParticipar from "./useParticiparCompleja";
import useEditComplexProposal from "./useEditComplexProposal";
import getEnvVariable from "env";
import getYoutubeLinkId from "helpers/getYoutubeLinkId";
export const ACCESS_TOKEN_KEY = "Asdflj1n2e2Q@!#rt4ew45";

const Form = ({ inputs, propuesta, convoId, handleChangeStep }) => {
  const isLoggued = localStorage.getItem(ACCESS_TOKEN_KEY);
  const [values, setValues] = useState([]);
  const [about, setabout] = useState("");
  const [title, settitle] = useState("");
  const [loading, setloading] = useState(false);
  const [displayBtn, setdisplayBtn] = useState(false);
  const [displayEdit, setdisplayEdit] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const editText = useEditComplexProposal(
    propuesta?.id,
    null,
    null,
    null,
    about,
    title
  );
  useEffect(() => {
    if (propuesta.hasProposal) {
      settitle(propuesta?.description);
      setabout(propuesta?.about);
    }
  }, [propuesta]);
  const fieldChanged = (file, index) => {
    setValues(currentValues => {
      currentValues[index] = file;
      return currentValues;
    });
  };
  const participar = useParticipar(convoId, about, title, values);
  const invocarParticipar = e => {
    e.preventDefault();
    setloading(true);
    if (isLoggued) {
      participar().then(() => {
        setloading(false);
        handleChangeStep();
      });
      document
        .getElementById("headerConvo")
        ?.scrollIntoView({ block: "center", behavior: "smooth" });
    } else {
      alert(
        "Registrate o inicia sesión para poder participar de una Convocatoria."
      );
    }
  };
  const editTexts = async e => {
    setLoadingEdit(true);
    await editText();
    handleChangeStep();
    setLoadingEdit(false);
    setdisplayEdit(false);
  };
  return (
    <div>
      <form onSubmit={e => invocarParticipar(e)}>
        <div className='container-fluid'>
          <div className='form-group mt-3'>
            <input
              className='form-control'
              required
              type='text'
              name='title'
              placeholder='Título'
              value={title}
              onChange={({ currentTarget }) => {
                settitle(currentTarget.value);
                setdisplayEdit(true);
              }}
            />
          </div>
          <div className='form-group mt-3'>
            <input
              required
              className='form-control'
              type='text'
              name='about'
              placeholder='Acerca de mi / Nosotros'
              value={about}
              onChange={({ currentTarget }) => {
                setabout(currentTarget.value);
                setdisplayEdit(true);
              }}
            />
          </div>
          {displayEdit && propuesta.hasProposal ? (
            <button
              className='secondary secondary--magenta mt-3'
              onClick={() => editTexts()}>
              {loadingEdit ? (
                <span
                  className='spinner-border spinner-border-sm mr-2'
                  role='status'
                  aria-hidden='true'
                />
              ) : null}
              Confirmar edición
            </button>
          ) : null}
        </div>

        {inputs.length > 0 &&
          inputs?.map((input, index) => (
            <Field
              field={input}
              fieldChanged={fieldChanged}
              index={index}
              propuesta={propuesta}
              convoId={convoId}
              handleChangeStep={() => {
                handleChangeStep();
              }}
            />
          ))}

        <div className='d-flex flex-column align-items-center'>
          <p className='terminos mt-5'>
            Al continuar estás aceptando nuestros{" "}
            <div
              onClick={() =>
                document
                  .getElementById("TermsAndConditions")
                  ?.scrollIntoView({ block: "center", behavior: "smooth" })
              }
              className='clickable'>
              <strong className='recreoBlue '>Términos y Condiciones</strong>
            </div>
            <br />
            Por favor, revisá tus datos personales en la siguiente pantalla.
          </p>
          {propuesta.hasProposal || displayBtn ? null : (
            <button type='submit' className='secondary secondary--magenta mt-3'>
              {loading ? (
                <span
                  className='spinner-border spinner-border-sm mr-2'
                  role='status'
                  aria-hidden='true'
                />
              ) : null}
              Continuar
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

const Field = ({
  field,
  fieldChanged,
  index,
  propuesta,
  convoId,
  handleChangeStep
}) => {
  const url = getEnvVariable("SERVER_URL");
  const formatoDescarga = useGetDocumentType(field.type);
  const [fileEdit, setFileEdit] = useState();
  const [preview, setpreview] = useState("");
  const [displayEdit, setdisplayEdit] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(false);
  const editMultimedia = useEditComplexProposal(
    propuesta?.id,
    convoId,
    field?.id,
    fileEdit,
    null,
    null
  );
  useEffect(() => {
    if (propuesta.multimedia?.length > index) {
      propuesta.multimedia.map(data => {
        if (data.annoucement_item_id === field.id) {
          setpreview(data.type !== "link" ? url + data.path : data.path);
        }
      });
    }
  }, [propuesta]);
  const handleDocument = e => {
    if (e.target.files && e.target.files[0]) {
      fieldChanged(e.target.files[0], index);
      setpreview(URL.createObjectURL(e.target.files[0]));
      setFileEdit(e.target.files[0]);
    } else {
      fieldChanged(e.target.value, index);
      setpreview(e.target.value);
      setFileEdit(e.target.value);
    }
    setdisplayEdit(true);
  };
  const editFile = async e => {
    setLoadingEdit(true);
    await editMultimedia();
    setLoadingEdit(false);
    handleChangeStep();
    setdisplayEdit(false);
  };
  return (
    <div key={field.id}>
      <p className='mt-3 font-weight-bold'>{`${field.title}`}</p>
      <p className='mt-1' style={{ color: "#8C8C8C" }}>
        Puede ser un archivo de formato <strong>{formatoDescarga}</strong>
      </p>
      <div className='uploadDiv mt-3 d-flex flex-column align-items-center'>
        <div className='container-fluid d-flex justify-content-center'>
          {preview && field.type === "image" ? (
            <img
              className='rounded'
              style={{ maxWidth: "100%", maxHeight: "600px" }}
              src={preview}
            />
          ) : preview && field.type === "document" ? (
            <a href={preview} target='_blank'>
              <Icon
                custom='Document'
                style={{ alignSelf: "center", cursor: "pointer" }}
                size={150}
                stroke={LIGHT_BLUE}
                fill={LIGHT_BLUE}
                colorLine={"#fff"}
              />
            </a>
          ) : preview && field.type === "video" ? (
            <video style={{ maxWidth: "100%", maxHeight: "600px" }} controls>
              <source src={preview} type='video/mp4' />
            </video>
          ) : preview && field.type === "audio" ? (
            <audio
              style={{ maxWidth: "100%" }}
              src={preview}
              preload='metadata'
              controls
            />
          ) : preview && field?.type === "link" ? (
            <iframe
              width='100%'
              height='480px'
              src={`https://www.youtube.com/embed/${getYoutubeLinkId(
                preview
              )}?enablejsapi=1&playsinline=1&controls=1&enablejsapi=1&showinfo=0`}
              title='YouTube video player'
              frameBorder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          ) : null}
        </div>
        {field?.type !== "link" ? (
          <div className='d-flex'>
            <div className='fakeButton mb-3 mt-3'>
              {`Subir archivo`}
              <input
                type='file'
                required
                onClick={() => setpreview(null)}
                onChange={handleDocument}
                accept={formatoDescarga}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0
                }}
              />
            </div>
            {displayEdit && propuesta.hasProposal ? (
              <button
                className='secondary secondary--magenta d-none d-sm-block mb-3 mt-3'
                onClick={() => editFile()}>
                {`Confirmar cambio`}
                {loadingEdit ? (
                  <span
                    className='spinner-border spinner-border-sm ml-2'
                    role='status'
                    aria-hidden='true'
                  />
                ) : null}
              </button>
            ) : null}
          </div>
        ) : (
          <>
            <input
              className='my-3'
              type='text'
              onChange={handleDocument}
              value={preview}
            />
            {displayEdit && propuesta.hasProposal ? (
              <button
                className='secondary secondary--magenta d-none d-sm-block mb-3 mt-3'
                onClick={() => editFile()}>
                {`Confirmar cambio`}
                {loadingEdit ? (
                  <span
                    className='spinner-border spinner-border-sm ml-2'
                    role='status'
                    aria-hidden='true'
                  />
                ) : null}
              </button>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default Form;
