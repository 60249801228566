import { gql, useMutation } from "@apollo/client";
import useUser from "hooks/useUser";
interface Props {}

function UseEditComplexProposal(
  proposalId: number,
  annoucementId: number,
  annoucementItemId: number,
  file: File,
  about: string,
  title: string
) {
  const [user] = useUser();
  const [mutate] = useMutation(editMultimedia, {
    variables: {
      user_id: user?.id,
      proposal_id: proposalId,
      annoucement_id: annoucementId,
      annoucement_item_id: annoucementItemId,
      file: file
    }
  });
  const [mutateText] = useMutation(ediText, {
    variables: {
      proposal_id: proposalId,
      about: about,
      description: title
    }
  });
  const editComplexProposal = async () => {
    try {
      if (title || about) {
        await mutateText();
        // alert("Edición de propuesta Éxitosa");
      } else {
        await mutate();
        // alert("Edición de multimedia éxitosa!");
      }
    } catch (error) {
      alert(
        "Parece que tuvimos un problema para subir la edición del multimedia"
      );
      console.log(
        "Parece que hubo un error subiendo la edición del multimedia",
        error.message
      );
    }
  };
  return editComplexProposal;
}
const editMultimedia = gql`
  mutation(
    $proposal_id: Int!
    $user_id: Int!
    $annoucement_item_id: Int!
    $file: Upload
    $link: String
  ) {
    update_file_complex_proposal(
      user_id: $user_id
      proposal_id: $proposal_id
      annoucement_item_id: $annoucement_item_id
      file: $file
      link: $link
    ) {
      about
      description
      multimedia {
        path
        type
      }
    }
  }
`;
const ediText = gql`
  mutation($proposal_id: Int!, $about: String!, $description: String!) {
    update_info_complex_proposal(
      proposal_id: $proposal_id
      about: $about
      description: $description
    ) {
      about
      description
    }
  }
`;
export default UseEditComplexProposal;
