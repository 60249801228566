import useSearchQuery from "hooks/useSearchQuery";
import useSimpleRecess from "./useSimpleRecess";
import RecreoFeatures from "components/RecreoFeatures";
import classes from "./style/index.module.scss";
import React, { useEffect, useState } from "react";
import TextualHero from "../../components/TextualHero/index";
import useScrollToTop from "hooks/useScrollToTop";
import useSimpleRecessFirebaseEvent from "./useSimpleRecessFirebaseEvent";
import useSpotifyLink from "./useSpotifyLink";
import Map from "components/Map";
import useUpdateSchedule from "hooks/useUpdateSchedule";
import useLangContext from "hooks/useLangContext";
import { useParams } from "react-router-dom";

const SimpleRecess = () => {
  const params = useParams<{ id: string }>();
  const { lang } = useLangContext();
  const [more, setMore] = useState<undefined | boolean>();
  const maxLetters = 600;
  const recess = useSimpleRecess(params.id);
  const mapa = React.useRef(null);
  const {
    update,
    isActiveShedule,
    loading: loadingSchedule
  } = useUpdateSchedule(recess.id);
  const spotify = useSpotifyLink(recess?.spotify_link);
  useSimpleRecessFirebaseEvent(recess);
  useScrollToTop();

  useEffect(() => {
    if (recess.icon) {
      var ogmetatag = document.createElement("meta");
      ogmetatag.setAttribute("property", "og:image");
      ogmetatag.setAttribute(
        "content",
        `https://minio.recreopba.com.ar/public/${recess.icon.uri}`
      );
    }
  }, [recess.icon]);

  return (
    <div>
      <TextualHero
        isScheduleActive={isActiveShedule}
        loadingShedule={loadingSchedule}
        onUpdateShedule={update}
        children={recess}
        ref={mapa.current}
      />
      <div className='container-fluid py-4'>
        <div className='container'>
          <div
            className={`col-12 col-md-7 col-lg-9 mx-auto my-5 ${classes.recessContent}`}>
            <div className='row justify-content-between'>
              {/* here we're doing a layout for text surrounding iframe for the desktop view, with a toggle text */}

              <div className={`${classes.desktop} ${classes.spotify}`}>
                {recess.spotify_link ? (
                  <div
                    className={`${classes.spotify} col-lg-5 col-md-12 col-sm-12 col-12 d-flex`}>
                    <iframe
                      src={spotify}
                      width='300'
                      height='380'
                      frameborder='0'
                      allowtransparency='true'
                      allow='encrypted-media'></iframe>
                  </div>
                ) : null}
                {more == undefined ||
                (more === false && recess.description_text) ? (
                  <>
                    {recess.description_text?.slice(0, maxLetters)} <br />
                    {maxLetters < recess.description_text?.length && (
                      <div
                        onClick={() => setMore(true)}
                        style={{ cursor: "pointer" }}
                        className='text-magenta'>
                        ver mas
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {recess.description_text}
                    <br />
                    <div
                      onClick={() => setMore(false)}
                      style={{ cursor: "pointer" }}
                      className='text-magenta'>
                      ver menos
                    </div>
                  </>
                )}
              </div>

              {/* here's the mobile layout */}

              <div
                className={`${classes.spotify} ${classes.description} ${
                  classes.mobile
                } ${
                  recess.spotify_link
                    ? "col-lg-7 col-md-12 col-sm-12"
                    : "col-12"
                } row`}>
                {!more &&
                recess.description_text &&
                recess.description_text.length > 500 ? (
                  <>
                    {recess.description_text
                      .slice(0, 480)
                      ?.split("\n")
                      .map(descriptionParagraph => (
                        <p
                          key={descriptionParagraph}
                          className={classes.description}>
                          {descriptionParagraph}
                        </p>
                      ))}
                    <span
                      className='text-magenta font-weight-bold clickable'
                      onClick={() => {
                        setMore(true);
                      }}>
                      {lang.Elements.btnViewMap}
                    </span>
                  </>
                ) : (
                  <>
                    {more &&
                      recess.description_text &&
                      recess.description_text
                        ?.split("\n")
                        .map(descriptionParagraph => (
                          <p
                            key={descriptionParagraph}
                            className={classes.description}>
                            {descriptionParagraph}
                          </p>
                        ))}
                    <span
                      className='text-magenta clickable font-weight-bold'
                      onClick={() => {
                        setMore(false);
                      }}>
                      Ver menos
                    </span>
                  </>
                )}
              </div>
              {recess.spotify_link ? (
                <>
                  <div
                    className={`${classes.spotify} ${classes.mobile} col-lg-5 col-md-12 col-sm-12 col-12`}>
                    <iframe
                      className={`${classes.ajustModal}`}
                      src={spotify}
                      width='300'
                      height='380'
                      frameborder='0'
                      allowtransparency='true'
                      allow='encrypted-media'></iframe>
                  </div>
                </>
              ) : null}
              <div className={`w-100 ${classes.featuresSeparator}`}>
                <div className={classes.separador}></div>

                <div
                  className={`${classes.features} d-flex align-items-center mb-4`}>
                  <RecreoFeatures
                    features={[
                      {
                        icon: "Recycle",
                        available: recess.is_sustainability
                      },
                      {
                        icon: "Accesibility",
                        available: recess.is_accessibility
                      },
                      {
                        icon: "HandSanitizer",
                        available: recess.is_preventive_measures
                      },
                      {
                        icon: "GenderFluid",
                        available: recess.is_gender_diversity
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`container-fluid py-4 ${classes.map}`}
        id='mapaRecess'
        ref={mapa}>
        <div className='container'>
          <div className='row'>
            <div className=' w-100'>
              {recess.latitude && recess.longitude ? (
                <Map
                  ubications={[
                    {
                      name: recess.title,
                      latitude: recess.latitude,
                      longitude: recess.longitude
                    }
                  ]}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimpleRecess;
