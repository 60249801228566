import * as React from "react";

function SvgCrossedArrowMix(props) {
  return (
    <svg width={36} height={42} xmlns='http://www.w3.org/2000/svg' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path fill='#E3287D' d='M0 21h21v21H0z' />
        <path fill='#8B4390' d='M21 21l-9 10.244L21 42z' />
        <g>
          <path d='M15 0h21v21H15z' fill='#24B5C7' />
          <path fill='#8B4390' d='M15 2l19 19h2v-2L17 0h-2z' />
          <path fill='#8B4390' d='M15 19v2h2.5L36 2.5V0h-2.5z' />
        </g>
      </g>
    </svg>
  );
}

export default SvgCrossedArrowMix;
