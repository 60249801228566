import * as React from "react";

function SvgDash(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} {...props}>
      <path d='M5 13h14a1 1 0 000-2H5a1 1 0 000 2z' />
    </svg>
  );
}

export default SvgDash;
